import { ActionTypes } from "../constants/actionTypes"

export const userReducer = (state = null, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_USER:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return null;
        default:
            return state;
    }
}

export const tokenReducer = (state = null, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_TOKEN:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return null;
        default:
            return state;
    }
}