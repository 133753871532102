import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactSelect from 'react-select';

const InputWithDropDownFilter = ({
    name,
    options,
    onChange,
    value
}) => {
    const [inputValue, setInputValue] = useState('');
    const [dropDownValue, setDropDownValue] = useState('');

    useEffect(() => {
        if (inputValue && dropDownValue) {
            onChange(name, inputValue + ' ' + dropDownValue);
        }
        // eslint-disable-next-line
    }, [inputValue, dropDownValue]);

    useEffect(() => {
        if (value) {
            const [inputValue, dropDownValue] = value.split(' ');
            setInputValue(inputValue);
            setDropDownValue(dropDownValue);
        } else {
            setInputValue('');
            setDropDownValue('');
        }
    }, [value]);

    return (
        <div className='d-flex'>
            <input
                className="form-control w-50"
                onChange={(e) => setInputValue(e.target.value)}
                type='number'
                value={inputValue}
            />
            <ReactSelect
                className='w-50'
                options={options}
                value={options.filter(({ value }) => value === dropDownValue)[0]}
                onChange={(e) => {
                    setDropDownValue(e.value);
                }}
            />
        </div>
    );
};

export default InputWithDropDownFilter;
