import Header from "../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../Common/Button";
import { toast } from "react-toastify";
import AddUpdatePatientPopup from "./AddUpdatePatientPopup";
import GridView from "../Common/GridView";
import DeleteConfirmationPopup from "../Common/DeleteConfirmationPopup";
import { Link } from "react-router-dom";
import { createPatient, deletePatient, fetchAllPatients, updatePatient, uploadDocuments, uploadInsurance } from "../../api/patientsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import { validateEmail } from "../../helper/validators";
import { dropDownKeys, fields, fileKeys } from "./fields";
import { FiltersContext } from "../Common/FiltersProvider";
import { useContext } from "react";
import { toQueryParams } from "../Common/helpers/filtersHelpers";

const Patients = ({ link }) => {

    const token = useSelector((state) => state?.token);
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [dobDate, setDobDates] = useState({});

    const { state: { filters } } = useContext(FiltersContext);

    const [showAddUpdatePatient, setShowAddUpdatePatient] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [patientData, setPatientData] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    // eslint-disable-next-line
    const [searched, setSearched] = useState(false);
    //const [patients, setPatients] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [checkSalutation, setCheckSalutation] = useState();
    const [checkGender, setCheckGender] = useState();
    const [patientId, setPatientId] = useState(undefined);
    const [stepFormData, setStepFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    // eslint-disable-next-line
    const [submitted, setSubmitted] = useState({ 1: false, 2: false, 3: false });

    // eslint-disable-next-line
    const [totalItems, setTotalItems] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

    const editPatient = (data) => {
        setPatientData(data)
        setShowAddUpdatePatient(true);
    }

    // const action = (id, row) => {
    //     return (
    //         <div className="dropdown card-widgets">
    //             <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
    //                 <i className="mdi mdi-dots-vertical"></i>
    //             </span>
    //             <div className="dropdown-menu dropdown-menu-end" >
    //                 <span className="dropdown-item cursor-pointer" onClick={() => editPatient(row)}><i className="uil-file-edit-alt me-1"></i>Edit</span>
    //                 <span className="dropdown-item cursor-pointer" onClick={() => {
    //                     setShowDeletePopup(true);
    //                     setDeleteId(id);
    //                 }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
    //             </div>
    //         </div>
    //     )
    // }

    const openPatientDetails = (cell, row) => {
        return <Link to={link || ("/patient-profile/" + cell)}>{row.first_name + ' ' + row.last_name}</Link>
    }


    // const gender = [
    //     { value: "male", label: "Male" },
    //     { value: "female", label: "Female" },
    //     { value: "Other", label: "Other" },
    // ];

    // const ageCategory = [
    //     { value: "years", label: "Years" },
    //     { value: "months", label: "Months" },
    //     { value: "days", label: "Days" },
    // ];

    const patientsColumn = [
        {
            dataField: 'id',
            text: 'Patient Name',
            formatter: openPatientDetails,
            // filterLabel: 'first_name',
            sort: true,
            isFilterable: false,
            type: "input",
        },
        {
            dataField: 'mr_id',
            text: 'MRN Number',
        },
        {
            dataField: 'phone_number',
            text: 'Phone',
            sort: true,
            isFilterable: false,
            formatter: (cell, row) => `+${row?.country_code} ${cell.substring(0, 4)} ${cell.substring(4, 8)}`
        }, {
            dataField: 'email',
            text: 'Email',
            sort: true,
            isFilterable: true,
            type: "input",
        }, {
            dataField: 'gender',
            text: 'Gender',
            isFilterable: true,
            type: "dropDown",
        }, {
            dataField: 'dob',
            text: 'DOB',
            sort: true,
            isFilterable: true,
            type: "datePicker",
            formatter: (cell, row) => {
                let dob = row.dob ? row.dob.split('-') : '';
                dob = dob.length > 1 ? `${dob[2]}-${dob[1]}-${dob[0]}` : '';
                return dob;
            }
        }, {
            dataField: 'age',
            text: 'Age',
            isFilterable: true,
            type: "inputWithDropDown",
        }, {
            dataField: 'viewMore',
            text: '',
        }
    ];


    const loadPatients = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
        dispatch(setLoaderView(true));
        fetchAllPatients(token, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setSearchResult(res.data.map(item => ({
                    ...item,
                    viewMore:
                        (
                            <div className="dropdown card-widgets">
                                <span className="dropdown-toggle arrow-none cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="mdi mdi-dots-vertical"></i>
                                </span>
                                <div className="dropdown-menu dropdown-menu-end" >
                                    <span className="dropdown-item cursor-pointer" onClick={() => editPatient(item)}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                                    <span className="dropdown-item cursor-pointer" onClick={() => {
                                        setShowDeletePopup(true);
                                        setDeleteId(item?.id);
                                    }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                                </div>
                            </div>
                        )
                })));
                setTotalItems(res.total_count);
                setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });
    }

    useEffect(() => {
        loadPatients(toQueryParams(filters));
        // eslint-disable-next-line
    }, [pageNo, totalItemToBeFetched]);

    useEffect(() => {
        loadPatients();
        // eslint-disable-next-line
    }, []);

    const onInput = (key, value, step) => {
        const allFields = fields[step];
        if (allFields[key]?.required && allFields[key]?.min && !fileKeys.includes(key) && ((!dropDownKeys.includes(key) && (!value || value.length < allFields[key].min)) || (dropDownKeys.includes(key) && (value === '0' || !value)))) {
            setFormErrors(prevState => ({
                ...prevState,
                [step]: {
                    ...prevState[step],
                    [key]: `${allFields[key].label} should be minimum of length ${allFields[key].min}`
                }
            }));
        } else if (allFields[key]?.required && allFields[key]?.min && !fileKeys.includes(key) && ((!dropDownKeys.includes(key) && (value && value.length === allFields[key].min)) || (dropDownKeys.includes(key) && value === '0'))) {
            setFormErrors(prevState => ({
                ...prevState,
                [step]: {
                    ...prevState[step],
                    [key]: ``
                }
            }));
        } else if (allFields[key]?.required && !fileKeys.includes(key) && ((!dropDownKeys.includes(key) && (!value || value.length === 0)) || (dropDownKeys.includes(key) && value === '0'))) {
            setFormErrors(prevState => ({
                ...prevState,
                [step]: {
                    ...prevState[step],
                    [key]: `${allFields[key].label} is required`
                }
            }));
        } else if (allFields[key]?.required && !fileKeys.includes(key) && ((!dropDownKeys.includes(key) && (value || value.length > 0)) || (dropDownKeys.includes(key) && value !== '0')) && !allFields[key].pattern) {
            setFormErrors(prevState => ({
                ...prevState,
                [step]: {
                    ...prevState[step],
                    [key]: ''
                }
            }));
        } else if (!fileKeys.includes(key) && allFields[key]?.pattern && !allFields[key]?.pattern.test(value)) {
            setFormErrors(prevState => ({
                ...prevState,
                [step]: {
                    ...prevState[step],
                    [key]: `${allFields[key].label} should only contain ${allFields[key].patternLabel} value`
                }
            }));
        } else if (!fileKeys.includes(key) && allFields[key]?.pattern && allFields[key]?.pattern.test(String(value))) {
            setFormErrors(prevState => ({
                ...prevState,
                [step]: {
                    ...prevState[step],
                    [key]: ''
                }
            }));
        }
    }

    const validateForm = (formData, step, id) => {
        // if (formData.get('email').includes('.comm')) {
        //     toast.error("Please enter valid email i.e., example@gmail.com!")
        //     return false;
        // } else 
        if (step === 1 && formData.get('salutation') === "0") {
            toast.error("Please select Salutation!")
            return false;
        } else if (step === 1 && (formData.get('salutation') === "mrs" || formData.get('salutation') === "ms") && formData.get('gender') === 'Male') {
            toast.error("Please select either Female or change the salutation to Mr.!")
            return false;
        } else if (step === 1 && (formData.get('salutation') === "mr") && formData.get('gender') === 'Female') {
            toast.error("Please select either Male or change the salutation to Mrs/Miss!")
            return false;
        } else if (false) {
            toast.error("Please enter valid phone no!")
            return false;
        } else if (step === 1 && !validateEmail(formData.get('email'))) {
            toast.error("Enter valid email address!");
            return false;
        } else if (step === 1 && formData.get('gender') === "0") {
            // toast.error("Please select Gender!")
            return false;
        } else if (step === 2 && ((!patientData && (!formData.get('insurance_file') || formData.get('insurance_file').name === '')) || (patientData && patientData.insurance_list && (!patientData?.insurance_list[0]?.file && (!formData.get('insurance_file') || formData.get('insurance_file').name === ''))))) {
            toast.error("Please upload insurance file!");
            return false;
        } else if (step === 2 && ((!formData.get('insuranceType') || formData.get('insuranceType') === '0'))) {
            toast.error("Please add insurance type!");
            return false;
        } else if (step === 2 && ((!formData.get('subInsuranceName') || formData.get('subInsuranceName') === '0'))) {
            toast.error("Please add Sub insurance!");
            return false;
        } else {
            return true;
        }
    }

    const submitCreatePatientHandler = (event, step, nextStep, setActiveStep, otherData, createdData, setDisable) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        if (step !== 1) {
            for (const data of Object.entries(otherData)) {
                if (step === 2) {
                    formData.append(data[0] === 'insuranceFile' ? 'file' : data[0], data[1]);
                } else if (step === 3) {
                    if (data[0] === 'files') {
                        data[1].forEach(({ docName, file }) => { formData.append('doc_type', docName); formData.append('file', file) })
                    }
                    formData.delete('status');
                }
            }
        }

        setCheckSalutation(formData.get("salutation"));
        setCheckGender(formData.get("gender"));

        if (step === 1 && validateForm(formData, step)) {

            if (patientData || patientId) {
                updatePatient(patientId || patientData?.id, formData, token).then(res => {
                    if (res.status === 200) {
                        toast.success(res.message);
                        setActiveStep(nextStep);
                        setDisable(false);
                        setPatientId(res.data.id);
                        setStepFormData(prevState => ({
                            ...prevState,
                            age: formData.get('age'),
                            country: formData.get('country')
                        }));
                        setSubmitted(prevState => ({ ...prevState, [step]: true }));
                        loadPatients();
                    } else {
                        dispatch(setLoaderView(false));
                        toast.error(res.message);
                    }
                }).catch(error => {
                    dispatch(setLoaderView(false));
                    // console.log(error);
                });
            } else {
                createPatient(formData, token).then(res => {
                    if (res.status === 200) {
                        toast.success(res.message);
                        setActiveStep(nextStep);
                        setPatientId(res.data.id);
                        setDisable(false);
                        setStepFormData(prevState => ({
                            ...prevState,
                            age: formData.get('age')
                        }));
                        setSubmitted(prevState => ({ ...prevState, [step]: true }));
                        loadPatients();
                    } else {
                        dispatch(setLoaderView(false));
                        toast.success(res.message);
                    }
                })
            }
        } else if (step === 2 && validateForm(formData, step, createdData?.id || patientId)) {
            if (createdData?.id || patientId) {
                if (formData.get('insurance_file').name === '') {
                    formData.delete('insurance_file');
                }
                uploadInsurance(formData, token, patientId || createdData?.id).then(res => {
                    if (res.status === 200) {
                        toast.success(res.message);
                        setActiveStep(nextStep);
                        setSubmitted(prevState => ({ ...prevState, [step]: true }));
                        loadPatients();
                    } else {
                        dispatch(setLoaderView(false));
                        toast.success(res.message);
                    }
                });
            } else if (!createdData?.id || !patientId) {
                toast.error('Please submit Basic Details form first to proceed with Insurance and other documents upload');
            }
        } else if (step === 3) {
            const { files } = otherData;
            if (createdData?.id || patientId) {
                files.forEach(({ docName: doc_type, file }) => {
                    const newFormData = new FormData();
                    newFormData.append('doc_type', doc_type);
                    newFormData.append('file', file);
                    if (typeof file !== 'string') {
                        uploadDocuments(newFormData, token, patientId || createdData?.id).then(res => {
                            if (res.status === 200) {
                                toast.success(res.message);
                                setSubmitted(prevState => ({ ...prevState, [step]: true }));
                                loadPatients();
                                setShowAddUpdatePatient(false);
                            } else {
                                dispatch(setLoaderView(false));
                                toast.success(res.message);
                            }
                        });
                    }
                });
            } else if (!createdData?.id || !patientId) {
                toast.error('Please submit Basic Details form first to proceed with Insurance and other documents upload');
            }
        }
    }

    const canDelete = (yesOrNo) => {
        if (yesOrNo) {
            dispatch(setLoaderView(true));
            deletePatient(deleteId, token).then(res => {
                loadPatients();
                dispatch(setLoaderView(false));
                if (res.status === 200) {
                    toast.success(res.message);
                    setShowDeletePopup(false);
                    fetchAllPatients(token).then(res => {
                        if (res.status === 200) {
                            setSearchResult(res.data);
                        } else {
                            dispatch(setLoaderView(false));
                            toast.error(res.message);
                        }
                    }).catch(error => {
                        dispatch(setLoaderView(false));
                        // console.log(error);
                    });
                } else {
                    dispatch(setLoaderView(false));
                    toast.error(res.message);
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                // console.log(error);
            });
        } else {
            setShowDeletePopup(false);
        }
    }

    // const submitSearchPatientForm = (event) => {
    //     event.preventDefault();
    //     const formData = new FormData(event.target);
    //     let phoneNumber = formData.get('phone_number');
    //     let email = formData.get('email');
    //     let name = formData.get('name');
    //     let gender = formData.get('gender');
    //     let age = formData.get('age');
    //     let mrId = formData.get('mr_id');
    //     let dobFromDate = dobDate?.from;
    //     let dobToDate = dobDate?.to;

    //     if (age && formData.get('age_category') === '0') {
    //         toast.warning('Enter  age in years (or) months (or) days!');
    //     } else if (name || phoneNumber || mrId || email || gender || dobFromDate || dobToDate || (formData.get('age_category') && age.concat(' ' + formData.get('age_category') || 'years'))) {
    //         dispatch(setLoaderView(true));
    //         searchPatients(token, mrId, name, phoneNumber, email, gender, age && age.concat(' ' + formData.get('age_category') || 'years'), dobFromDate && format(dobFromDate, 'yyyy-MM-dd'), dobToDate && format(dobToDate, 'yyyy-MM-dd')).then(res => {
    //             dispatch(setLoaderView(false));
    //             setSearched(true);
    //             if (res.status === 200) {
    //                 setSearchResult(res.data);
    //             } else {
    //                 dispatch(setLoaderView(false));
    //                 toast.error(res.message);
    //             }
    //         }).catch(error => {
    //             dispatch(setLoaderView(false));
    //             // console.log(error);
    //         });
    //     } else {
    //         toast.warning('Enter mrn number, name, phone, email, dob, age in years (or) months (or) days, gender to search!');
    //     }
    // }


    return (
        <>
            <Header title="Patients" buttons={[]}>
                <Button label="Add New Patient" className="btn btn-outline-primary float-right mb-3" onClick={() => {
                    setShowAddUpdatePatient(true)
                    setPatientData(null)
                }} />
            </Header>

            <div className="card">
                <div className="card-body">
                    {/* <form onSubmit={submitSearchPatientForm} id="searchPatientForm">
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <Input label="Patient's Name" name="name" required={false} type="text" className="form-control" placeholder="Enter patient's name" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <Input label="Mobile No" name="phone_number" required={false} type="number" className="form-control" placeholder="Enter patient's mobile no." />
                            </div>
                            <div className="col-md-4 mb-3">
                                <Input label="Email ID" name="email" required={false} type="text" className="form-control" placeholder="Enter patient's email id" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <SelectDropDown
                                    options={gender}
                                    label="Gender"
                                    name="gender"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-3 mb-3">
                                <InputWithDropDown label="Age" inputName="age" dropDownName="age_category" inputType="number" placeholder="Enter here" inputClassName="form-control" dropDownClassName="btn border" options={ageCategory} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <DateRangePicker key={'dob'} label={'DOB'} setDates={setDobDates} />
                            </div>
                            <div className="col-md-3 mb-3">
                                <Input label="MRN Number" name="mr_id" required={false} type="text" className="form-control" placeholder="Enter patient's mrn number" />
                            </div>
                            <div className="col-md-1  align-self-center">
                                <Button type="submit" className="btn btn-primary" label="Search" />
                            </div>
                            <div className="col-md-1  align-self-center">
                                <Button type="button" onClick={loadPatients} className="btn btn-outline-primary" label="Reset" />
                            </div>
                        </div>
                    </form> */}

                    <div className="row">
                        {searched && <label>Searched Results</label>}
                        <div className="col-md-12">
                            <GridView
                                data={searchResult}
                                columns={patientsColumn}
                                maxRow={10}
                                searchKey="name"
                                totalItems={totalItems}
                                totalItemToBeFetched={totalItemToBeFetched}
                                setTotalItemToBeFetched={setTotalItemToBeFetched}
                                pageNo={pageNo}
                                onFilter={loadPatients}
                                url={process.env.REACT_APP_BASE_URL +
                                    "/patients/get_all_patients?page_no=" +
                                    pageNo +
                                    "&page_count=" +
                                    totalItemToBeFetched}
                                setPageNo={setPageNo}
                            />
                        </div>
                    </div>

                </div>
            </div>

            {/* <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <GridView
                                gridData={patients}
                                columns={patientsColumn}
                                totalSize={10}
                                sizePerPage={10}
                                keyField="id" />
                        </div>
                    </div>
                </div>
            </div> */}

            {showAddUpdatePatient ? <AddUpdatePatientPopup
                onInput={onInput}
                stepFormData={stepFormData} setStepFormData={setStepFormData} createdId={patientId} show={showAddUpdatePatient} checkSalutation={checkSalutation} setCheckSalutation={setCheckSalutation} checkGender={checkGender} setCheckGender={setCheckGender} data={patientData} onHide={() => setShowAddUpdatePatient(false)} submitForm={submitCreatePatientHandler}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            /> : null}
            {showDeletePopup ? <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} /> : null}

        </>
    )
}

export default Patients;