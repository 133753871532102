import { useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { FiltersContext } from './FiltersProvider';
import { useState } from 'react';
import useDebounce from './debounce';
import { useEffect } from 'react';
import MultiSelect from './MultiSelect';
import { toFormData } from './helpers/filtersHelpers';
import filterFactory from "react-bootstrap-table2-filter";
import FullScreenLoader from './FullScreenLoader';
import CustomPagination from './CustomPagination';
import { useLocation } from 'react-router-dom';

const GridView = ({
  data,
  columns,
  maxRow,
  loader,
  rowEvents,
  url,
  rowStyle,
  searchKey,
  tabLabel,
  filterData,
  enableFESearchFilter,
  totalItems,
  totalItemToBeFetched,
  setTotalItemToBeFetched,
  pageNo,
  onFilter,
  setPageNo,
  hideFilters,
  hidePagination,
  hideSearch,
  searchPlaceHolder = 'Search'
}) => {
  const location = useLocation();
  const { state: { filters } } = useContext(FiltersContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setData] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    if (loader) {
      setSearchTerm("");
      setData([]);
    }
  }, [loader]);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      // if (searchKey && searchKey.split('.').length === 2) {
      //   const key1 = searchKey.split('.')[0];
      //   const key2 = searchKey.split('.')[1];
      //   setData((filterData ? filterData : data).filter(x => x[key1] && x[key1][key2] && String((x[key1][key2])).toLowerCase().match(searchTerm.toLowerCase())));
      // } else {
      if (enableFESearchFilter) {
        const allColumns = columns.map(({ dataField }) => dataField);
        setData(
          (filterData ? filterData : data).filter((x) =>
            Object.entries(x).some(
              (y) =>
                allColumns.includes(y[0])
                  ? y[1] &&
                  String(y[1])
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                  : false
            )
          )
        );
      } else {
        // if (!loader) {
        //   onFilter && onFilter(toFormData(filters), undefined, searchTerm);
        // }
      }
      // }
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);


  useEffect(() => {
    if (!loader && !hideSearch) {
      document.getElementById('form1').focus();
    }
  }, [loader])

  useEffect(() => {
    setSearchTerm('');
  }, [location.pathname])

  //customize table
  const tableStyle = {
    border: "none !important",
  };

  // customize pagination button
  // const pageButtonRenderer = ({ page, active, onPageChange }) => {
  //   const handleClick = (e) => {
  //     e.preventDefault();
  //     onPageChange(page);
  //   };
  //   const activeStyle = {
  //     padding: "10px 15px",
  //     borderRadius: "50%",
  //   };
  //   if (active) {
  //     activeStyle.backgroundColor = "#F58329";
  //     activeStyle.border = "1px solid #F58329";
  //     activeStyle.color = "white";
  //   } else {
  //     activeStyle.backgroundColor = "white";
  //     activeStyle.color = "black";
  //   }
  //   if (typeof page === "string") {
  //     activeStyle.backgroundColor = "white";
  //     activeStyle.color = "black";
  //     activeStyle.border = "none";
  //   }
  //   return (
  //     <li className="page-item">
  //       <a href="#" onClick={handleClick} style={activeStyle}>
  //         {page}
  //       </a>
  //     </li>
  //   );
  // };

  // options
  // const options = {
  //   custom: true,
  //   totalSize: tableData?.length,
  //   hideSizePerPage: true, // Hide the sizePerPage dropdown always
  //   pageButtonRenderer,
  //   sizePerPageList: [maxRow],
  // };

  // const rowStyles = (row, rowIndex) => {
  //   const style = {
  //     padding: "5px",
  //   };
  //   return style;
  // };

  return (
    <>
      <div class="card p-0">
        <div class="card-body p-0">
          {/* <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <> */}
          <div class="input-group" style={{ marginBottom: "20px" }}>
            {!hideFilters && <>
              <div
                className="btn btn-outline-primary mr-2"
                data-toggle="modal"
                data-target="#multi-select-filters"
                disabled={loader}
                onClick={(e) => { e.stopPropagation(); setShowFilters(true); }}
              >
                Filters
              </div>
              <MultiSelect
                enableFESearchFilter={enableFESearchFilter}
                filtersConfig={columns}
                searchTerm={searchTerm}
                show={showFilters}
                setShowFilters={setShowFilters}
                data={data?.map((x) => ({
                  ...x,
                  faq_status_field: x?.faq_status,
                  faq_status: x?.status,
                  active_status: x?.is_active,
                  is_active: x?.is_active && x?.is_active?.props?.value,
                  featured_status: x?.featured,
                  featured: x?.featured?.props?.value,
                }))}
                currentUrl={url}
                setData={setData}
                onFilter={onFilter}
              />
            </>}
            {!hideSearch && <div class="form-outline flex-grow-1">
              <input
                type="search"
                id="form1"
                className="form-control"
                placeholder={searchPlaceHolder}
                value={searchTerm}
                // disabled={loader}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  onFilter && onFilter(null, null, null, e.target.value);
                }}
                onInput={(e) => { if (e.target.value === '') { onFilter(toFormData(filters), null, '') } }}
              />
            </div>}
            {!hideSearch && <button
              type="button"
              disabled={loader}
              class="btn btn-primary"
              style={{ pointerEvents: "none", fontColor: "#fff" }}
            >
              <i class="mdi mdi-magnify"></i>
            </button>}
          </div>
          <div className="table-sm">
            <BootstrapTable
              // classes="table-hover-custom"
              keyField="id"
              data={tableData.map((data) => ({
                ...data,
                [data?.active_status && "is_active"]: data?.active_status,
                [data?.featured_status && "featured"]: data?.featured_status,
                [data?.faq_status_field &&
                  "faq_status"]: data?.faq_status_field,
              }))}
              columns={columns}
              headerClasses="bg-primary text-light text-center"
              bodyClasses="text-center"
              style={tableStyle}
              // {...paginationTableProps}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              filter={filterFactory()}
              bordered={false}
            // cellEdit={cellEditFactory({ mode: 'click' })}
            />
            {(tableData.length === 0 && !loader) ? (
              <p
                style={{
                  fontSize: "0.9rem",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                No results to display
              </p>
            ) : null}
          </div>
          {/* <div className="mt-3 d-flex justify-content-end">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </>
              )}
            </PaginationProvider> */}
          {loader ? <FullScreenLoader /> : null}
        </div>
        {!hidePagination && totalItems && pageNo ? <CustomPagination
          totalItems={totalItems}
          totalItemToBeFetched={totalItemToBeFetched}
          setTotalItemToBeFetched={setTotalItemToBeFetched}
          pageNo={pageNo}
          setPageNo={setPageNo}
        /> : null}
      </div>
    </>
  );
};


export default GridView;