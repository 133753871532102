const IconHeading = ({ title, icon }) => {
    return (
        <div className="flex-shrink-0 d-flex mb-1">
            <div className="avatar-sm rounded mr-2">
                <span className="avatar-title bg-primary-lighten h3 my-0 text-primary rounded">
                    <i className={`mdi ${icon}`}></i>
                </span>
            </div>
            <h4>{title}</h4>
        </div>
    )
}

export default IconHeading;