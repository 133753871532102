import SideMenu from "../components/Common/SideMenu";
import TopBar from "../components/Common/TopBar";
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from "react-redux";

const BaseLayout = () => {

    const token = useSelector((state) => state?.token);

    return (
        <>
            {token ? <div className="wrapper">
                <SideMenu />
                <div className="content-page">
                    <div className="content">
                        <TopBar />
                        <div className="container-fluid">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div> : <Navigate to="/auth/login" />}
        </>
    );
}

export default BaseLayout;