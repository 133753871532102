import { Modal } from "react-bootstrap";
import Input from "../Common/Input";
import InputWithDropDown from "../Common/InputWithDropDown";
import TextArea from "../Common/TextArea";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDoseUnits, fetchStrengthUnits, fetchDurationUnits, fetchRoute, fetchProducts, fetchDirection, fetchPreperation, fetchFrequency } from "../../api/prescriptionApi";
import { formatValueLabel } from "../../helper/valueLabelFormatter";
import { useParams } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { toast } from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import SelectDropDown from "../Common/SelectDropDown";

const CreateMedicalHistoryPopUp = ({ show, onHide, submitForm, patient }) => {

    const token = useSelector((state) => state?.token);

    const { patient_id } = useParams();
    const dispatch = useDispatch();

    const [products, setProducts] = useState([]);
    const [strengthUnits, setStrengthUnits] = useState([]);
    const [preperation, setPreperation] = useState([]);
    const [direction, setDirection] = useState([]);
    const [frequency, setFrequency] = useState([]);
    const [doseUnits, setDoseUnits] = useState([]);
    const [durationUnits, setDurationUnits] = useState([]);
    const [route, setRoute] = useState([]);

    const [dateTime, setDateTime] = useState('');

    useEffect(() => {
        fetchProducts(token).then(res => {
            if (res.status === 200) {
                setProducts(formatValueLabel('itemName', 'itemName', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });

        fetchStrengthUnits(token).then(res => {
            if (res.status === 200) {
                setStrengthUnits(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });

        fetchPreperation(token).then(res => {
            if (res.status === 200) {
                setPreperation(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });

        fetchDirection(token).then(res => {
            if (res.status === 200) {
                setDirection(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });

        fetchFrequency(token).then(res => {
            if (res.status === 200) {
                setFrequency(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });

        fetchDurationUnits(token).then(res => {
            if (res.status === 200) {
                setDurationUnits(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });

        fetchDoseUnits(token).then(res => {
            if (res.status === 200) {
                setDoseUnits(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });

        fetchRoute(token).then(res => {
            if (res.status === 200) {
                setRoute(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });
    }, []);

    return (
        <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={(event) => submitForm(event, patient_id)}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Create Medical History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <SelectDropDown
                                options={[
                                    {
                                        label: 'Resident',
                                        value: 'Resident'
                                    },
                                    {
                                        label: 'Visitor',
                                        value: 'Visitor'
                                    }
                                ]}
                                label="Select Status"
                                name="status"
                                required={true}
                                className="form-control basic-multi-select"
                                classNamePrefix="select"
                                onChange={() => { }}
                            />
                        </div>
                        <div className="col-6">
                            <SelectDropDown
                                options={[
                                    {
                                        label: 'Doctor 1',
                                        value: 'Doctor 1'
                                    },
                                    {
                                        label: 'Doctor 2',
                                        value: 'Doctor 2'
                                    }
                                ]}
                                label="Select Doctor"
                                name="doctor"
                                required={true}
                                className="form-control basic-multi-select"
                                classNamePrefix="select"
                                onChange={() => { }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <Input className='form-control' inputStyle={{ width: '100%' }} label={'Residence Location'} name="patient_name" />
                        </div>
                        <div className="col-6">
                            <Input className='form-control' inputStyle={{ width: '100%' }} label={'Patient Nationality'} name="patient_name" />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <Input className='form-control' label={'Allergy Details'} name="patient_name" />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <Input className='form-control' label={'Insurance Premium No.'} name="patient_name" />
                        </div>
                        <div className="col-6">
                            <Input type='date' className='form-control' label={'Insurance Expiry Date'} name="patient_name" />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label className="mb-2">{"Attach Insurance File of Type JPEG, PNG or PDF format (Max File Size : 5mb)"}</label>
                            <span className="form-control upload-input-placeholader-txt">
                                <Input
                                    type="file"
                                    accept="image/*, .pdf"
                                    onChange={(e) => {

                                    }}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label className="mb-2">{"Attach Other Documents(QID/Passport) of Type JPEG, PNG or PDF format (Max File Size : 5mb)"}</label>
                            <span className="form-control upload-input-placeholader-txt">
                                <Input
                                    type="file"
                                    accept="image/*, .pdf"
                                    onChange={(e) => {

                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={() => { onHide(); setDateTime(''); }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CreateMedicalHistoryPopUp;