import axios from "axios";
import { toast } from "react-toastify";

export async function fetchClinicDetail(token) {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_BASE_URL + "/users/get_clinic_data",
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function submitClinicDetails(formData, token) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_BASE_URL + "/users/update_clinic_data",
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function fetchRoles(
  token,
  pageNo,
  totalItemToBeFetched,
  applyFilter,
  search_string,
  filters
) {
  const response = await axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      "/users/get_role_data?page_no=" +
      (applyFilter || search_string ? "1" : pageNo) +
      "&page_count=" +
      totalItemToBeFetched +
      "&" +
      (!search_string && filters) +
      (search_string ? "&search_string=" + search_string : ""),
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function createRole(formData, token) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_BASE_URL + "/users/save_role_data",
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function updateRole(id, formData, token) {
  const response = await axios({
    method: "put",
    url: process.env.REACT_APP_BASE_URL + "/users/update_role_data/" + id,
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  });
  return response.data;
}

export async function deleteRole(id, token) {
  const response = await axios({
    method: "delete",
    url: process.env.REACT_APP_BASE_URL + "/users/update_role_data/" + id,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function fetchSpecialization(token) {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_BASE_URL + "/master/get_specialities",
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function fetchUsers(
  token,
  pageNo = 1,
  totalItemToBeFetched = 10,
  applyFilter,
  search_string,
  filters
) {
  const response = await axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      "/users/get_users_list?page_no=" +
      (applyFilter || search_string ? "1" : pageNo) +
      "&page_count=" +
      totalItemToBeFetched +
      (search_string ? "&search_string=" + search_string : ""),
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function createUser(formData, token) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_BASE_URL + "/users/add_user_clinic",
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    toast.error(
      <>
        <div>{error?.response?.data?.message}</div>
        {error?.response?.data?.otp?.username && (
          <div>{error?.response?.data?.otp?.username}</div>
        )}
      </>
    );
    // console.log(error);
  });
  return response.data;
}

export async function updateUser(id, formData, token) {
  const response = await axios({
    method: "put",
    url: process.env.REACT_APP_BASE_URL + "/users/update_user_clinic/" + id,
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    toast.error(
      <>
        <div>{error?.response?.data?.message}</div>
        {error?.response?.data?.otp?.username && (
          <div>{error?.response?.data?.otp?.username}</div>
        )}
      </>
    );
    // console.log(error);
  });
  return response.data;
}

export async function deleteUser(id, token) {
  const response = await axios({
    method: "delete",
    url: process.env.REACT_APP_BASE_URL + "/users/update_user_clinic/" + id,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function fetchDashboardDetails(token) {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_BASE_URL + "/users/dashboard_data",
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}

export async function fetchUserDetails(token) {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_BASE_URL + "/users/get_user_data",
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // console.log(error);
  });
  return response.data;
}
