export const fields = {
    clinic: {
        name: {
            name: "name",
            label: "Clinic Name",
            required: true,
            pattern: /^[a-zA-Z0-9 ]+$/,
            patternLabel: 'alphanumeric',
        },
        email: {
            name: "email",
            required: true,
            label: "Email Id",
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            patternLabel: 'format i.e., example@gmail.com',
        },
        street: {
            name: "street",
            required: true,
            label: "Street",
            pattern: /^[a-zA-Z0-9 ]+$/,
            patternLabel: 'alphanumeric',
        },
        primary_contact_no: {
            label: "Primary Contact No",
            name: "primary_contact_no",
            required: true,
        },
        secondary_contact_no: {
            label: "Secondary Contact No",
            name: "secondary_contact_no",
            required: true,
        },
        speciality_id: {
            label: 'Specialization',
            name: 'speciality_id',
            required: true
        },
        address: {
            label: "Address",
            name: "address",
            required: true,
        },
        building_number: {
            label: "Building Number",
            name: "building_number",
            required: true,
        },
        street: {
            label: "Street",
            name: "street",
            required: true,
        },
        zone: {
            label: "Zone",
            name: "zone",
            required: true,
        },
        unit_number: {
            label: "Unit Number",
            name: "unit_number",
            required: true,
        },
        opening_hour: {
            label: "Opening Hours",
            name: "opening_hour",
            required: true,
        },
        closing_hour: {
            label: "Closing Hours",
            name: "closing_hour",
            required: true,
        },
    },
    user: {
        name: {
            name: "name",
            label: "Name",
            required: true,
            pattern: /^[a-zA-Z0-9 .]+$/,
            patternLabel: 'alpha-numeric',
        },
        email: {
            name: "email",
            required: true,
            label: "Email Id",
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            patternLabel: 'format i.e., example@gmail.com',
        },
        phone_number: {
            label: "Phone Number",
            name: "phone_number",
            required: true,
        },
        country_code: {
            label: "Country Code",
            name: "country_code",
            required: true,
        },
        speciality_id: {
            label: "Specialization",
            name: "speciality_id",
            required: true,
        },
        dob: {
            name: "dob",
            label: "Date of Birth",
            required: true
        },
        gender: {
            name: "gender",
            label: "Gender",
            required: true
        },
        role_id: {
            name: "role_id",
            label: "Role",
            required: true
        },
        opening_hour: {
            label: "Opening Hours",
            name: "opening_hour",
            required: true,
        },
        closing_hour: {
            label: "Closing Hours",
            name: "closing_hour",
            required: true,
        },
    },
}

export const dropDownKeys = ['insuranceType', 'subInsuranceName', 'gender', 'role_id', 'speciality_id'];

export const fileKeys = ['insurance_file'];