import Header from "../Common/Header";
import Pdf from "react-to-pdf";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { autoSendPresctiotionImage, fetchAccessToken, fetchPrintPreviewPrescription } from "../../api/prescriptionApi";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderView } from "../../redux/actions/settingActions";
import GridView from "../Common/GridView";
import { dateFormatter, newDateFormatter } from "../../helper/dateFormatter";
import QRCode from "react-qr-code";
import * as htmlToImage from 'html-to-image';
import TermsConditionPopup from "./TermsConditionPopup";
import axios from "axios";
import ReactTable from "../Common/ReactTable";

const PreviewPrescription = () => {

  const ref = useRef();
  const dispatch = useDispatch();

  const { prescription_id } = useParams();

  const [prescriptionDetail, setPrescriptionDetail] = useState(null);
  const [showTC, setShowTC] = useState(false);

  const isRole = useSelector((state) => state?.user_detail?.role_id);

  const location = useLocation();

  const { patient_id, clinic_id } = location?.state || {};

  const navigate = useNavigate();

  const drugsColumns = [
    {
      dataField: 'itemName',
      text: 'Drug Name'
    }, {
      dataField: 'strength',
      text: 'Strength',
      formatter: (cell, row) => `${cell} ${row.strength_unit}`
    }, {
      dataField: 'preparation_name',
      text: 'Preparation'
    }, {
      dataField: 'dosage',
      text: 'Dosage',
      formatter: (cell, row) => cell ? `${cell} ${row.dosage_unit}` : ''
    }, {
      dataField: 'route_name',
      text: 'Route'
    }, {
      dataField: 'direction_name',
      text: 'Direction'
    }, {
      dataField: 'frequency_name',
      text: 'Frequency'
    }, {
      dataField: 'duration',
      text: 'Duration',
      formatter: (cell, row) => `${cell} ${row.duration_unit}`
    }
  ];

  useEffect(() => {
    dispatch(setLoaderView(true));
    fetchPrintPreviewPrescription(prescription_id).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setPrescriptionDetail(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    })
  }, []);

  const sendPrescription = () => {
    setShowTC(false);
    dispatch(setLoaderView(true));
    fetchAccessToken().then(res => {
      const formData = new FormData();
      formData.append('phone_number', prescriptionDetail.patient_details.phone_number);
      formData.append('country_code', prescriptionDetail.patient_details.country_code);
      formData.append('passport_no', prescriptionDetail.patient_details.national_id_pass_number);
      formData.append('national_id', prescriptionDetail.patient_details.national_id_pass_number);
      formData.append('salutation', prescriptionDetail.patient_details.salutation);
      formData.append('email', prescriptionDetail.patient_details.email);
      formData.append('dob', prescriptionDetail.patient_details.dob);
      //formData.append('country', prescriptionDetail.patient_details.country_code);
      formData.append('name', `${prescriptionDetail.patient_details.first_name} ${prescriptionDetail.patient_details.last_name}`);
      formData.append('prescription_uuid', prescription_id);

      axios({
        method: "post",
        url: process.env.REACT_APP_CONSUMER_URL + "/order/place_doctor_panel_order?access_token=" + res.access_token,
        data: formData
      }).then((res) => {
        dispatch(setLoaderView(false));
        if (res?.data?.status === 200) {
          toast.success(res.data.message);
          window.open(process.env.REACT_APP_CONSUMER_FE_URL + "auth/prescrition-login/" + prescription_id);
        } else if (res?.data?.status === 400) {
          toast.error(res.response.data.message);
        }
      }).catch(error => {
        dispatch(setLoaderView(false));
        toast.error(error.response.data.message);
      });
    }).catch(error => {
      dispatch(setLoaderView(false));
      toast.error(error.message);
    });
  }

  useEffect(() => {
    if (prescriptionDetail) {
      if (prescriptionDetail?.is_modified) {
        dispatch(setLoaderView(true));
        setTimeout(() => {
          dispatch(setLoaderView(false));
          sendPrescriptionAutomatically();
        }, 2000);
      }
    }
  }, [prescriptionDetail]);

  const sendPrescriptionAutomatically = () => {
    htmlToImage.toJpeg(document.getElementById('prescription-container'))
      .then(function (dataUrl) {
        const formData = new FormData();
        formData.append('image_data', dataUrl);
        autoSendPresctiotionImage(formData, prescription_id).then(res => {
          dispatch(setLoaderView(false));
        }).catch(error => {
          dispatch(setLoaderView(false));
          toast.error(error.message);
        });
      }).catch(error => {
        dispatch(setLoaderView(false));
        toast.error(error.message);
      });
  }


  const options = {
    orientation: "potrait",
    format: 'a1',
    unit: 'px',
  };

  const printPrescription = () => {
    // let printContents = document.getElementById('prescription-container').innerHTML;
    // let originalContents = document.body.innerHTML;
    // dispatch(setLoaderView(true));
    // document.body.innerHTML = printContents;
    // window.print();
    // dispatch(setLoaderView(false));
    // document.body.innerHTML = originalContents;
    window.print();
  }

  return (
    <>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-1" />

            <div className="col-md-10">
              <Header title={<><span className="align-self-center text-secondary cursor-pointer" onClick={() => { navigate(patient_id && clinic_id ? `/view-visit/${patient_id}/${clinic_id}` : '/patients') }}>
                <i className="mdi mdi-arrow-left me-2 font-24"></i>
              </span><span>Preview Prescription</span></>}>
                <Pdf targetRef={ref} filename="prescription.pdf" scale={1.8} options={options} x={30} y={50}>
                  {({ toPdf }) => <button type="button" className="btn btn-outline-primary btn-block ml-2" onClick={toPdf}>Download Prescription</button>}
                </Pdf>
                <button type="button" className="btn btn-outline-primary btn-block ml-2" onClick={printPrescription}>Print</button>

                {!isRole && <button type="button" className="btn btn-primary btn-block ml-2" disabled={prescriptionDetail?.patient_prescription.length === 0} onClick={() => setShowTC(true)}>Place Order on Medpack</button>}

              </Header>

              <div className="row" id="prescription-container" ref={ref}>
                <div className="col-12">
                  <div className="card">
                    <div className="p-3">

                      <div className="row">
                        <div className="col-9">
                          <p className="m-0"><b>{prescriptionDetail?.doctor_clinic_details?.clinic_data?.name}</b></p>
                          <p className=" m-0">{prescriptionDetail?.doctor_clinic_details?.doctor_data?.name}{(prescriptionDetail?.doctor_clinic_details?.doctor_data?.speciality_list?.map((obj) => {
                            return obj.speciality_name
                          }))?.length ? ' - ' : null} {(prescriptionDetail?.doctor_clinic_details?.doctor_data?.speciality_list?.map((obj) => {
                            return obj.speciality_name
                          }))?.join(', ')}</p>
                          <p className=" m-0">{newDateFormatter(prescriptionDetail?.created_on)}</p>
                        </div>
                        <div className="col-3">
                          {prescriptionDetail?.doctor_clinic_details?.clinic_data?.logo ? <img className="float-right" src={process.env.REACT_APP_BASE_URL + prescriptionDetail?.doctor_clinic_details?.clinic_data?.logo} alt="" height={70} width={70} /> : null}
                        </div>
                      </div>

                      <hr />

                      <div>
                        <div className="row">
                          <div className="col-12">
                            <div className="float-left mt-3">
                              <p className="m-0"><b>Patient Details</b></p>
                              <p className=" m-0">{prescriptionDetail?.patient_details?.first_name} (Age-{prescriptionDetail?.patient_details?.age})</p>
                            </div>
                          </div>
                        </div>

                        {prescriptionDetail?.patient_complaints?.length > 0 || prescriptionDetail?.complaint_comment ?
                          <div className="row">
                            <div className="col-12">
                              <div className="float-left mt-3">
                                <p className="m-0"><b>Complaints</b></p>
                                <p className=" m-0">{prescriptionDetail?.patient_complaints?.map((obj) => {
                                  return obj.name
                                })?.join(', ')}</p>
                                <p className=" m-0">{prescriptionDetail?.complaint_comment}</p>
                              </div>
                            </div>
                          </div> : null}

                        {prescriptionDetail?.patient_diagnosis?.length > 0 || prescriptionDetail?.diagnosis_comment ?
                          <div className="row">
                            <div className="col-12">
                              <div className="float-left mt-3">
                                <p className="m-0"><b>Medical diagnosis</b></p>
                                <p className=" m-0">{prescriptionDetail?.patient_diagnosis?.map((obj) => {
                                  return obj.name
                                })?.join(', ')}</p>
                                <p className=" m-0">{prescriptionDetail?.diagnosis_comment}</p>
                              </div>
                            </div>
                          </div> : null}

                        {prescriptionDetail?.patient_tests_id?.length > 0 ? <div className="row">
                          <div className="col-12">
                            <div className="float-left mt-3">
                              <p className="m-0"><b>Tests</b></p>
                              <p className=" m-0">{prescriptionDetail?.patient_tests_id?.map((obj) => {
                                return obj.name
                              })?.join(', ')}</p>
                            </div>
                          </div>
                        </div> : null}

                        {prescriptionDetail?.patient_prescription?.length > 0 ?
                          <div className="row">
                            <div className="col-12">
                              <div className="float-left mt-3">
                                <p className="m-0 mb-1"><b>Medicine Details</b></p>
                                {/* <GridView
                                  data={prescriptionDetail?.patient_prescription}
                                  columns={drugsColumns}
                                  hidePagination={true}
                                  hideFilters={true}
                                  hideSearch={true}
                                  totalSize={10}
                                  sizePerPage={10}
                                  keyField="id" /> */}
                                <ReactTable columns={drugsColumns} data={prescriptionDetail?.patient_prescription} />
                              </div>
                            </div>
                          </div>
                          : null}

                        {prescriptionDetail?.doctor_comment && <div className="row">
                          <div className="col-12">
                            <div className="float-left mt-3 mb-3">
                              <p className="m-0"><b>Doctor’s Note</b></p>
                              <p className=" m-0">{prescriptionDetail?.doctor_comment}</p>
                            </div>
                          </div>
                        </div>}
                      </div>

                      <div className="row mt-3 prescription-images">
                        <div className="mb-3 width-auto signature">
                          <img src={process.env.REACT_APP_BASE_URL + prescriptionDetail?.doctor_clinic_details?.doctor_data?.signature} alt="" height="32" />
                          <h6 className="m-0">Doctor’s Signature</h6>
                        </div>
                        <div className="mb-3 width-auto">
                          <QRCode id="qr_code_viewer" value={window.location.href} size={80} /><br />
                          <h6 className="m-0">QR Code</h6>
                        </div>
                      </div>

                      <hr />

                      <div className="row mt-3">
                        <div className="col-4 mb-3">
                          <h6 className="m-0">Address</h6>
                          <p className=" m-0">Address: {prescriptionDetail?.doctor_clinic_details?.clinic_data?.address}</p>
                          <p className=" m-0">Building No.: {prescriptionDetail?.doctor_clinic_details?.clinic_data?.building_number}</p>
                          <p className=" m-0">Street: {prescriptionDetail?.doctor_clinic_details?.clinic_data?.street}</p>
                          <p className=" m-0">Zone: {prescriptionDetail?.doctor_clinic_details?.clinic_data?.zone}</p>
                          <p className=" m-0">Unit Number: {prescriptionDetail?.doctor_clinic_details?.clinic_data?.unit_number}</p>
                        </div>
                        <div className="col-4 mb-3">
                          <h6 className="m-0">Contact Info</h6>
                          <p className=" m-0">
                            <p className=" m-0">{prescriptionDetail?.doctor_clinic_details?.clinic_data?.primary_contact_no}</p>
                            <p className=" m-0">{prescriptionDetail?.doctor_clinic_details?.clinic_data?.secondary_contact_no}</p>
                          </p>
                        </div>
                        <div className="col-4 mb-3">
                          <h6 className="m-0">Opening Hours</h6>
                          <p className=" m-0">
                            {prescriptionDetail?.doctor_clinic_details?.clinic_data?.opening_hour} - {prescriptionDetail?.doctor_clinic_details?.clinic_data?.closing_hour}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1" />
          </div>
        </div>
      </div >

      {showTC ? <TermsConditionPopup show={showTC} onHide={() => setShowTC(false)} submitForm={sendPrescription} /> : null}

    </>
  )
}

export default PreviewPrescription;