export const step1 = (data) => {
    return {
        label: 'Basic Details',
        step: 1,
        buttonLabel: data ? "Update Patient" : "Add Patient",
        next: 2
    }
};

export const step2 = (data) => {
    return {
        label: 'Insurance Details',
        step: 2,
        buttonLabel: data ? "Update Insurance" : "Add Insurance",
        previous: 1,
        next: 3
    }
};

export const step3 = (data) => {
    return {
        label: 'Documents Upload',
        step: 3,
        buttonLabel: 'Upload',
        previous: 2
    }
};
