import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD1SqL6VnYlE8Bb08wI2tCFqZWQvaV-zTE",
    authDomain: "medpack-6b8b0.firebaseapp.com",
    projectId: "medpack-6b8b0",
    storageBucket: "medpack-6b8b0.appspot.com",
    messagingSenderId: "487716036895",
    appId: "1:487716036895:web:21c3f9edddd3ab9d32102a",
    measurementId: "G-KDDKHT9CL6"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;