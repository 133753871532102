import Input from "../Common/Input";
import Header from "../Common/Header";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GridView from "../Common/GridView";
import Button from "../Common/Button";
import { Link } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { fetchAllPatients, fetchAllPatientsofRecentVisits, searchPatients } from "../../api/patientsApi";
import { toast } from "react-toastify";
import SelectDropDown from "../Common/SelectDropDown";
import InputWithDropDown from "../Common/InputWithDropDown";
import DateRangePicker from "../Common/DateRangePicker";
import { format } from "date-fns";
import { toQueryParams } from "../Common/helpers/filtersHelpers";
import { useContext } from "react";
import { FiltersContext } from "../Common/FiltersProvider";
import { useEffect } from "react";
import { formatDate } from "../utils/dateUtils";

const SearchPatient = () => {

    const token = useSelector((state) => state?.token);
    const [dobDate, setDobDates] = useState({});
    const isDoctor = useSelector((state) => state?.user_detail?.user_data?.is_doctor);

    const gender = [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "Other", label: "Other" },
    ];

    const ageCategory = [
        { value: "years", label: "Years" },
        { value: "months", label: "Months" },
        { value: "days", label: "Days" },
    ];
    const dispatch = useDispatch();
    const [allData, setAllData] = useState([]);
    const [searchResult, setSearchResult] = useState(allData);
    const [showTable, setShowTable] = useState(false);

    const { state: { filters } } = useContext(FiltersContext);
    let doctor_id = useSelector((state) => state?.user_detail?.user_id);
    // const details = useSelector((state) => state?.user_detail);

    // console.log('>>', details);

    const [totalItems, setTotalItems] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

    const openPatientDetails = (cell, row) => {
        return isDoctor ? <Link to={'/create-prescription/' + row.patient_id + '/' + row.id}>{row?.patient_first_name + ' ' + row?.patient_last_name}</Link> : row?.patient_first_name + ' ' + row?.patient_last_name
    }

    useEffect(() => {
        setSearchResult(allData)
    }, [allData]);

    const action = (id, row) => {
        const { patient_id } = row;
        const toPath = {
            pathname: `/create-prescription/${row.patient_id}/${row.id}`,
            state: { searchPatient: true, patientId: patient_id, visitId: id }
        };

        return (
            <div className="dropdown card-widgets">
                <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                </span>
                <div className="dropdown-menu dropdown-menu-end" >
                    <span className="dropdown-item cursor-pointer"><Link style={{ color: 'inherit' }} to={toPath}>{'Create Prescription'}</Link></span>
                    <span className="dropdown-item cursor-pointer"><Link style={{ color: 'inherit' }} to={'/view-visit/' + row.patient_id + '/' + row.id}>{'View Prescription'}</Link></span>
                </div>
            </div>
        )
    }

    const loadPatients = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
        dispatch(setLoaderView(true));
        fetchAllPatientsofRecentVisits(token, doctor_id, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setAllData(res.data.map(item => ({
                    ...item,
                    // viewMore:
                    //     (
                    //         <div className="dropdown card-widgets">
                    //             <span className="dropdown-toggle arrow-none cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false">
                    //                 <i className="mdi mdi-dots-vertical"></i>
                    //             </span>
                    //             <div className="dropdown-menu dropdown-menu-end" >
                    //                 <span className="dropdown-item cursor-pointer" onClick={() => editPatient(item)}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                    //                 <span className="dropdown-item cursor-pointer" onClick={() => {
                    //                     setShowDeletePopup(true);
                    //                     setDeleteId(item?.id);
                    //                 }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                    //             </div>
                    //         </div>
                    //     )
                })));
                setTotalItems(res.total_count);
                setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });
    }

    useEffect(loadPatients, []);

    useEffect(() => {
        loadPatients(toQueryParams(filters));
        // eslint-disable-next-line
    }, [pageNo, totalItemToBeFetched]);

    const patientsColumn = [
        {
            dataField: 'patient_first_name',
            text: 'Patient Name',
            sort: true,
            isFilterable: true,
            type: "input",
            formatter: openPatientDetails
        }, {
            dataField: 'visit_datetime',
            text: 'Visit Date',
            sort: true,
            isFilterable: true,
            type: "datePicker",
            formatter: (cell) => formatDate(cell)
        },
        {
            dataField: 'doctor_name',
            text: 'Doctor Name',
            sort: true,
            isFilterable: true,
            type: "input",
        },
        {
            dataField: 'created_by_name',
            text: 'Created By',
            sort: true,
            isFilterable: true,
            type: "input",
        },
        {
            dataField: 'modified_by_name',
            text: 'Modified By',
            sort: true,
            isFilterable: true,
            type: "input",
        },
        {
            dataField: 'id',
            text: '',
            formatter: isDoctor && action
        },
    ];




    // const submitSearchPatientForm = (event) => {
    //     event.preventDefault();
    //     const formData = new FormData(event.target);
    //     let phoneNumber = formData.get('phone_number');
    //     let email = formData.get('email');
    //     let name = formData.get('name');
    //     let gender = formData.get('gender');
    //     let age = formData.get('age');
    //     let mrId = formData.get('mr_id');
    //     let dobFromDate = dobDate?.from;
    //     let dobToDate = dobDate?.to;
    //     if (age && formData.get('age_category') === '0') {
    //         toast.warning('Enter  age in years (or) months (or) days!');
    //     } else if (name || phoneNumber || mrId || email || gender || dobFromDate || dobToDate || (formData.get('age_category') && age.concat(' ' + formData.get('age_category') || 'years'))) {
    //         dispatch(setLoaderView(true));
    //         searchPatients(token, mrId, name, phoneNumber, email, gender, age && age.concat(' ' + formData.get('age_category') || 'years'), dobFromDate && format(dobFromDate, 'yyyy-MM-dd'), dobToDate && format(dobToDate, 'yyyy-MM-dd')).then(res => {
    //             dispatch(setLoaderView(false));
    //             setShowTable(true);
    //             if (res.status === 200) {
    //                 setSearchResult(res.data);
    //             } else {
    //                 dispatch(setLoaderView(false));
    //                 toast.error(res.message);
    //             }
    //         }).catch(error => {
    //             dispatch(setLoaderView(false));
    //             // console.log(error);
    //         });
    //     } else {
    //         toast.warning('Enter mrn number, name, phone, email, dob, age, gender to search!');
    //     }
    // }

    const searchData = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
        if (searchTerm) {
            setSearchResult(allData.filter(e => (e.patient_first_name.toLowerCase() + " " + e.patient_last_name.toLowerCase()).match(searchTerm.toLowerCase())));
        }
        else {
            setSearchResult(allData);
        }
    }

    return (
        <>
            <Header title="Create Prescription"></Header>

            <div className="card">
                <div className="card-body">
                    {/* <form onSubmit={submitSearchPatientForm} id="searchPatientForm">
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <Input label="Patient's Name" name="name" required={false} type="text" className="form-control" placeholder="Enter patient's name" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <Input label="Mobile No" name="phone_number" required={false} type="number" className="form-control" placeholder="Enter patient's mobile no." />
                            </div>
                            <div className="col-md-4 mb-3">
                                <Input label="Email ID" name="email" required={false} type="text" className="form-control" placeholder="Enter patient's email id" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <SelectDropDown
                                    options={gender}
                                    label="Gender"
                                    name="gender"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-3 mb-3">
                                <InputWithDropDown label="Age" inputName="age" dropDownName="age_category" inputType="number" placeholder="Enter here" inputClassName="form-control" dropDownClassName="btn border" options={ageCategory} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <DateRangePicker key={'dob'} label={'DOB'} setDates={setDobDates} />
                            </div>
                            <div className="col-md-3 mb-3">
                                <Input label="MRN Number" name="mr_id" required={false} type="text" className="form-control" placeholder="Enter patient's mrn number" />
                            </div>
                            <div className="col-md-1  align-self-center">
                                <Button type="submit" className="btn btn-primary" label="Search" />
                            </div>
                            <div className="col-md-1  align-self-center">
                                <Button type="button" onClick={loadPatients} className="btn btn-outline-primary" label="Reset" />
                            </div>
                        </div>
                    </form> */}

                    <div className="row">
                        <div className="col-md-12">
                            <GridView
                                data={searchResult}
                                hideFilters={true}
                                columns={patientsColumn}
                                maxRow={10}
                                searchKey="name"
                                totalItems={totalItems}
                                totalItemToBeFetched={totalItemToBeFetched}
                                setTotalItemToBeFetched={setTotalItemToBeFetched}
                                pageNo={pageNo}
                                // onFilter={loadPatients}
                                onFilter={searchData}
                                setPageNo={setPageNo}
                            />

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SearchPatient;