/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { removeUser } from "../../redux/actions/userActions";
import Select, { components, PlaceholderProps } from 'react-select';

import { useEffect } from "react";
import { toast } from "react-toastify";
import { fetchAllPatients, fetchAllPatientsGeneral } from "../../api/patientsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import { useState } from 'react';

const TopBar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [patients, setPatients] = useState([]);
    const [patient, setPatient] = useState(null);
    const user_detail = useSelector((state) => state?.user_detail);
    const permissions_list = useSelector((state) => state?.user_detail?.permissions_list);
    const token = useSelector((state) => state?.token);
    const [search_string, setSearchString] = useState('');

    const mapSinglePatient = patient => ({ ...patient, label: `${patient?.mr_id} ${patient?.first_name + ' ' + patient?.last_name}`, value: patient?.first_name + ' ' + patient?.last_name });

    const location = useLocation();
    // debugger


    const logoutUser = () => {
        dispatch(removeUser())
        navigate("/auth/login")
    }

    const Placeholder = (props) => {
        return <components.Placeholder {...props}>
            <div style={{ color: '#999' }}>Enter MRN/ patient name</div>
        </components.Placeholder>;
    };

    const fetchPatient = (search_string) => {
        dispatch(setLoaderView(true));
        fetchAllPatientsGeneral(token, search_string).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setPatients(res.data);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            console.log(error);
        });
    }

    useEffect(() => {
        if (search_string || search_string === '') {
            fetchPatient(search_string);
        }
    }, [search_string]);

    useEffect(() => {
        if (!location.pathname.includes('patient-profile')) {
            setPatient({});
        }
    }, [location.pathname]);

    const filterOption = (option, inputValue) => {
        const { value, label } = option;
        const lowerCasedInputValue = inputValue.toLowerCase();

        return (
            value.toLowerCase().includes(lowerCasedInputValue) ||
            label.toLowerCase().includes(lowerCasedInputValue)
        );
    };

    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <button class="btn btn-primary" type="button">
                    <i class="mdi mdi-magnify"></i>
                </button>
            </components.DropdownIndicator>
        );
    };

    const styles = {
        dropdownIndicator: (css) => ({ ...css, padding: '0' }),
    };

    return (
        <div className="navbar-custom">
            <ul className="list-unstyled topbar-menu float-end mb-0">
                <li className="dropdown notification-list">
                    <Link className="nav-link dropdown-toggle nav-user arrow-none me-0" data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false"
                        aria-expanded="false">
                        <span className="account-user-avatar">
                            <img src="/assets/images/users/user.png" alt="" className="rounded-circle" />
                        </span>
                        <span>
                            <span className="account-user-name">{user_detail?.user_data?.name}</span>
                            <span className="account-position">{user_detail?.role_name}</span>
                        </span>
                    </Link>
                    <div className="mt-1 dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                        {/* <Link to="/profile" className="dropdown-item notify-item">
                            <i className="mdi mdi-account-circle me-1"></i>
                            <span>My Profile</span>
                        </Link> */}
                        {/* {
                            permissions_list.settings
                            &&
                            <Link to="/settings" className="dropdown-item notify-item">
                                <i className="mdi mdi-account-circle me-1"></i>
                                <span>Settings</span>
                            </Link>
                        } */}
                        <button onClick={logoutUser} className="dropdown-item notify-item">
                            <i className="mdi mdi-logout me-1"></i>
                            <span>Logout</span>
                        </button>
                    </div>
                </li>
            </ul>
            <button className="button-menu-mobile open-left">
                <i className="mdi mdi-menu"></i>
            </button>
            <span className={'float-right d-block'} style={{ width: window.innerWidth < 768 ? '58%' : '30%' }}>
                <Select
                    name="patients"
                    styles={styles}
                    // placeholder='Enter MRN/ patient name'
                    components={{ DropdownIndicator, Placeholder }}
                    isSearchable={true}
                    options={patients.map(mapSinglePatient)}
                    className="basic-multi-select mt-2 mr-2"
                    classNamePrefix="select"
                    defaultValue={patient}
                    filterOption={filterOption}
                    onInputChange={(value) => setSearchString(value)}
                    onChange={(value) => { setPatient(value); navigate('/patient-profile/' + value.id) }}
                />
            </span>
        </div>
    )
}

export default TopBar