import { useNavigate } from "react-router-dom";
import Button from "./Button";

const Header = ({ title, back, buttons, ...props }) => {

    const navigate = useNavigate();

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <div className="page-title-right">
                        {buttons && buttons.map((button, index) => {
                            return <Button type={button.type}
                                key={index}
                                className={button.className}
                                onClick={button.onClick}
                                label={button.label}
                                icon={button.icon} />
                        })}
                        {props.children}
                    </div>
                    <div className="d-flex">
                        {back && <span className="align-self-center text-secondary cursor-pointer" onClick={() => { navigate(-1) }}>
                            <i className="mdi mdi-arrow-left me-2 font-24"></i>
                        </span>}
                        <h4 className="page-title">{title}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;