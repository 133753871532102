import axios from "axios";
import { toast } from "react-toastify";

export async function getOTP(phoneNo, countryCode) {
  const response = await axios
    .post(process.env.REACT_APP_BASE_URL + "/users/verify_user", {
      phone_number: phoneNo,
      country_code: countryCode,
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
  return response.data;
}

export async function velidateOTP(phoneNo, otp) {
  const response = await axios
    .post(process.env.REACT_APP_BASE_URL + "/users/verify_otp", {
      phone_number: phoneNo,
      otp: otp,
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
  return response.data;
}
