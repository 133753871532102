import { ActionTypes } from "../constants/actionTypes"

export const setRoleDetail = (role) => {
    return {
        type: ActionTypes.SET_ROLE,
        payload: role
    }
}

export const setLoaderView = (loaderState) => {
    return {
        type: ActionTypes.SHOW_HIDE_LOADER,
        payload: loaderState
    }
}