import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createUser, deleteUser, fetchRoles, fetchUserDetails, fetchUsers, updateUser } from '../../api/settingsApi';
import { validateEmail } from '../../helper/validators';
import { setLoaderView } from '../../redux/actions/settingActions';
import Button from '../Common/Button';
import DeleteConfirmationPopup from '../Common/DeleteConfirmationPopup';
import { FiltersContext } from '../Common/FiltersProvider';
import GridView from '../Common/GridView';
import { toQueryParams } from '../Common/helpers/filtersHelpers';
import AddUpdateUserPopup from './AddUpdateUserPopup';
import { setUser } from '../../redux/actions/userActions';

const Users = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.token);
  const [users, setUsers] = useState([]);
  const [showAddUpdateUser, setShowAddUpdateUser] = useState(false);
  const [userData, setUserData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [roles, setRoles] = useState([]);

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  const { state: { filters } } = useContext(FiltersContext);

  const usersAction = (id, row) => {
    return (
      <div className="dropdown card-widgets">
        <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="mdi mdi-dots-vertical"></i>
        </span>
        <div className="dropdown-menu dropdown-menu-end" >
          <span className="dropdown-item cursor-pointer" onClick={() => {
            setUserData(row);
            setShowAddUpdateUser(true);
          }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
          {row?.user_data?.is_owner ? null :
            <span className="dropdown-item cursor-pointer" onClick={() => {
              setUserData(row);
              setDeleteId(id);
              setShowDeletePopup(true);
            }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
          }
        </div>
      </div>
    )
  }

  const usersColumns = [
    {
      dataField: 'user_data.name',
      text: 'User Name',
    }, {
      dataField: 'role_name',
      text: 'Assigned Role',
      sort: true,
      isFilterable: true,
      type: "input",
    }, {
      dataField: 'user_data.dob',
      text: 'DOB',
      sort: true,
      isFilterable: true,
      type: "datePicker"
    }, {
      dataField: 'user_data.email',
      text: 'Email',
    }, {
      dataField: 'user_data.gender',
      text: 'Gender',
      sort: true,
      isFilterable: true,
      type: "dropDown",
    }, {
      dataField: 'user_data.phone_number',
      text: 'Phone No',
      sort: true,
      isFilterable: true,
      type: "input",
      formatter: (cell, row) => `+${row?.user_data?.country_code}${cell}`
    }, {
      dataField: 'user_id',
      text: '',
      formatter: usersAction
    }
  ];

  useEffect(() => {
    dispatch(setLoaderView(true));
    fetchRoles(token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setRoles(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });
    fetchUsers(token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setUsers(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });
  }, []);


  const canDelete = (yesOrNo) => {
    if (yesOrNo) {
      dispatch(setLoaderView(true));
      if (userData) {
        deleteUser(deleteId, token).then(res => {
          if (res.status === 200) {
            toast.success(res.message);
            setShowDeletePopup(false);
            fetchUsers(token).then(res => {
              if (res.status === 200) {
                dispatch(setLoaderView(false));
                setUsers(res.data);
                setUserData(null);
              } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
              }
            }).catch(error => {
              dispatch(setLoaderView(false));
              // console.log(error);
            });
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }
        }).catch(error => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
      } else {
        dispatch(setLoaderView(false));
      }
    } else {
      setShowDeletePopup(false);
    }
  }

  const validateForm = (formData) => {
    if (formData.get('phone_number').length !== 8) {
      toast.error("Please enter valid phone no!")
      return false;
    } else if (!validateEmail(formData.get('email'))) {
      toast.error("Enter valid email address!");
      return false;
    } else if (formData.get('gender') === "0") {
      toast.error("Please select Gender!")
      return false;
    } else if (formData.get('role_id') === "0") {
      toast.error("Please select Role!")
      return false;
    } else {
      return true;
    }
  }

  const submitCreateUserHandler = (event, isDoctor) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    if (!isDoctor) {
      formData.append('is_doctor', 'off')
      formData.delete('signature');
    }
    if (validateForm(formData)) {
      if (userData) {
        dispatch(setLoaderView(true));
        updateUser(userData?.user_id, formData, token).then(res => {
          if (res.status === 200) {
            toast.success(res.message);
            fetchUsers(token).then(res => {
              dispatch(setLoaderView(false));
              if (res.status === 200) {
                setShowAddUpdateUser(false);
                setUserData(null);
                setUsers(res.data);
              } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
              }
            }).catch(error => {
              dispatch(setLoaderView(false));
              // console.log(error);
            });
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }


          fetchUserDetails(token).then(res => {
            dispatch(setUser(res.data))
          });
          
        }).catch(error => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
      } else {
        dispatch(setLoaderView(true));
        createUser(formData, token).then(res => {
          if (res.status === 200) {
            toast.success(res.message);
            fetchUsers(token).then(res => {
              dispatch(setLoaderView(false));
              if (res.status === 200) {
                setShowAddUpdateUser(false);
                setUsers(res.data);
              } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
              }
            }).catch(error => {
              dispatch(setLoaderView(false));
              // console.log(error);
            });
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }
        }).catch(error => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
      }
    }
  }

  const loadUser = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    dispatch(setLoaderView(true));
    fetchUsers(token, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setUsers(res.data);
        setTotalItems(res.total_count);
        setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
      } else {
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });
  }

  useEffect(() => {
    loadUser(toQueryParams(filters));
    // eslint-disable-next-line
  }, [pageNo, totalItemToBeFetched]);

  return (
    <div className='card'>
      <div className="card-body">
        <div className="row mt-4">
          <div className="col-md-12">
            <Button label="Create New User" className="btn btn-outline-primary  float-right mb-3" onClick={() => {
              setShowAddUpdateUser(true)
              setUserData(null)
            }} />
          </div>
        </div>

        <GridView
          data={users}
          columns={usersColumns}
          maxRow={10}
          hideFilters={true}
          searchKey="name"
          totalItems={totalItems}
          totalItemToBeFetched={totalItemToBeFetched}
          setTotalItemToBeFetched={setTotalItemToBeFetched}
          setPageNo={setPageNo}
          pageNo={pageNo}
          onFilter={loadUser}
        //fetchData={(page, size) => fetchProducts(page, size)} 
        />

        {showAddUpdateUser ? <AddUpdateUserPopup show={showAddUpdateUser} data={userData} onHide={() => setShowAddUpdateUser(false)} submitForm={submitCreateUserHandler} roles={roles} /> : null}
        {showDeletePopup ? <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} /> : null}
      </div>
    </div>
  )
}

export default Users;