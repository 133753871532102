import { ActionTypes } from "../constants/actionTypes"

export const roleReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_ROLE:
            return payload;
        default:
            return state;
    }
}

export const loaderReducer = (state = false, { type, payload }) => {
    switch (type) {
        case ActionTypes.SHOW_HIDE_LOADER:
            return payload;
        default:
            return state;
    }
}