
import Header from "../Common/Header";
import { useState } from "react";
import GridView from "../Common/GridView";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderView } from "../../redux/actions/settingActions";
import { deletePrescription, fetchPrescriptionsForVisit } from "../../api/prescriptionApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { fetchPatient } from "../../api/patientsApi";
import DeleteConfirmationPopup from "../Common/DeleteConfirmationPopup";
import { toQueryParams } from "../Common/helpers/filtersHelpers";
import { useContext } from "react";
import { FiltersContext } from "../Common/FiltersProvider";


const ViewClinic = () => {

  const { patient_id, clinic_id } = useParams();
  const token = useSelector((state) => state?.token);
  const isDoctor = useSelector((state) => state?.user_detail?.user_data?.is_doctor);
  const dispatch = useDispatch();
  const [prescriptions, setPrescriptions] = useState([]);
  const [patientDetail, setPatientDetail] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { state: { filters } } = useContext(FiltersContext);

  // eslint-disable-next-line
  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  const location = useLocation();

  console.log('>>', location);

  useEffect(() => {
    dispatch(setLoaderView(true));
    // TO DO: need to write new api fetchPrescriptions based on clinic ID
    fetchPrescriptionsList();
    // eslint-disable-next-line
  }, []);

  const fetchPrescriptionsList = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    fetchPrescriptionsForVisit(clinic_id, token, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setPrescriptions(res.data?.reverse());
        setTotalItems(res.total_count);
        setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    });
  }

  useEffect(() => {
    fetchPrescriptionsList(toQueryParams(filters));
    // eslint-disable-next-line
  }, [pageNo, totalItemToBeFetched]);


  // eslint-disable-next-line
  const [clinicDetails, setClinicDetails] = useState({});

  const openPrescriptionDetails = (cell, row) => {
    return isDoctor ? <Link to={(`/preview-prescription-details/${patient_id}/${row.prescription_uuid}`)}>Prescription {cell}</Link> : <span>Prescription {cell}</span>
  }

  const action = (prescriptionId) => {
    if (!isDoctor) {
      return null;
    }
    return (
      <div className="dropdown card-widgets">
        <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="mdi mdi-dots-vertical"></i>
        </span>
        <div className="dropdown-menu dropdown-menu-end" >
          {isDoctor && <Link className="dropdown-item cursor-pointer" to={`/update-prescription/${patient_id}/${prescriptionId}`}><i className="uil-file-edit-alt me-1"></i>Update</Link>}
          {isDoctor && <span className="dropdown-item cursor-pointer" onClick={() => {
            setShowDeletePopup(true);
            setDeleteId(prescriptionId);
          }}><i className="uil uil-trash-alt me-1"></i>Delete</span>}
        </div>
      </div>
    )
  }

  const prescriptionsColumns = [
    {
      dataField: 'id',
      text: 'Prescription Name',
      formatter: openPrescriptionDetails
    }, {
      dataField: 'created_on',
      text: 'Created On',
      sort: true,
      isFilterable: true,
      type: "datePicker",
      formatter: (cell) => {
        let date = new Date(cell)
        return date.toDateString()
      }
    },
    {
      dataField: 'created_by_name',
      text: 'Created By',
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: 'id',
      text: '',
      formatter: action
    }
  ];

  useEffect(() => {
    dispatch(setLoaderView(true));
    fetchPatient(patient_id, token).then(res => {
      if (res.status === 200) {
        setPatientDetail(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    })
    // eslint-disable-next-line
  }, []);

  const canDelete = (yesOrNo) => {
    if (yesOrNo) {
      deletePrescription(deleteId, token).then(res => {
        dispatch(setLoaderView(false));
        if (res.status === 200) {
          toast.success(res.message);
          setShowDeletePopup(false);
          fetchPrescriptionsForVisit(clinic_id, token).then(res => {
            if (res.status === 200) {
              setPrescriptions(res.data?.reverse());
            } else {
              dispatch(setLoaderView(false));
              toast.error(res.message);
            }
          }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
          });
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      }).catch(error => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });
    } else {
      setShowDeletePopup(false);
    }
  }

  let dob = patientDetail?.dob ? patientDetail?.dob.split('-') : '';
  dob = dob.length > 1 ? `${dob[2]}-${dob[1]}-${dob[0]}` : '';
  return (
    <>
      <Header title="Clinic Details" back={true}>
      </Header>

      <div className="row">
        <div className="col-12 m-auto">
          <div className="card">
            <div className="card-body">
              <h4 className="mb-3 mt-2 text-center">Patient Details</h4>
              <div className="row">
                <div className="col-7">
                  <p className="mb-2"><strong>Full Name :</strong> <span className="ml-2">{patientDetail ? patientDetail?.first_name + ' ' + patientDetail?.last_name : null}</span></p>
                </div>
                <div className="col-5">
                  <p className="mb-2"><strong>Mobile :</strong><span className="ml-2">{patientDetail?.phone_number}</span></p>
                </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <p className="mb-2"><strong>Email :</strong> <span className="ml-2 ">{patientDetail?.email}</span></p>
                </div>
                <div className="col-5">
                  <p className="mb-2"><strong>Gender :</strong> <span className="ml-2">{patientDetail?.gender}</span></p>
                </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <p className="mb-2"><strong>DOB :</strong> <span className="ml-2">{dob}</span></p>
                </div>
                <div className="col-5">
                  <p className="mb-2"><strong>National Id :</strong> <span className="ml-2">{patientDetail?.national_id_pass_number}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {isDoctor && <Link to={"/create-prescription/" + patient_id + '/' + clinic_id} className="btn btn-primary float-right mb-3">Create Prescription</Link>}
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
                <li className="nav-item">
                  <a href="#clinics-tab" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0 active">
                    <i className="mdi mdi-account d-md-none d-block"></i>
                    <span className="d-none d-md-block">Prescription Details</span>
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-pane show active" id="clinics-tab">
                  <GridView
                    data={prescriptions}
                    columns={prescriptionsColumns}
                    maxRow={10}
                    hideFilters={true}
                    searchKey="name"
                    totalItems={totalItems}
                    totalItemToBeFetched={totalItemToBeFetched}
                    setTotalItemToBeFetched={setTotalItemToBeFetched}
                    pageNo={pageNo}
                    onFilter={fetchPrescriptionsList}
                    url={process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/?visit_id=" + clinic_id + '&page_no=' +
                      pageNo +
                      "&page_count=" +
                      totalItemToBeFetched}
                    setPageNo={setPageNo}
                  />
                  {/* <GridView
                    gridData={prescriptions}
                    columns={prescriptionsColumns}
                    totalSize={10}
                    sizePerPage={10}
                    keyField="id"
                  /> */}
                </div>
              </div>
              {showDeletePopup ? <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} /> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewClinic;