export const SideMenuConfig = [
    {
        path: '/',
        id: 'dashboard',
        label: 'Dashboard',
        icon: 'mdi mdi-view-dashboard'
    },
    {
        path: 'patients',
        id: 'patients',
        label: 'Patients',
        icon: 'mdi mdi-account'
    },
    // {
    //     path: 'favourites',
    //     id: 'favourites',
    //     label: 'Favourites',
    //     icon: 'uil-store'
    // },
    {
        // path: 'create-prescription',
        path: 'search-patient',
        id: 'create_prescription',
        label: 'Create Prescription',
        icon: 'mdi mdi-file-edit'
    },
    // {
    //     // path: 'create-prescription',
    //     path: 'total-prescriptions',
    //     id: 'create_prescription',
    //     label: 'Total Prescriptions',
    //     icon: 'mdi mdi-file'
    // },
    // {
    //     path: 'reports-and-analytics',
    //     id: 'reports_and_analytics',
    //     label: 'Reports & Analytics',
    //     icon: 'uil-store'
    // },
    {
        path: 'settings',
        id: 'settings',
        label: 'Settings',
        icon: 'mdi mdi-cog',
        subCategory: [
            {
                path: 'clinicDetails',
                id: 'clinicDetails',
                label: 'Clinic Details',
                icon: 'mdi mdi-cog'
            },
            {
                path: 'users',
                id: 'users',
                label: 'Users',
                icon: 'mdi mdi-cog'
            },
            {
                path: 'roles',
                id: 'roles',
                label: 'Roles',
                icon: 'mdi mdi-cog'
            },
            {
                path: 'complaints',
                id: 'complaints',
                label: 'Complaints',
                icon: 'mdi mdi-cog'
            },
            {
                path: 'diagnosis',
                id: 'diagnosis',
                label: 'Diagnosis',
                icon: 'mdi mdi-cog'
            },
            {
                path: 'tests',
                id: 'tests',
                label: 'Tests',
                icon: 'mdi mdi-cog'
            },
        ]
    },
    // {
    //     path: 'user-authorization',
    //     id: 'user_authorization',
    //     label: 'User Authorization',
    //     icon: 'uil-store'
    // }
]