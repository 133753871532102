import React, { createContext, useReducer } from 'react';

let FiltersContext = createContext();

let initialState = {
    filters: []
}

let reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'update-filters':
            return { ...state, filters: { ...state.filters, [action.payload.key]: action.payload.value } };
        case 'remove-single-filter':
            {
                delete state.filters[action.payload.key];
                return { ...state };
            }
        case 'reset-filters':
            delete state.filters
            return initialState;
        default: return initialState
    }
}

function FiltersProvider(props) {
    const { children } = props;
    let [state, dispatch] = useReducer(reducer, initialState);
    let value = { state, dispatch };

    return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}

export { FiltersProvider, FiltersContext, reducer };