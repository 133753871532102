import * as React from 'react';
import SelectDropDown from '../Common/SelectDropDown';
import Input from '../Common/Input';
import RequiredInput from '../Common/RequiredInput';
import { ageCalculator } from '../../helper/ageCalculator';
import PhoneInput from 'react-phone-input-2';
import CountryList from "./CountryCodes.json";

const AddPatientStep1 = ({ data, setCheckSalutation, errors, setPhone, setCountryCode, checkSalutation, setCheckGender, checkGender, setBtnDisable }) => {
  const [phoneCountryCode, setPhoneCountryCode] = React.useState(
    `${data?.country_code}${data?.phone_number}`
  );
  const [age, setAge] = React.useState(data?.age);
  const [dob, setDOB] = React.useState('');

  React.useEffect(() => {
    if (data?.dob) {
      setAge(ageCalculator(data.dob));
    }
  }, [data?.dob]);

  const salutation = [
    { value: "mr", label: "Mr" },
    { value: "mrs", label: "Mrs" },
    { value: "ms", label: "Miss" },
    { value: "dr", label: "Dr" },
  ];

  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  React.useEffect(() => {
    if (errors && Object.values(errors).some(val => val !== '' && val.length > 0)) {
      setBtnDisable(true);
    } else if (errors && Object.values(errors).every(val => val === '' && val.length === 0)) {
      setBtnDisable(false);
    }
  }, [errors]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-4 mb-3">
          <SelectDropDown
            options={salutation}
            label="Salutation"
            defaultValue={data?.salutation}
            name="salutation"
            required={true}
            className="form-control"
            onChange={() => setCheckSalutation()}
          />
          {checkSalutation === "0" ? (
            <small className="text-danger">Salutation is required</small>
          ) : null}
        </div>
        <div className="col-md-4 mb-3">
          <Input
            label="First Name"
            maxLength={20}
            minLength={1}
            defaultValue={data?.first_name}
            name="first_name"
            required={true}
            type="text"
            className="form-control"
            placeholder="Enter first name"
            regex={new RegExp(/[a-zA-Z0-9]+/)}
            regexLabel={'alphanumeric'}
          />
          <small className="text-danger">{errors && errors['first_name']}</small>
        </div>
        <div className="col-md-4 mb-3">
          <Input
            label="Last Name"
            maxLength={20}
            minLength={1}
            defaultValue={data?.last_name}
            name="last_name"
            required={true}
            type="text"
            className="form-control"
          />
          <small className="text-danger">{errors && errors['last_name']}</small>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-3">
          {/* <Input label="Phone" defaultValue={data?.phone_number} name="phone_number" required={true} type="number" className="form-control" placeholder="Enter phone no." /> */}
          <label>
            Phone <RequiredInput />
          </label>
          <PhoneInput
            onChange={(phoneNumber, countryCodeObj) => {
              setPhone(
                phoneNumber?.substring(countryCodeObj?.dialCode?.length)
              );
              setCountryCode(countryCodeObj?.dialCode);
              setPhoneCountryCode(phoneNumber);
            }}
            defaultMask={'.... ....'}
            country="qa"
            onlyCountries={["qa"]}
            required={true}
            name="phone_number"
            value={(data && data['']?.split(' ').join('').slice(1)) ||
              (data?.country_code && data?.phone_number && (data?.country_code + data?.phone_number))}
            className="phone-input"
          />
        </div>
        <div className="col-md-4 mb-3">
          <Input
            label="Email"
            defaultValue={data?.email}
            name="email"
            required={true}
            type="email"
            className="form-control"
            placeholder="Enter email id"
          // regex={errors && !errors['email'] && /^[^\s@]+@[^\s@]+\.[^\s@]+$/}
          // regexLabel={errors && !errors['email'] && 'i.e., example@gmail.com'}
          />
          <small className="text-danger">{errors && errors['email']}</small>
        </div>
        <div className="col-md-4 mb-3">
          <SelectDropDown
            options={gender}
            label="Gender"
            defaultValue={data?.gender}
            name="gender"
            required={true}
            className="form-control"
            onChange={() => setCheckGender()}
          />
          {checkGender === "0" ? (
            <small className="text-danger">Gender is required</small>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-3">
          <Input
            label="Date of Birth"
            max={new Date().toISOString().slice(0, 10)}
            defaultValue={data?.dob}
            name="dob"
            required={true}
            type="date"
            className="form-control"
            placeholder="Enter date of birth"
          // onChange={(event) => { calculateAge(event?.target?.value) }}
          />
          <small className="text-danger">{errors && errors['dob']}</small>
        </div>
        <div className="col-md-4 mb-3">
          <Input
            label="Age"
            // value={age || data?.age || (data?.dob && calculateAge(data?.dob))}
            value={age || data?.age}
            readOnly={true}
            // disabled={true}
            name="age"
            type="text"
            required={true}
            className="form-control"
            placeholder="Enter age"
          />
          <small className="text-danger">{errors && errors['age']}</small>
        </div>
        <div className="col-md-4 mb-3">
          <Input
            label="National Id / Passport Number"
            defaultValue={data?.national_id_pass_number}
            name="national_id_pass_number"
            required={true}
            maxLength={20}
            // regex={errors && !errors['national_id_pass_number'] && /^[a-zA-Z0-9]+$/}
            // regexLabel={errors && !errors['national_id_pass_number'] && 'alphanumeric'}
            type="text"
            className="form-control"
            placeholder="Enter National Id / Passport Number"
            disableSubmitBtn={(bool) => setBtnDisable(bool)}
          />
          <small className="text-danger">{errors && errors['national_id_pass_number']}</small>
        </div>
      </div>
      <div className="row mt-2 mb-4">
        <div className="col-6">
          <Input className='form-control' inputStyle={{ width: '100%' }} label={'Patient Nationality'} name="country" defaultValue={data?.country} />
          {/* <SelectDropDown
            options={CountryList.map(obj => {
              return {
                value: obj.name,
                id: obj.name,
              }
            })}
            label="Patient Nationality"
            name="patient_name"
            required={true}
            className="form-control basic-multi-select"
            classNamePrefix="select"
            onChange={() => { }}
          /> */}
        </div>
        <div className="col-6">
          <Input className='form-control' label={'Allergy Details'} name="allergies" defaultValue={data?.allergies} />
        </div>
      </div>
    </>
  );
}

export default AddPatientStep1;