const Button = ({ label, type, icon, onClick, id, ref, className, disabled }) => {
    return <button
        onClick={onClick}
        className={className}
        id={id}
        disabled={disabled}
        ref={ref}
        type={type}>
        {icon && <i className={icon}></i>}
        {label}
    </button>
}

export default Button;