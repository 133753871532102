import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createRole, deleteRole, fetchRoles, updateRole } from '../../api/settingsApi';
import { setLoaderView } from '../../redux/actions/settingActions';
import Button from '../Common/Button';
import DeleteConfirmationPopup from '../Common/DeleteConfirmationPopup';
import { FiltersContext } from '../Common/FiltersProvider';
import GridView from '../Common/GridView';
import { toQueryParams } from '../Common/helpers/filtersHelpers';
import AddUpdateRolePopup from './AddUpdateRolePopup';

const Roles = () => {
  const dispatch = useDispatch();
  const { state: { filters } } = useContext(FiltersContext);

  const token = useSelector((state) => state?.token);
  const [roles, setRoles] = useState([]);
  const [showAddUpdateRole, setShowAddUpdateRole] = useState(false);
  const [roleData, setRoleData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  useEffect(() => {
    dispatch(setLoaderView(true));
    fetchRoles(token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setRoles(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });
  }, []);

  const rolesAction = (id, row) => {
    return !row.is_admin_role ? (
      <div className="dropdown card-widgets">
        <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="mdi mdi-dots-vertical"></i>
        </span>
        <div className="dropdown-menu dropdown-menu-end" >
          <span className="dropdown-item cursor-pointer" onClick={() => {
            setRoleData(row);
            setShowAddUpdateRole(true);
          }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
          <span className="dropdown-item cursor-pointer" onClick={() => {
            setRoleData(row);
            setDeleteId(id);
            setShowDeletePopup(true);
          }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
        </div>
      </div>
    ) : null
  }

  const rolesColumns = [
    {
      dataField: 'name',
      text: 'Role Name',
      sort: true,
      isFilterable: true,
      type: "input",
    }, {
      dataField: 'description',
      text: 'Role Description',
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: 'id',
      text: '',
      formatter: rolesAction
    }
  ];

  const submitCreateRoleHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    dispatch(setLoaderView(true));
    if (roleData) {
      updateRole(roleData?.id, formData, token).then(res => {
        dispatch(setLoaderView(false));
        if (res.status === 200) {
          toast.success(res.message);
          fetchRoles(token).then(res => {
            if (res.status === 200) {
              setShowAddUpdateRole(false);
              setRoleData(null);
              setRoles(res.data);
            } else {
              dispatch(setLoaderView(false));
              toast.error(res.message);
            }
          }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
          });
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      }).catch(error => {
        dispatch(setLoaderView(false));
        const { response } = error;
        const { data } = response;
        toast.error(data.message);
      });
    } else {
      createRole(formData, token).then(res => {
        if (res.status === 200) {
          toast.success(res.message);
          fetchRoles(token).then(res => {
            if (res.status === 200) {
              setShowAddUpdateRole(false);
              dispatch(setLoaderView(false));
              setRoles(res.data);
            } else {
              dispatch(setLoaderView(false));
              toast.error(res.message);
            }
          }).catch(error => {
            dispatch(setLoaderView(false));
            const { response } = error;
            const { data } = response;
            toast.error(data.message);
          });
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      }).catch(error => {
        dispatch(setLoaderView(false));
        const { response } = error;
        const { data } = response;
        toast.error(data.message);
      });
    }
  }

  const canDelete = (yesOrNo) => {
    if (yesOrNo) {
      dispatch(setLoaderView(true));
      if (roleData) {
        deleteRole(deleteId, token).then(res => {
          if (res.status === 200) {
            toast.success(res.message);
            setShowDeletePopup(false);
            fetchRoles(token).then(res => {
              if (res.status === 200) {
                dispatch(setLoaderView(false));
                setRoles(res.data);
                setRoleData(null);
              } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
              }
            }).catch(error => {
              dispatch(setLoaderView(false));
              // console.log(error);
            });
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }
        }).catch(error => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
      } else {
        dispatch(setLoaderView(false));
      }
    } else {
      setShowDeletePopup(false);
    }
  }

  const loadRoles = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    dispatch(setLoaderView(true));

    fetchRoles(token, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setRoles(res.data);
        setTotalItems(res.total_count);
        setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
      } else {
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });
  }

  useEffect(() => {
    loadRoles(toQueryParams(filters));
    // eslint-disable-next-line
  }, [pageNo, totalItemToBeFetched]);


  return (
    <div className='card'>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <Button label="Create New Role" className="btn btn-outline-primary  float-right mb-3" onClick={() => {
              setShowAddUpdateRole(true)
              setRoleData(null)
            }} />
          </div>
        </div>

        <GridView
          data={roles}
          columns={rolesColumns}
          totalSize={10}
          hideFilters={true}
          sizePerPage={10}
          keyField="id"
          searchKey="name"
          totalItems={totalItems}
          totalItemToBeFetched={totalItemToBeFetched}
          setTotalItemToBeFetched={setTotalItemToBeFetched}
          pageNo={pageNo}
          setPageNo={setPageNo}
          onFilter={loadRoles}
        //fetchData={(page, size) => fetchProducts(page, size)} 
        />

        {showAddUpdateRole ? <AddUpdateRolePopup show={showAddUpdateRole} data={roleData} onHide={() => setShowAddUpdateRole(false)} submitForm={submitCreateRoleHandler} /> : null}
        {showDeletePopup ? <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} /> : null}
      </div>
    </div>
  )
}

export default Roles;