import Header from "../Common/Header";
import Pdf from "react-to-pdf";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { fetchAccessToken, fetchComplaints, fetchDiagnosisList, fetchDrugsByPrescription, fetchPrescriptionByPrecriptionId, fetchPrintPreviewPrescription, fetchTests } from "../../api/prescriptionApi";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderView } from "../../redux/actions/settingActions";
import GridView from "../Common/GridView";
import { dateFormatter, newDateFormatter } from "../../helper/dateFormatter";
import QRCode from "react-qr-code";
import TermsConditionPopup from "./TermsConditionPopup";
import axios from "axios";
import { fetchPatient } from "../../api/patientsApi";
import { formatValueLabel } from "../../helper/valueLabelFormatter";
import { fetchClinicDetail } from "../../api/settingsApi";

const PrescriptionDetail = () => {
  const dispatch = useDispatch();

  const { patient_id, prescription_id } = useParams();

  const [prescriptionDetail, setPrescriptionDetail] = useState(null);
  const [signature, setSignature] = useState();
  const [patientDetails, setpatientDetails] = useState({});
  const [showTC, setShowTC] = useState(false);
  const [complaints, setComplaints] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);
  const [tests, setTests] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const token = useSelector((state) => state?.token);
  const [clinicDetail, setClinicDetail] = useState();
  const ref = useRef();

  const user_detail = useSelector((state) => state?.user_detail);

  const drugsColumns = [
    {
      dataField: 'itemName',
      text: 'Drug Name'
    }, {
      dataField: 'strength',
      text: 'Strength',
      formatter: (cell, row) => `${cell}  ${row.strength_unit}`
    }, {
      dataField: 'preparation_name',
      text: 'Preparation'
    }, {
      dataField: 'dosage',
      text: 'Dosage',
      formatter: (cell, row) => `${cell} ${row.dosage_unit}`
    }, {
      dataField: 'route_name',
      text: 'Route'
    }, {
      dataField: 'direction_name',
      text: 'Direction'
    }, {
      dataField: 'frequency_name',
      text: 'Frequency'
    }, {
      dataField: 'duration',
      text: 'Duration',
      formatter: (cell, row) => `${cell} ${row.duration_unit}`
    }
  ];

  useEffect(() => {
    dispatch(setLoaderView(true));
    fetchPatient(patient_id, token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setpatientDetails(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    });

    fetchPrescriptionByPrecriptionId(patient_id, prescription_id, token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setPrescriptionDetail(res.data[0]);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    });

    fetchPrintPreviewPrescription(prescription_id, token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setSignature(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    });

    fetchDrugsByPrescription(prescription_id, token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setDrugs(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });

    fetchComplaints(token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setComplaints(formatValueLabel('id', 'name', res.data));
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });

    fetchTests(token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setTests(formatValueLabel('id', 'name', res.data));
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });

    fetchDiagnosisList(token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setDiagnosis(formatValueLabel('id', 'name', res.data));
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });

    fetchClinicDetail(token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setClinicDetail(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });
  }, []);

  const options = {
    orientation: "potrait",
    format: 'a1',
    unit: 'px',
  };

  const sendPrescription = () => {
    setShowTC(false);
    dispatch(setLoaderView(true));
    fetchAccessToken().then(res => {
      const formData = new FormData();
      formData.append('phone_number', patientDetails?.phone_number);
      formData.append('country_code', patientDetails?.country_code);
      formData.append('passport_no', patientDetails?.national_id_pass_number);
      formData.append('national_id', patientDetails?.national_id_pass_number);
      formData.append('salutation', patientDetails?.salutation);
      formData.append('email', patientDetails?.email);
      formData.append('dob', patientDetails?.dob);
      //formData.append('country', patientDetails?.country_code);
      formData.append('name', `${patientDetails?.first_name} ${patientDetails?.last_name}`);
      formData.append('prescription_uuid', prescription_id);

      axios({
        method: "post",
        url: process.env.REACT_APP_CONSUMER_URL + "/order/place_doctor_panel_order?access_token=" + res.access_token,
        data: formData
      }).then((res) => {
        dispatch(setLoaderView(false));
        if (res?.data?.status === 200) {
          toast.success(res.data.message);
          window.open(process.env.REACT_APP_CONSUMER_FE_URL + "auth/prescrition-login/" + prescription_id);
        } else if (res?.data?.status === 400) {
          toast.error(res.response.data.message);
        }
      }).catch(error => {
        dispatch(setLoaderView(false));
        toast.error(error.response.data.message);
      });
    }).catch(error => {
      dispatch(setLoaderView(false));
      toast.error(error.message);
    });
  }

  const printPrescription = () => {
    // let printContents = document.getElementById('prescription-container').innerHTML;
    // let originalContents = document.body.innerHTML;
    // dispatch(setLoaderView(true));
    // document.body.innerHTML = printContents;
    // window.print();
    // dispatch(setLoaderView(false));
    // document.body.innerHTML = originalContents;
    window.print();
  }

  return (
    <>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-1" />
            <div className="col-md-10">
              <Header title="Prescription Details" back={true}>
                <Pdf targetRef={ref} filename="prescription.pdf" scale={1.8} options={options} x={30} y={50}>
                  {({ toPdf }) => <button type="button" className="btn btn-outline-primary btn-block ml-2" onClick={toPdf}>Download Prescription</button>}
                </Pdf>
                <button type="button" className="btn btn-outline-primary btn-block ml-2" onClick={printPrescription}>Print</button>

                {/* <button type="button" className="btn btn-primary btn-block ml-2" onClick={() => setShowTC(true)}>Place Order on Medpack</button> */}

              </Header>
              <div className="card p-4" ref={ref}>
                <div className="row">
                  <div className="col-9">
                    <p className="m-0"><b>{clinicDetail?.name}</b></p>
                    <p className=" m-0">{user_detail?.user_data?.name}{(user_detail?.speciality_list?.map((obj) => {
                      return obj.speciality_name
                    }))?.length ? ' - ' : null} {(user_detail?.speciality_list?.map((obj) => {
                      return obj.speciality_name
                    }))?.join(', ')}</p>
                    <p className=" m-0">{newDateFormatter(prescriptionDetail?.created_on)}</p>
                  </div>
                  <div className="col-3">
                    {clinicDetail?.logo ? <img className="float-right" src={process.env.REACT_APP_BASE_URL + clinicDetail?.logo} alt="" height={70} width={70} /> : null}
                  </div>
                </div>
                <hr />
                <div className="">
                  <div className="col-12">
                    <div className="float-left mt-3">
                      <p className="m-0"><b>Patient Details</b></p>
                      <p className=" m-0">{patientDetails?.first_name} (Age-{patientDetails?.age})</p>
                    </div>
                  </div>
                </div>
                {prescriptionDetail?.complaint && complaints?.length > 0 ?
                  <div className="">
                    <div className="col-12">
                      <div className="float-left mt-3">
                        <p className="m-0"><b>Complaints</b></p>
                        <p className=" m-0">{complaints.filter(x => prescriptionDetail?.complaint.includes(x.id)).map((obj) => {
                          return obj.name
                        })?.join(', ')}</p>
                        <p className=" m-0">{prescriptionDetail?.complaint_comment}</p>
                      </div>
                    </div>
                  </div> : null}

                {prescriptionDetail?.diagnosis && diagnosis?.length > 0 ?
                  <div className="">
                    <div className="col-12">
                      <div className="float-left mt-3">
                        <p className="m-0"><b>Medical Diagnosis</b></p>
                        <p className=" m-0">{diagnosis.filter(x => prescriptionDetail?.diagnosis.includes(x.id)).map((obj) => {
                          return obj.name
                        })?.join(', ')}</p>
                        <p className=" m-0">{prescriptionDetail?.diagnosis_comment}</p>
                      </div>
                    </div>
                  </div> : null}

                {prescriptionDetail?.tests_id && tests?.length > 0 ?
                  <div className="">
                    <div className="col-12">
                      <div className="float-left mt-3">
                        <p className="m-0"><b>Tests</b></p>
                        <p className=" m-0">{tests.filter(x => prescriptionDetail?.tests_id.includes(x.id)).map((obj) => {
                          return obj.name
                        })?.join(', ')}</p>
                        <p className=" m-0">{prescriptionDetail?.tests_comment}</p>
                      </div>
                    </div>
                  </div> : null}

                <div className="">
                  <div className="col-md-12 mt-2">
                    {drugs?.length > 0 && <GridView
                      data={drugs}
                      columns={drugsColumns}
                      hidePagination={true}
                      hideFilters={true}
                      hideSearch={true}
                      totalSize={10}
                      sizePerPage={10}
                      keyField="id" />}
                  </div>
                </div>
                <div className="row  prescription-images">
                  <div className="width-auto signature">
                    <img src={process.env.REACT_APP_BASE_URL + signature?.doctor_clinic_details?.doctor_data?.signature} alt="" height="32" />
                    <h6 className="m-0">Doctor’s Signature</h6>
                  </div>
                  <div className="width-auto">
                    <QRCode id="qr_code_viewer" value={window.location.href} size={80} /><br />
                    <h6 className="m-0">QR Code</h6>
                  </div>
                </div>
                <hr />
                <div className="row ">
                  <div className="col-4 mb-3">
                    <h6 className="m-0">Address</h6>
                    <p className=" m-0">Address: {clinicDetail?.address}</p>
                    <p className=" m-0">Building No.: {clinicDetail?.building_number}</p>
                    <p className=" m-0">Street: {clinicDetail?.street}</p>
                    <p className=" m-0">Zone: {clinicDetail?.zone}</p>
                    <p className=" m-0">Unit Number: {clinicDetail?.unit_number}</p>
                  </div>
                  <div className="col-4 mb-3">
                    <h6 className="m-0">Contact Info</h6>
                    <p className=" m-0">
                      <p className=" m-0">{clinicDetail?.primary_contact_no}</p>
                      <p className=" m-0">{clinicDetail?.secondary_contact_no}</p>
                    </p>
                  </div>
                  <div className="col-4 mb-3">
                    <h6 className="m-0">Opening Hours</h6>
                    <p className=" m-0">
                      {clinicDetail?.opening_hour} - {clinicDetail?.closing_hour}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      {showTC ? <TermsConditionPopup show={showTC} onHide={() => setShowTC(false)} submitForm={sendPrescription} /> : null}
    </>
  )
}

export default PrescriptionDetail;