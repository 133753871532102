import axios from "axios";

const pathnameMap = {
    complaints: 'get_clinic_complaints',
    diagnosis: 'get_clinic_diagnosis',
    tests: 'get_clinic_test'
};

const pathIdMap = {
    complaints: 'clinic_complaints',
    diagnosis: 'clinic_diagnosis',
    tests: 'clinic_test'
};

const editIdMap = {
    complaints: 'complaints',
    diagnosis: 'diagnosis',
    tests: 'tests'
};

export async function fetchAllSideMenu(path, token, pageNo = 1, totalItemToBeFetched = 10, applyFilter, search_string, filters) {
    const response = await axios({
        method: "get",
        // url: process.env.REACT_APP_BASE_URL + "/master/" + pathnameMap[path.replace('/', '')],
        url:
            process.env.REACT_APP_BASE_URL +
            "/master/" +
            pathnameMap[path.replace("/", "")] +
            "?page_no=" +
            (applyFilter || search_string ? "1" : pageNo) +
            "&page_count=" +
            totalItemToBeFetched +
            (search_string ? "&search_string=" + search_string : ""),
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchAllSideMenuById(path, token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/" + pathIdMap[path.split('/')[3].toLowerCase()] + '/' + path.split('/')[4],
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function editCategoryId(path, formData, token) {
    const response = await axios({
        method: "patch",
        url: process.env.REACT_APP_BASE_URL + "/master/" + editIdMap[path.split('/')[3].toLowerCase()] + '/' + path.split('/')[4] + '/',
        headers: {
            Authorization: 'Token ' + token
        },
        data: formData,
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}