import * as React from 'react';
import Input from '../Common/Input';
import CreatableSelect from 'react-select/creatable'
import GridView from '../Common/GridView';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getAllDocTypes } from '../../api/patientsApi';
import { useSelector } from 'react-redux';


const AddPatientStep3 = ({ data, uploadedFiles, setUploadedFiles, setBtnDisable }) => {
  const token = useSelector((state) => state?.token);
  const [images, setImages] = React.useState(null);
  const [documentName, setDocumentName] = React.useState(null);
  const [imageError, setImageError] = React.useState(false);
  // eslint-disable-next-line
  const [add, setAdd] = React.useState(false);
  const [documentTypes, setDocumentTypes] = React.useState([]);
  const [fileSizeError, setFileSizeError] = React.useState('');

  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  useEffect(() => {
    setUploadedFiles(data?.document_list.map(item => ({ ...item, docName: item?.doc_type, del: Math.random(), name: item?.doc_type, file: item?.file, fileData: item?.file })));
  }, [data?.document_list]);


  const action = (cell, row) => {
    return (
      <span className="dropdown-item cursor-pointer" onClick={() => {
        setUploadedFiles(prevState => prevState.filter(({ del }) => del !== cell));
      }}><i className="uil uil-trash-alt me-1"></i></span>
    )
  }

  const fileColumns = [
    {
      dataField: 'name',
      text: 'Document Type',
      formatter: (cell, row) => row?.doc_type || cell
    },
    {
      dataField: 'fileData',
      text: 'File',
      formatter: (cell, row) => {
        return <img
          style={{ margin: 'auto' }}
          id="img"
          src={row?.file && typeof row?.file === 'string' ? process.env.REACT_APP_BASE_URL + row?.file : cell}
          height={300}
          width={400}
          alt={cell?.toString()}
        />
      }
    },
    {
      dataField: 'del',
      text: '',
      formatter: action
    },
  ];

  React.useEffect(() => {
    getAllDocTypes(token).then(res => {
      if (res.status === 200) {
        const list = [
          ...res.data,
          // {
          //   created_on: "2023-04-08T20:32:32.584908+05:30",
          //   id: 2,
          //   is_active: true,
          //   name: "passport"
          // }
        ];
        setDocumentTypes(list.map(x => ({ ...x, label: x.name, value: x.id })));
      } else {
        toast.error(res.message);
      }
    }).catch(error => {
      // console.log(error);
    });
  }, []);

  React.useEffect(() => {
    setBtnDisable(imageError);
  }, [imageError]);

  return (
    <div className='document-upload'>
      <div>
        <label className="mt-2" style={{ color: imageError ? 'red' : '' }}>{"Attach Files of Type JPEG, PNG or PDF format (Max File Size : 5mb)"}</label>
      </div>
      <div className='d-flex align-items-center my-2'>
        <div className='col-3 mr-2 pb-2 mt-2'>
          <CreatableSelect
            options={documentTypes}
            label="Document Name"
            name="status"
            required={true}
            className="basic-multi-select"
            classNamePrefix="select"
            value={documentName}
            onChange={(docName) => { setDocumentName(docName); }}
          />
        </div>
        <div className="form-control upload-input-placeholader-txt mr-2">
          <Input
            type="file"
            accept="image/*, .pdf"
            id="image-file"
            onChange={(e) => {
              if (e.target.files[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].some(x => x === e.target.files[0]?.type)) {
                setImages({ docName: documentName.value, del: Math.random(), name: documentName.label, file: e.target.files[0], fileData: URL.createObjectURL(e.target.files[0]) });
                setImageError(false);
              } else {
                e.target.value = null;
                setImageError(true);
              } if (e.target.files[0].size >= MAX_FILE_SIZE) {
                setFileSizeError('File size must be less than 5 MB');
                setImageError(true);
                setBtnDisable(true);
              } else {
                setFileSizeError('');
                setBtnDisable(false);
                setImageError(false);
              }
            }}
          />
        </div>
        <button
          className='btn add-doc-list'
          disabled={!documentName || !images || imageError}
          onClick={(e) => {
            document.getElementById('image-file').value = null;
            setUploadedFiles(prevState => prevState ? [...prevState, {
              ...images,
            }] : [{
              ...images,
            }]);
            setDocumentName(''); setImages(null);
          }}>+</button>
      </div>
      <h5 className="text-danger">{fileSizeError}</h5>
      <div className="row">
        <div className="col-md-12 ">
          {uploadedFiles?.length > 0 && <GridView
            data={uploadedFiles}
            columns={fileColumns}
            hideFilters={true}
            hideSearch={true}
            hidePagination={true}
            keyField="docName"
          />}
        </div>
      </div>
    </div >
  );
}

export default AddPatientStep3;