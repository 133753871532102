import axios from "axios";
import { toast } from "react-toastify";

export async function fetchStrengthUnits(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_medicine_strength",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // // console.log(error)
    })
    return response.data;
}

export async function fetchDoseUnits(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_dosage_unit",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // // console.log(error)
    })
    return response.data;
}

export async function fetchDrugAvailableUnit(drugId, token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_drug_stoct_units/" + drugId,
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // // console.log(error)
    })
    return response.data;
}

export async function fetchDurationUnits(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_medicine_duration",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // // console.log(error)
    })
    return response.data;
}

export async function fetchRoute(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_medicine_route",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // // console.log(error)
    })
    return response.data;
}

export async function fetchProducts(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_products",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // // console.log(error)
    })
    return response.data;
}

export async function fetchComplaints(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/complaints/",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // // console.log(error)
    })
    return response.data;
}

export async function addComplaints(token, complaints) {
    const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/master/complaints/",
        headers: {
            Authorization: 'Token ' + token
        },
        data: complaints,
    }).catch((error) => {
        // // console.log(error)
    })
    return response.data;
}

export async function fetchTests(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/tests/",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function addTests(token, tests) {
    const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/master/tests/",
        headers: {
            Authorization: 'Token ' + token
        },
        data: tests,
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchDiagnosisList(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/diagnosis/",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function addDiagnosis(token, diagnosis) {
    const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/master/diagnosis/",
        headers: {
            Authorization: 'Token ' + token
        },
        data: diagnosis,
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchDiagnosis(token, keyword) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/diagnosis_filter?search_string=" + keyword,
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchPrescription(id, token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/?id=" + id, //prescription id
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchPrescriptionByPrecriptionId(patientId, prescriptionId, token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/?patient_id=" + patientId + '&patient_prescription_id=' + prescriptionId, //prescription id
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchAllPrescription(id, token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/?clinic_id=" + id,
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}


export async function fetchPrescriptions(id, token, pageNo = 1, totalItemToBeFetched = 10, applyFilter, search_string, filters) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/?patient_id=" + id
            + '&page_no=' +
            (applyFilter || search_string ? '1' : pageNo) +
            "&page_count=" +
            totalItemToBeFetched + '&' + (!search_string && filters) + (search_string ? "&search_string=" + search_string : ''), //patient id
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchPrescriptionsForVisit(id, token, pageNo = 1, totalItemToBeFetched = 10, applyFilter, search_string, filters) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/?visit_id=" + id +
            '&page_no=' +
            (applyFilter || search_string ? '1' : pageNo) +
            "&page_count=" +
            totalItemToBeFetched + '&' + (!search_string && filters) + (search_string ? "&search_string=" + search_string : ''), //patient id
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function addDrug(formData, token, drugId, newProduct, id) {
    formData.delete('drug_id');
    formData.delete('clinic_id');
    const response = await axios({
        method: drugId ? "patch" : "post",
        url: newProduct ? process.env.REACT_APP_BASE_URL + "/master/save_products" : drugId ? process.env.REACT_APP_BASE_URL + "/patients/patients-drug/" + id + '/' : process.env.REACT_APP_BASE_URL + "/patients/patients-drug/",
        data: formData,
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        error.response?.data && typeof error.response.data === 'object' && toast.error(
            <ul style={{ listStyleType: 'none' }}>
                <li><h4>Request Failed</h4></li>
                {Object.entries(error.response.data).map(error =>
                    <li style={{ marginBottom: '5px', fontWeight: '600' }}>
                        <span style={{ textTransform: 'uppercase' }}>{error[0] + ':  '}</span>
                        <span>{`${Array.isArray(error[1]) ? error[1][0] : error[1]}`}</span>
                    </li>)}
            </ul>)
    })
    return response.data;
}

export async function deleteDrug(id, token) {
    const response = await axios({
        method: "delete",
        url: process.env.REACT_APP_BASE_URL + "/patients/patients-drug/" + id + "/",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchDrugsByPrescription(id, token, pageNo, totalItemToBeFetched, applyFilter, search_string, filters) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/patients/patients-drug/?patient_prescription_id=" + id,
        // + '&page_no=' +
        // (applyFilter ? '1' : pageNo) +
        // "&page_count=" +
        // totalItemToBeFetched + '&' + (!search_string && filters) + (search_string ? "&search_string=" + search_string : ''),
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchDrugsByPatient(id, token, pageNo = 1, totalItemToBeFetched = 10, applyFilter, search_string, filters) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/patients/patients-drug/?patient_id=" + id,
        // + '&page_no=' +
        // (applyFilter ? '1' : pageNo) +
        // "&page_count=" +
        // totalItemToBeFetched + '&' + (!search_string && filters) + (search_string ? "&search_string=" + search_string : ''),
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function deletePrescription(id, token) {
    const response = await axios({
        method: "delete",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/" + id + "/",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function submitPrescription(formData, token) {
    const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/",
        data: formData,
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        toast.error(error?.response?.data?.message)
        // console.log(error);
    })
    return response.data;
}

export async function updatePrescription(id, formData, token) {
    const response = await axios({
        method: "patch",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/" + id + "/",
        data: formData,
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchPrintPreviewPrescription(id) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/get_final_prescription/" + id,
        // headers: {
        //     Authorization: 'Token ' + token
        // }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchPreperation(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_preparation",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchDirection(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_directions",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function fetchFrequency(token) {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/master/get_frequency",
        headers: {
            Authorization: 'Token ' + token
        }
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}


export async function fetchAccessToken() {
    const response = await axios({
        method: "get",
        url: process.env.REACT_APP_CONSUMER_URL + "/master/get_api_token",
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}

export async function autoSendPresctiotionImage(formData, uid) {
    const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/prescriptions/save_prescription_file/" + uid,
        data: formData
    }).catch((error) => {
        // console.log(error)
    })
    return response.data;
}