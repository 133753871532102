const toFormData = (filters) => {
    const formData = new FormData();
    Object.entries(filters).forEach(([key, value]) => formData.append(key, value));
    return formData;
}

const toQueryParams = (filters = {}) => {
    let queryParams = '';
    filters && Object.entries(filters).forEach((obj, index) => { if (obj[1] || obj[1] === false) { queryParams = queryParams + `${obj[0].includes('_status') ? 'status' : obj[0]}=${obj[1]}` + (Object.keys(filters).length - 1 !== index ? '&' : '') } });
    return queryParams;
}

export {
    toFormData,
    toQueryParams,
}