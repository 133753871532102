import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updatePatientVisit } from '../../api/patientsApi';
import { editCategoryId, fetchAllSideMenuById } from '../../api/sidemenuapi';
import { setLoaderView } from '../../redux/actions/settingActions';
import Input from "../Common/Input";

const DetailedViewEdit = ({ name, data: selectedData, onSuccess }) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.token);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [form, setForm] = useState({});

  const location = useLocation();
  const category = location.pathname.split('/')[3];
  const id = location.pathname.split('/')[4];
  const user_detail = useSelector((state) => state?.user_detail);

  useEffect(() => {
    setForm(selectedData || location.state)
  }, [selectedData || location.state]);

  const fetch = (name) => {
    fetchAllSideMenuById(location.pathname, token).then(res => {
      if (res.status === 1 || res.status === 200) {
        setData(res.data);
        setForm({
          name: res?.data?.name,
          description: res?.data?.description,
          created_by: res?.data?.created_by,
          modified_by: res?.data?.modified_by,
          created_on: res?.data?.created_on,
          modified_on: res?.data?.modified_on,
        });
      } else {
        toast.error(res.message);
      }
    }).catch(error => {
      // console.log(error);
    });
  }

  // useEffect(() => {
  //   if (id) fetch();
  // }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('modified_by', user_detail.user_id);
    editCategoryId(location.pathname, formData, token).then(res => {
      if (res.status === 200) {
        toast.success(res.message);
        onSuccess();
        // fetch(name)
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      toast.error(error);
      // console.log(error);
    });
  }

  return (
    <Modal
      show={id ? true : false}
      backdrop="static"
      size="lg"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="remove-padding add-new-patient"
    >
      <Modal.Header>
        <Modal.Title className="m-0" id="contained-modal-title-vcenter  justify-content-between" style={{ width: '100%', display: 'flex' }}>
          <span className="flex-grow-1">Edit {category && category?.slice(0, 1).toUpperCase() + category?.slice(1)}</span>
          <button
            type="button"
            onClick={() => navigate('/' + category?.slice(0, 1).toLowerCase() + category?.slice(1))}
            className="btn btn-close"
            data-bs-dismiss="modal"
          >
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <Input label={category + ' Name'} maxLength={50} name="name" value={form?.name} onChange={(e) => setForm({ ...form, name: e.target.value })} required={true} type="text" className="form-control" />
            </div>
            <div className="col-md-6 mb-3">
              <Input label={category + ' Description'} maxLength={50} name="description" value={form?.description} onChange={(e) => setForm({ ...form, description: e.target.value })} required={true} type="text" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <Input label={'Created By'} maxLength={50} name="created_by" value={form?.created_by} disabled={true} required={true} type="text" className="form-control" />
            </div>
            <div className="col-md-6 mb-3">
              <Input label={'Modifeid By'} maxLength={50} name="modified_by" value={form?.modified_by} disabled={true} required={true} type="text" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Created Date</label>
              <div className='mt-2'>{form?.created_on?.split('T')[0] + ' T' + form?.created_on?.split('T')[1].slice(0, 5)}</div>
            </div>
            <div className="col-md-6 mb-3">
              <label>Modified Date</label>
              <div className='mt-2'>{form?.modified_on?.split('T')[0] + ' T' + form?.modified_on?.split('T')[1].slice(0, 5)}</div>
            </div>
          </div>
          <div className='text-center'>
            <button className='btn btn-primary' style={{ width: '70px' }}>Save</button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  );
}

export default DetailedViewEdit;