import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchClinicDetail, submitClinicDetails } from '../../api/settingsApi';
import { setLoaderView } from '../../redux/actions/settingActions';
import Button from '../Common/Button';
import Input from '../Common/Input';
import RequiredInput from '../Common/RequiredInput';
import { fields } from './settingsFields';
import { dropDownKeys, fileKeys } from '../Patients/fields';

const ClinicDetails = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.token);
  const [clinicDetail, setClinicDetail] = useState();
  const [logo, setLogo] = useState(null);
  const [form, setFormData] = useState({});
  const [disable, setDisable] = useState(false);
  const [errors, setFormErrors] = useState({});
  const [imageError, setImageError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState('');
  const [openTime, setOpenTime] = useState();

  useEffect(() => {
    setOpenTime(clinicDetail?.opening_hour);
  }, [clinicDetail?.opening_hour])

  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  const validate = (value, key) => {
    if (key === 'building_number') {
      setDisable(form.zone?.length > 10 || form.street?.length > 20 || form.unit_number?.length > 10 || value.length > 10);
    } else if (key === 'street') {
      setDisable(form.zone?.length > 10 || form.building_number?.length > 10 || form.unit_number?.length > 10 || value.length > 10);
    } else if (key === 'zone') {
      setDisable(form.building_number?.length > 10 || form.street?.length > 20 || form.unit_number?.length > 10 || value.length > 10);
    } else if (key === 'unit_number') {
      setDisable(form.zone?.length > 10 || form.street?.length > 20 || form.building_number?.length > 10 || value.length > 10);
    }
  }

  useEffect(() => {
    dispatch(setLoaderView(true));
    fetchClinicDetail(token).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        localStorage.setItem('ClinicDetail', JSON.stringify(res.data));
        setClinicDetail(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });
  }, []);

  const validateClinicDetailsForm = (formData) => {
    if (((!logo || logo.size === 0) && !clinicDetail?.id) || (clinicDetail?.id && ((typeof formData.get('logo') !== 'object')))) {
      toast.error('Please select clinic logo!')
      setDisable(true);
    } else if (formData.get('primary_contact_no').length !== 8) {
      toast.error("Please enter valid primary phone no!");
      return false;
    } else if (formData.get('secondary_contact_no') && formData.get('secondary_contact_no').length !== 8) {
      toast.error("Please enter valid secondary phone no!");
      return false;
    }
    // else if (formData.get('opening_hour') && formData.get('closing_hour')) {
    //   const [fromHours, fromMinutes] = formData.get('opening_hour').split(":");
    //   const [toHours, toMinutes] = formData.get('closing_hour').split(":");

    //   const fromMilliseconds = (parseInt(fromHours, 10) * 60 + parseInt(fromMinutes, 10)) * 60 * 1000;
    //   const toMilliseconds = (parseInt(toHours, 10) * 60 + parseInt(toMinutes, 10)) * 60 * 1000;

    //   if (fromMilliseconds >= toMilliseconds) {
    //     toast.error("Opening Hour can not be greater than Closing Hours!");
    //   }
    //   return false;
    // } 
    else {
      return true;
    }
  }

  const submitClinicDetailsHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (validateClinicDetailsForm(formData)) {
      const primaryContactNo = formData.get('primary_contact_no');
      const secondaryContactNo = formData.get('secondary_contact_no');

      if (primaryContactNo === secondaryContactNo) {
        toast.error("Primary Contact No cannot match with Secondary Contact No!");
        return;
      }

      dispatch(setLoaderView(true));
      if (clinicDetail?.id && clinicDetail?.logo && formData.get('logo')?.name === '') {
        formData.delete('logo');
      }

      const [fromHours, fromMinutes] = formData.get('opening_hour').split(":");
      const [toHours, toMinutes] = formData.get('closing_hour').split(":");
      const fromMilliseconds = (parseInt(fromHours, 10) * 60 + parseInt(fromMinutes, 10)) * 60 * 1000;
      const toMilliseconds = (parseInt(toHours, 10) * 60 + parseInt(toMinutes, 10)) * 60 * 1000;

      if (fromMilliseconds >= toMilliseconds) {
        dispatch(setLoaderView(false));
        toast.error("Closing Hour cannot be earlier than or equal to Opening Hours!");
        return;
      }

      submitClinicDetails(formData, token).then(res => {
        dispatch(setLoaderView(false));
        if (res.status === 200) {
          toast.success(res.message);
          setClinicDetail(res.data);
          setLogo(null);
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      }).catch(error => {
        dispatch(setLoaderView(false));
      });
    }
  }


  React.useEffect(() => {
    delete errors.secondary_contact_no;


    if (errors && Object.values(errors).some(val => val !== '' && val.length > 0)) {
      setDisable(true);
    } else if (errors && Object.values(errors).every(val => val === '' && val.length === 0)) {
      setDisable(false);
    }
  }, [errors]);

  const onInput = (key, value) => {
    const allFields = fields.clinic;
    if (allFields[key].required && !fileKeys.includes(key) && ((!dropDownKeys.includes(key) && (!value || value.length === 0)) || (dropDownKeys.includes(key) && value === '0'))) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: `${allFields[key].label} is required`
      }));
    } else if (allFields[key].required && !fileKeys.includes(key) && ((!dropDownKeys.includes(key) && (value || value.length > 0)) || (dropDownKeys.includes(key) && value !== '0')) && !allFields[key].pattern) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: ''
      }));
    } else if (!fileKeys.includes(key) && allFields[key].pattern && !allFields[key].pattern.test(value)) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: `${allFields[key].label} should contain only ${allFields[key].patternLabel} value`
      }));
    } else if (!fileKeys.includes(key) && allFields[key].pattern && allFields[key].pattern.test(value)) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: ''
      }));
    }
  }

  return (
    <>
      <div className='card'>
        <div className="card-body">
          {clinicDetail?.name && <form onSubmit={submitClinicDetailsHandler} onInput={(e) => onInput(e.target.name, e.target.value)}>
            <div className="row">
              <div className="col-md-6 mb-3 text-center align-self-center">
                {/* {logo && !imageError ? */}
                {logo ?
                  <img className="logo-image-upload-view" src={logo ? URL.createObjectURL(logo?.target?.files[0]) : "/assets/images/logo.png"} alt="logo" />
                  :
                  <img className="logo-image-upload-view" src={process.env.REACT_APP_BASE_URL + clinicDetail?.logo} alt="logo" />
                }
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="example-fileinput" className="form-label">Logo <RequiredInput /></label>
                <label className="mb-2" style={{ color: imageError ? 'red' : '' }}>{"Attach logo File of Type JPEG, PNG format (Max File Size : 5mb)"}</label>
                <input
                  type="file"
                  accept="image/*"
                  required={clinicDetail?.logo ? false : true}
                  name="logo"
                  onChange={(e) => {
                    if (e.target.files[0] && ['image/png', 'image/jpg', 'image/jpeg'].some(x => x === e.target.files[0]?.type)) {
                      setLogo(e);
                      setImageError(false);
                    } else {
                      e.target.value = null;
                      setImageError(true);
                    } if (e.target.files[0].size >= MAX_FILE_SIZE) {
                      setFileSizeError('File size must be less than 5 MB');
                      setImageError(true);
                      setDisable(true);
                    } else {
                      setFileSizeError('');
                      setDisable(false);
                      setImageError(false);
                    }
                  }}
                  id="example-fileinput"
                  className="form-control" />
                <h5 className="text-danger">{fileSizeError}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <input type="hidden" name="clinic_id" value={clinicDetail?.id} />
                <Input label="Clinic Name" maxLength={50} name="name" defaultValue={clinicDetail?.name} required={true} minLength={1} type="text" className="form-control" placeholder="Enter clinic name" />
                <small className="text-danger">{errors && errors['name']}</small>
              </div>
              <div className="col-md-6 mb-3">
                <Input label="Email ID" name="email" defaultValue={clinicDetail?.email} required={true} minLength={1} type="email" className="form-control" placeholder="Enter email id" />
                <small className="text-danger">{errors && errors['email']}</small>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Input label="Primary Contact No" defaultValue={clinicDetail?.primary_contact_no} limit={8} name="primary_contact_no" required={true} minLength={1} type="number" className="form-control" placeholder="Enter primary contact no" />
                <small className="text-danger">{errors && errors['primary_contact_number']}</small>
              </div>
              <div className="col-md-6 mb-3">
                <Input label="Secondary Contact No" defaultValue={clinicDetail?.secondary_contact_no} limit={8} name="secondary_contact_no" minLength={1} type="number" className="form-control" placeholder="Enter secondary contact no" />
                {/* <small className="text-danger">{errors && errors['secondary_contact_number']}</small> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Input label="Address" maxLength={200} minLength={2} defaultValue={clinicDetail?.address} name="address" required={true} type="text" className="form-control" placeholder="Enter clinic address" />
                <small className="text-danger">{errors && errors['address']}</small>
              </div>
              <div className="col-md-3 mb-3">
                <Input label="Building Number" limit={10} maxLength={5} minLength={1} onChange={(e) => { setFormData({ ...form, building_number: e.target.value }); validate(e.target.value, 'building_number'); }} defaultValue={form?.building_number || clinicDetail?.building_number} name="building_number" required={true} type="number" className="form-control" placeholder="Enter clinic building number" />
              </div>
              <div className="col-md-3 mb-3">
                <Input label="Street" limit={20} minLength={1} onChange={(e) => { setFormData({ ...form, street: e.target.value }); validate(e.target.value, 'street'); }} defaultValue={form?.street || clinicDetail?.street} name="street" required={true} className="form-control" placeholder="Enter clinic street" />
              </div>
              <div className="col-md-3 mb-3">
                <Input label="Zone" limit={10} maxLength={5} minLength={1} onChange={(e) => { setFormData({ ...form, zone: e.target.value }); validate(e.target.value, 'zone'); }} defaultValue={form?.zone || clinicDetail?.zone} name="zone" required={true} type="number" className="form-control" placeholder="Enter clinic zone" />
              </div>
              <div className="col-md-3 mb-3">
                <Input label="Unit Number" limit={10} maxLength={5} onChange={(e) => { setFormData({ ...form, unit_number: e.target.value }); validate(e.target.value, 'unit_number'); }} defaultValue={form?.unit_number || clinicDetail?.unit_number} name="unit_number" required={true} minLength={1} type="number" className="form-control" placeholder="Enter clinic unit number" />
              </div>
              <div className="col-md-3 mb-3">
                <Input label="Max Visit" limit={10} maxLength={3} onChange={(e) => { setFormData({ ...form, max_visits_allowed: e.target.value }); }} defaultValue={form?.max_visits_allowed || clinicDetail?.max_visits_allowed} name="unit_number" required={true} minLength={1} type="number" className="form-control" placeholder="Enter clinic unit number" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <Input label="Opening Hours" defaultValue={clinicDetail?.opening_hour} name="opening_hour" required={true} minLength={1} type="time" className="form-control" placeholder="Enter clinic opening hours" onChange={(e) => setOpenTime(e.target.value)} />
                <small className="text-danger">{errors && errors['opening_hours']}</small>
              </div>
              <div className="col-md-3 mb-3">
                <Input label="Closing Hours" defaultValue={clinicDetail?.closing_hour}
                  // min={openTime}
                  name="closing_hour" required={true} minLength={1} type="time" className="form-control" placeholder="Enter clinic closing hours" />
                <small className="text-danger">{errors && errors['closing_hours']}</small>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <Button type="submit" disabled={disable} className="btn btn-primary" label="Save Details" />
              </div>
            </div>
          </form>}
        </div>
      </div>
    </>
  )
}

export default ClinicDetails;