import Input from "../Common/Input";
import Header from "../Common/Header";
import IconHeading from "../Common/IconHeading";
import Select from 'react-select/creatable';
import AddDrugPopup from "./AddDrugPopup";
import { useState, useEffect } from "react";
import Button from "../Common/Button";
import TextArea from "../Common/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { addComplaints, addDiagnosis, addDrug, addTests, deleteDrug, fetchComplaints, fetchDiagnosisList, fetchDrugsByPatient, fetchTests, submitPrescription } from "../../api/prescriptionApi";
import { toast } from "react-toastify";
import GridView from "../Common/GridView";
import { useNavigate, useParams } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { fetchPatient } from "../../api/patientsApi";
import { formatValueLabel } from "../../helper/valueLabelFormatter";
import DeleteConfirmationPopup from "../Common/DeleteConfirmationPopup";
import { useContext } from "react";
import { FiltersContext } from "../Common/FiltersProvider";
import { toQueryParams } from "../Common/helpers/filtersHelpers";
import hasSpecialChar from "../utils/hasSpecialChar";

const CreatePrescription = () => {

    const { patient_id } = useParams();
    const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // const createOption = (label) => ({
    //     label,
    //     value: label.toLowerCase().replace(/\W/g, ''),
    // });

    // const handleCreate = (inputValue, setOptions) => {
    //     setTimeout(() => {
    //         const newOption = createOption(inputValue);
    //         setOptions((prev) => [...prev, newOption]);
    //     }, 1000);
    // };

    const token = useSelector((state) => state?.token);
    // const [values, setValues] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [patientDetail, setPatientDetail] = useState();
    const [showAddDrug, setShowAddDrug] = useState(false);
    const [complaints, setComplaints] = useState([]);
    const [allComplaints, setAllComplaints] = useState([]);
    const [diagnosis, setDiagnosis] = useState([]);
    // eslint-disable-next-line
    const [allDiagnosis, setAllDiagnosis] = useState([]);
    const [tests, setTests] = useState([]);
    const [allTests, setAllTests] = useState([]);
    const [drugs, setDrugs] = useState([]);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedDrug, setSelectedDrug] = useState({});

    const { state: { filters } } = useContext(FiltersContext);

    // eslint-disable-next-line
    const [totalItems, setTotalItems] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

    const { clinic_id } = useParams();

    const action = (id, row) => {
        return (
            <div className="dropdown card-widgets">
                <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                </span>
                <div className="dropdown-menu dropdown-menu-end" >
                    <span className="dropdown-item cursor-pointer" onClick={() => { setShowAddDrug(true); setSelectedDrug({ ...row, isEditable: true }); }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                    <span className="dropdown-item cursor-pointer" onClick={() => {
                        setShowDeletePopup(true);
                        setDeleteId(id);
                    }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                </div>
            </div>
        )
    }

    const drugsColumns = [
        {
            dataField: 'itemName',
            text: 'Drug Name',
            sort: true,
            isFilterable: true,
            type: "input",
        }, {
            dataField: 'strength',
            text: 'Strength',
            sort: true,
            isFilterable: true,
            type: "input",
            formatter: (cell, row) => `${cell} ${row.strength_unit}`
        }, {
            dataField: 'preparation_name',
            text: 'Preparation',
            sort: true,
            isFilterable: true,
            type: "input",
        }, {
            dataField: 'dosage',
            text: 'Dosage',
            sort: true,
            isFilterable: true,
            type: "input",
            formatter: (cell, row) => row.preparation_name?.toLowerCase().includes('ointment') || row.preparation_name?.toLowerCase().includes('spray') ? 'Nil' : `${cell} ${row.dosage_unit}`
        }, {
            dataField: 'route_name',
            text: 'Route',
            sort: true,
            isFilterable: true,
            type: "input",
        }, {
            dataField: 'direction_name',
            text: 'Direction',
            sort: true,
            isFilterable: true,
            type: "input",
        }, {
            dataField: 'frequency_name',
            text: 'Frequency',
            sort: true,
            isFilterable: true,
            type: "input",
            formatter: (cell) => cell && cell.split('-').map(val => val || 0).join('-')
        }, {
            dataField: 'duration',
            text: 'Duration',
            sort: true,
            isFilterable: true,
            type: "input",
            formatter: (cell, row) => `${cell}  ${row.duration_unit}`
        }, {
            dataField: 'id',
            text: '',
            formatter: action
        }
    ];
    const fetchAllComplaints = (newId, currentOptions = []) => {
        fetchComplaints(token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setComplaints(formatValueLabel('id', 'name', res.data));
                newId && currentOptions && setAllComplaints(prevState => [...prevState, ...formatValueLabel('id', 'name', res.data).filter(({ value }) => value === newId)])
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });
    }
    const fetchAllDiagnosis = (newId, currentOptions = []) => {
        fetchDiagnosisList(token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setDiagnosis(formatValueLabel('id', 'name', res.data));
                newId && currentOptions && setSelectedDiagnosis(prevState => [...prevState, ...formatValueLabel('id', 'name', res.data).filter(({ value }) => value === newId)])
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });
    }
    const fetchAllTests = (newId, currentOptions = []) => {
        fetchTests(token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setTests(formatValueLabel('id', 'name', res.data));
                newId && currentOptions && setAllTests(prevState => [...prevState, ...formatValueLabel('id', 'name', res.data).filter(({ value }) => value === newId)])
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });
    }

    useEffect(() => {
        dispatch(setLoaderView(true));
        fetchPatient(patient_id, token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setPatientDetail(res.data);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });

        fetchCreatePrescriptionDrugs();
        fetchAllComplaints();
        fetchAllDiagnosis();
        fetchAllTests();
        // eslint-disable-next-line
    }, []);


    // const gender = [
    //     { value: 'Male', label: 'Male' },
    //     { value: 'Female', label: 'Female' }
    // ]

    const fetchCreatePrescriptionDrugs = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
        fetchDrugsByPatient(patient_id, token, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setDrugs(res.data);
                setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error);
        });
    }

    const validateAddDrug = (formData, frequency, isNewDrug, doseValue) => {
        if ((!selectedDrug?.itemName && !isNewDrug) || (!selectedDrug.value && isNewDrug)) {
            toast.error("Please select a Drug!")
            return false;
        } else if (!formData.get('strength')) {
            toast.error("Please enter correct strength!")
            return false;
        } else if (formData.get('strength_unit') === "0") {
            toast.error("Please select strength unit!")
            return false;
        }
        else if (!formData.get('prepration')) {
            toast.error("Please select preperation!")
            return false;
        }
        else if (!formData.get('route')) {
            toast.error("Please select route!")
            return false;
        }
        else if (!formData.get('dosage') > 0 && doseValue !== 0) {
            toast.error("Please enter correct dosage!")
            return false;
        } else if (formData.get('dosage_unit') === "0") {
            toast.error("Please select dosage unit!")
            return false;
        }
        else if (!formData.get('direction')) {
            toast.error("Please select direction!")
            return false;
        }
        // else if (!Object.values(frequency)) {
        //     toast.error("Please select frequency!")
        //     return false;
        // }
        else if (!formData.get('duration') > 0) {
            toast.error("Please enter correct duration!")
            return false;
        } else if (formData.get('duration_unit') === "0") {
            toast.error("Please select duration unit!")
            return false;
        } else {
            return true;
        }
    }

    const submitAddDrugForm = (event, frequency, setFrequency, drug, newDrug, doseValue) => {
        event.stopPropagation();
        event.preventDefault();
        const formData = new FormData(event.target);
        const newFormData = new FormData();
        if (selectedDrug?.isEditable) {
            for (const pair of formData.entries()) {
                console.log('>>', `${pair[0]}, ${pair[1]}`);
                newFormData.append(pair[0], pair[1] || drug[pair[0]]);
            }
            newFormData.append('itemName', drug?.itemName);
            if (doseValue === 0) {
                formData.delete('dosage');
                formData.delete('dosage_unit');
                newFormData.delete('dosage');
                newFormData.delete('dosage_unit');
            }
            if (formData.get('preparation_name')?.toLowerCase() === 'ointment' || newFormData.get('preparation_name')?.toLowerCase() === 'spray') {
                formData.delete('dosage_unit');
                newFormData.delete('dosage_unit_id');
                formData.delete('dosage_unit_id');
                newFormData.delete('dosage_unit');
            }
        }
        if (validateAddDrug(selectedDrug?.isEditable ? newFormData : formData, frequency, newDrug, doseValue)) {
            const { m, a, n } = frequency || { m: '0', a: '0', n: '0' };
            dispatch(setLoaderView(true));
            frequency && formData.append('frequency', Object.values({ m: m || 0, a: a || 0, n: n || 0 }).join('-'));
            frequency && newFormData.append('frequency', Object.values({ m: m || 0, a: a || 0, n: n || 0 }).join('-'));
            addDrug(selectedDrug?.isEditable ? newFormData : formData, token, selectedDrug?.isEditable, newDrug, selectedDrug?.id).then(res => {
                if (newDrug) {
                    addDrug(selectedDrug?.isEditable ? newFormData : formData, token, selectedDrug?.isEditable, false, selectedDrug?.id).then(res => {
                        toast.success(res.message);
                        dispatch(setLoaderView(false));
                        setShowAddDrug(false);
                        setDrugs(res.data);
                        setFrequency({});
                        fetchCreatePrescriptionDrugs();
                        setSelectedDrug({});
                    }).catch(error => {
                        dispatch(setLoaderView(false));
                        // console.log(error);
                    });
                } else {
                    toast.success(res.message);
                    dispatch(setLoaderView(false));
                    setShowAddDrug(false);
                    setDrugs(res.data);
                    setFrequency({});
                    fetchCreatePrescriptionDrugs();
                    setSelectedDrug({});
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                // console.log(error);
            });
        }
    }

    const submitGeneratePrescriptionForm = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData(event.target);
        formData.append('visit_id', clinic_id);
        for (const key of formData.keys()) {
            if (key.startsWith('complaint') || key.startsWith('diagnosis') || key.startsWith('tests_id')) {
                formData.delete(key);
            }
        }

        let complaintList = [];
        allComplaints.forEach(complaint => {
            complaintList.push(complaint?.id);
        });
        complaintList && formData.append('complaint', complaintList.join(','));

        let diagnosisList = [];
        selectedDiagnosis.forEach(diagnosis => {
            diagnosisList.push(diagnosis?.id);
        });
        diagnosisList?.length > 0 && formData.append('diagnosis', diagnosisList.join(','));

        let testsList = [];
        allTests.forEach(tests => {
            testsList.push(tests.value);
        });
        testsList && formData.append('tests_id', testsList.join(','));

        if (formData.get('complaint') && formData.get('doctor_comment')) {
            dispatch(setLoaderView(true));
            submitPrescription(formData, token).then(res => {
                dispatch(setLoaderView(false));
                if (res.status === 200) {
                    toast.success(res.message);
                    navigate("/preview-prescription/" + res.data.prescription_uuid, {
                        state: {
                            patient_id,
                            clinic_id
                        }
                    })
                    //window.open("/preview-prescription/" + patient_id + "/" + res.data.id)
                } else {
                    dispatch(setLoaderView(false));
                    toast.error(res.message);
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                // console.log(error);
            });
        }
        else {
            toast.warning("Complaints & Doctor's Comments are required !");
        }
    }

    const canDelete = (yesOrNo) => {
        if (yesOrNo) {
            dispatch(setLoaderView(true));
            deleteDrug(deleteId, token).then(res => {
                dispatch(setLoaderView(false));
                if (res.status === 200) {
                    toast.success(res.message);
                    setShowDeletePopup(false);
                    fetchDrugsByPatient(patient_id, token).then(res => {
                        if (res.status === 200) {
                            setDrugs(res.data);
                        }
                    });
                } else {
                    dispatch(setLoaderView(false));
                    toast.error(res.message);
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                // console.log(error);
            });
        } else {
            setShowDeletePopup(false);
        }
    }

    // const fetchDiagnosisByKeyword = (keyword) => {
    //     if (keyword.length > 3) {
    //         fetchDiagnosis(token, keyword).then(res => {
    //             dispatch(setLoaderView(false));
    //             if (res.status === 200) {
    //                 setDiagnosis(formatValueLabel('id', 'name', res.data));
    //             } else {
    //                 dispatch(setLoaderView(false));
    //                 toast.error(res.message);
    //             }
    //         }).catch(error => {
    //             dispatch(setLoaderView(false));
    //             // console.log(error);
    //         });
    //     }
    // }

    return (
        <>
            <Header title="Create Prescription" back={true} />

            {patientDetail && <div className="card">
                <div className="card-body">
                    <IconHeading title="Patient Details" icon="mdi-account" />
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <Input label="Patient Name" defaultValue={patientDetail?.first_name + ' ' + patientDetail?.last_name} name="patient_name" readOnly={true} required={false} type="text" className="form-control" placeholder="Enter product name" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <Input label="Gender" defaultValue={patientDetail?.gender} name="gender" readOnly={true} required={false} type="text" className="form-control" placeholder="Enter gender" />
                        </div>
                        <div className="col-md-4">
                            <Input label="Age" defaultValue={patientDetail?.age} name="age" readOnly={true} required={false} className="form-control" placeholder="Enter product name" />
                        </div>
                    </div>
                </div>
            </div>}

            <form onSubmit={submitGeneratePrescriptionForm}>
                <Input type="hidden" labelClassName="d-none" value={patient_id} name="patient_id" />
                <div className="card">
                    <div className="card-body">
                        <IconHeading title="Complaints" icon="mdi-account" />
                        <div className="row">
                            <div className="col-md-12">
                                <Select
                                    isMulti
                                    name="complaint"
                                    options={complaints}
                                    className="basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    onChange={(value) => {
                                        if (value.some(item => item.__isNew__)) {
                                            let newItem = value.filter(item => item.__isNew__);
                                            newItem = newItem[newItem.length - 1];
                                            const complaintsData = new FormData();
                                            if (!hasSpecialChar(newItem.label)) {
                                                complaintsData.append('name', newItem.label);
                                                complaintsData.append('description', newItem.label);
                                                addComplaints(token, complaintsData).then(res => { fetchAllComplaints(res?.data?.id, [value[value.length - 1]]); });
                                            }
                                            else {
                                                toast.warning('Spacial Character not allowed')
                                            }
                                        } else {
                                            setAllComplaints(value);
                                        }
                                    }}
                                // onCreateOption={(inputValue) => handleCreate(inputValue, setComplaints)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TextArea name="complaint_comment" className="form-control mt-n1" rows={5} placeholder="Add comments" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <IconHeading title="Diagnosis" icon="mdi-account" />
                        <div className="row">
                            <div className="col-md-12">
                                {/* onInputChange={(value) => fetchDiagnosisByKeyword(value)}  */}
                                <Select isMulti
                                    name="diagnosis"
                                    options={diagnosis}
                                    value={selectedDiagnosis}
                                    onChange={(value) => {
                                        if (value.some(item => item.__isNew__)) {
                                            let newItem = value.filter(item => item.__isNew__);
                                            newItem = newItem[newItem.length - 1];
                                            const diagnosisData = new FormData();
                                            if (!hasSpecialChar(newItem.label)) {
                                                diagnosisData.append('name', newItem.label);
                                                diagnosisData.append('description', newItem.label);
                                                addDiagnosis(token, diagnosisData).then(res => { fetchAllDiagnosis(res?.data?.id, [value[value.length - 1]]); });
                                            }
                                            else {
                                                toast.warning('Spacial Character not allowed')
                                            }
                                        } else {
                                            setSelectedDiagnosis(value);
                                        }
                                    }}
                                    className="basic-multi-select mt-2"
                                    classNamePrefix="select"
                                />
                                {/* <Select
                                    isMulti
                                    name="diagnosis"
                                    options={diagnosis}
                                    className="basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    onChange={(value) => { setAllDiagnosis(value) }}
                                // onCreateOption={(inputValue) => handleCreate(inputValue, setDiagnosis)}
                                /> */}
                            </div >
                        </div >
                        <div className="row">
                            <div className="col-md-12">
                                <TextArea name="diagnosis_comment" className="form-control mt-n1" rows={5} placeholder="Add comments" />
                            </div>
                        </div>
                    </div >
                </div >

                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <IconHeading title="Medication" icon="mdi-account" />
                            <Button type="button" label="Add Drug" className="btn btn-sm btn-outline-primary height-fit-content" onClick={() => setShowAddDrug(true)} />
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-2">
                                {drugs?.length > 0 &&
                                    <GridView
                                        hideFilters={true}
                                        hideSearch={true}
                                        hidePagination={true}
                                        data={drugs}
                                        columns={drugsColumns}
                                        // maxRow={10}
                                        searchKey="name"
                                    // totalItems={totalItems}
                                    // totalItemToBeFetched={totalItemToBeFetched}
                                    // setTotalItemToBeFetched={setTotalItemToBeFetched}
                                    // pageNo={pageNo}
                                    // onFilter={fetchCreatePrescriptionDrugs}
                                    // url={process.env.REACT_APP_BASE_URL + "/patients/patients-drug/?patient_id=" + patient_id + '&page_no=' +
                                    //     pageNo +
                                    //     "&page_count=" +
                                    //     totalItemToBeFetched}
                                    // setPageNo={setPageNo}
                                    />
                                    // <GridView
                                    //     gridData={drugs}
                                    //     columns={drugsColumns}
                                    //     totalSize={10}
                                    //     sizePerPage={10}
                                    //     keyField="id" />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <IconHeading title="Tests" icon="mdi-account" />
                        <div className="row">
                            <div className="col-md-12">
                                <Select
                                    isMulti
                                    name="tests_id"
                                    options={tests}
                                    className="basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    onChange={(value) => {
                                        if (value.some(item => item.__isNew__)) {
                                            let newItem = value.filter(item => item.__isNew__);
                                            newItem = newItem[newItem.length - 1];
                                            const testsData = new FormData();
                                            if (!hasSpecialChar(newItem.label)) {
                                                testsData.append('name', newItem.label);
                                                testsData.append('description', newItem.label);
                                                addTests(token, testsData).then(res => { fetchAllTests(res?.data?.id, [value[value.length - 1]]); });
                                            }
                                            else {
                                                toast.warning('Spacial Character not allowed')
                                            }
                                        } else {
                                            setAllTests(value);
                                        }
                                    }}
                                // onCreateOption={(inputValue) => handleCreate(inputValue, setTests)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <IconHeading title="Doctor's Comments" icon="mdi-account" />
                        <div className="row">
                            <div className="col-md-12">
                                <TextArea name="doctor_comment" className="form-control mt-n1" rows={5} placeholder="Add comments" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <Button type="submit" className="btn btn-primary mb-3" label="Generate Prescription" />
                    </div>
                </div>
            </form >

            <AddDrugPopup drug={selectedDrug} setSelectedDrug={setSelectedDrug} show={showAddDrug} onHide={() => setShowAddDrug(false)} submitForm={(e, frequency, drug, newDrug, setSelectedDrug, doseValue) => submitAddDrugForm(e, frequency, drug, newDrug, setSelectedDrug, doseValue)} />
            {showDeletePopup ? <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} /> : null}
        </>
    )
}

export default CreatePrescription;