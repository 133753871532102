import { useEffect, useState } from "react";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";

const CustomPagination = ({
  totalItems,
  totalItemToBeFetched,
  setTotalItemToBeFetched,
  pageNo,
  setPageNo,
}) => {
  const [TotalNumPages, setTotalNumPages] = useState(1);
  // set number of pages in pagination
  useEffect(() => {
    setTotalNumPages(Math.ceil(totalItems / totalItemToBeFetched));
    // if (totalItems > totalItemToBeFetched) {
    //   setTotalNumPages(Number((totalItems / totalItemToBeFetched).toFixed(0)));
    // } else {
    //   setTotalNumPages(1);
    // }
  }, [totalItems, totalItemToBeFetched]);

  return totalItems > 0 ? (
    <div className="custom-pagination d-flex align-items-center justify-content-between mt-3 px-4 pb-3">
      <select
        style={{ width: "70px", height: "30px" }}
        value={totalItemToBeFetched}
        onChange={(e) => {
          setTotalItemToBeFetched(e.target.value);
          setPageNo(1);
        }}
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </select>

      <Pagination
        {...bootstrap5PaginationPreset}
        current={pageNo}
        total={TotalNumPages}
        maxWidth={550}
        onPageChange={(e) => setPageNo(e)}
        activeItemClassName="active"
      />
    </div>
  ) : null;
};

export default CustomPagination;
