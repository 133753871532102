import { Modal } from "react-bootstrap";

const DeleteConfirmationPopup = ({ show, canDelete }) => {

    return (
        <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title className="m-0" id="contained-modal-title-vcenter">Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <h5>Are you sure you want to delete?</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={() => canDelete(false)} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" onClick={() => canDelete(true)} className="btn btn-primary">Delete</button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmationPopup;