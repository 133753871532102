import Header from "../Common/Header";
import Input from "../Common/Input";
import {
  createRole,
  createUser,
  deleteRole,
  deleteUser,
  fetchRoles,
  fetchUserDetails,
  fetchUsers,
  submitClinicDetails,
  updateRole,
  updateUser,
} from "../../api/settingsApi";
import { useSelector, useDispatch } from "react-redux";
import { setLoaderView } from "../../redux/actions/settingActions";
import { fetchClinicDetail } from "../../api/settingsApi";
import { useEffect, useState } from "react";
import Button from "../Common/Button";
import { toast } from "react-toastify";
import AddUpdateRolePopup from "./AddUpdateRolePopup";
import GridView from "../Common/GridView";
import DeleteConfirmationPopup from "../Common/DeleteConfirmationPopup";
import AddUpdateUserPopup from "./AddUpdateUserPopup";
import { validateEmail } from "../../helper/validators";
import RequiredInput from "../Common/RequiredInput";
import { setUser } from "../../redux/actions/userActions";

const Settings = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.token);

  // const [err, setErr] = useState(false);
  const [clinicDetail, setClinicDetail] = useState();
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showAddUpdateRole, setShowAddUpdateRole] = useState(false);
  const [showAddUpdateUser, setShowAddUpdateUser] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [logo, setLogo] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  // eslint-disable-next-line
  const [form, setFormData] = useState({});
  // eslint-disable-next-line
  const [disable, setDisable] = useState(false);

  const [checkGenderUser, setCheckGenderUser] = useState();
  const [checkRoleUser, setCheckRoleUser] = useState();

  // const validate = (value, key) => {
  //   if (key === "building_number") {
  //     setDisable(
  //       form.zone?.length > 10 ||
  //       form.street?.length > 10 ||
  //       form.unit_number?.length > 10 ||
  //       value?.length > 10
  //     );
  //   } else if (key === "street") {
  //     setDisable(
  //       form.zone?.length > 10 ||
  //       form.building_number?.length > 10 ||
  //       form.unit_number?.length > 10 ||
  //       value?.length > 10
  //     );
  //   } else if (key === "zone") {
  //     setDisable(
  //       form.building_number?.length > 10 ||
  //       form.street?.length > 10 ||
  //       form.unit_number?.length > 10 ||
  //       value?.length > 10
  //     );
  //   } else if (key === "unit_number") {
  //     setDisable(
  //       form.zone?.length > 10 ||
  //       form.street?.length > 10 ||
  //       form.building_number?.length > 10 ||
  //       value?.length > 10
  //     );
  //   }
  // };

  const rolesAction = (id, row) => {
    if (row.clinic_id != null) {
      return (
        <div className="dropdown card-widgets">
          <span
            className="dropdown-toggle arrow-none cursor-pointer card-drop"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="mdi mdi-dots-vertical"></i>
          </span>
          <div className="dropdown-menu dropdown-menu-end">
            <span
              className="dropdown-item cursor-pointer"
              onClick={() => {
                setRoleData(row);
                setShowAddUpdateRole(true);
              }}
            >
              <i className="uil-file-edit-alt me-1"></i>Edit
            </span>
            <span
              className="dropdown-item cursor-pointer"
              onClick={() => {
                setRoleData(row);
                setDeleteId(id);
                setShowDeletePopup(true);
              }}
            >
              <i className="uil uil-trash-alt me-1"></i>Delete
            </span>
          </div>
        </div>
      );
    } else {
      return null
    }

  };

  const usersAction = (id, row) => {
    return (
      <div className="dropdown card-widgets">
        <span
          className="dropdown-toggle arrow-none cursor-pointer card-drop"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="mdi mdi-dots-vertical"></i>
        </span>
        <div className="dropdown-menu dropdown-menu-end">
          <span
            className="dropdown-item cursor-pointer"
            onClick={() => {
              setUserData(row);
              setShowAddUpdateUser(true);
            }}
          >
            <i className="uil-file-edit-alt me-1"></i>Edit
          </span>
          {row?.user_data?.is_owner ? null : (
            <span
              className="dropdown-item cursor-pointer"
              onClick={() => {
                setUserData(row);
                setDeleteId(id);
                setShowDeletePopup(true);
              }}
            >
              <i className="uil uil-trash-alt me-1"></i>Delete
            </span>
          )}
        </div>
      </div>
    );
  };

  const rolesColumns = [
    {
      dataField: "name",
      text: "Role Name",
    },
    {
      dataField: "description",
      text: "Role Description",
    },
    {
      dataField: "id",
      text: "",
      formatter: rolesAction,
    },
  ];

  const usersColumns = [
    {
      dataField: "user_data.name",
      text: "User Name",
    },
    {
      dataField: "role_name",
      text: "Assigned Role",
    },
    {
      dataField: "user_data.dob",
      text: "DOB",
    },
    {
      dataField: "user_data.email",
      text: "Email",
    },
    {
      dataField: "user_data.gender",
      text: "Gender",
    },
    {
      dataField: "user_data.phone_number",
      text: "Phone No",
      formatter: (cell, row) => `+${row?.user_data?.country_code} ${cell.substring(0, 4)} ${cell.substring(4, 8)}`,
    },
    {
      dataField: "user_id",
      text: "",
      formatter: usersAction,
    },
  ];

  useEffect(() => {
    dispatch(setLoaderView(true));
    fetchClinicDetail(token)
      .then((res) => {
        dispatch(setLoaderView(false));
        if (res.status === 200) {
          setClinicDetail(res.data);
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });
    fetchRoles(token)
      .then((res) => {
        dispatch(setLoaderView(false));
        if (res.status === 200) {
          setRoles(res.data);
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });
    fetchUsers(token)
      .then((res) => {
        dispatch(setLoaderView(false));
        if (res.status === 200) {
          setUsers(res.data);
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });
  }, []);

  const validateClinicDetailsForm = (formData) => {
    if (formData.get("primary_contact_no")?.length > 14 || formData.get("primary_contact_no")?.length < 13) {
      toast.error("Please enter valid primary phone no!");
      return false;
    } else if (formData.get("secondary_contact_no")?.length > 14 || formData.get("secondary_contact_no")?.length < 13) {
      toast.error("Please enter valid secondary phone no!");
      return false;
    } else if (formData.get("opening_hour") > formData.get("closing_hour")) {
      toast.error("Opening Hour can not be greater than Closing Hours!");
      return false;
    } else {
      return true;
    }
  };

  const submitClinicDetailsHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    if (!logo?.target?.files[0]) {
      formData.set("logo", null);
    }
    if (validateClinicDetailsForm(formData)) {
      dispatch(setLoaderView(true));
      submitClinicDetails(formData, token)
        .then((res) => {
          dispatch(setLoaderView(false));
          if (res.status === 200) {
            toast.success(res.message);
            setClinicDetail(res.data);
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }
        })
        .catch((error) => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
    }

    fetchUserDetails(token).then(res => {
      dispatch(setUser(res.data))
    });
  };

  const submitCreateRoleHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    dispatch(setLoaderView(true));
    if (roleData) {
      updateRole(roleData?.id, formData, token)
        .then((res) => {
          dispatch(setLoaderView(false));
          if (res.status === 200) {
            toast.success(res.message);
            fetchRoles(token)
              .then((res) => {
                if (res.status === 200) {
                  setShowAddUpdateRole(false);
                  setRoleData(null);
                  setRoles(res.data);
                } else {
                  dispatch(setLoaderView(false));
                  toast.error(res.message);
                }
              })
              .catch((error) => {
                dispatch(setLoaderView(false));
                // console.log(error);
              });
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }
        })
        .catch((error) => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
    } else {
      createRole(formData, token)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.message);
            fetchRoles(token)
              .then((res) => {
                if (res.status === 200) {
                  setShowAddUpdateRole(false);
                  dispatch(setLoaderView(false));
                  setRoles(res.data);
                } else {
                  dispatch(setLoaderView(false));
                  toast.error(res.message);
                }
              })
              .catch((error) => {
                dispatch(setLoaderView(false));
                // console.log(error);
              });
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }
        })
        .catch((error) => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
    }
  };

  const validateForm = (formData) => {
    if (formData.get("phone_number")?.length !== 8) {
      toast.error("Please enter valid phone no!");
      return false;
    } else if (!validateEmail(formData.get("email"))) {
      toast.error("Enter valid email address!");
      return false;
    } else if (formData.get("gender") === "0") {
      // toast.error("Please select Gender!")
      setCheckGenderUser(formData.get("gender"));
      return false;
    } else if (formData.get("role_id") === "0") {
      // toast.error("Please select Role!")
      setCheckRoleUser(formData.get("role_id"));
      return false;
    } else {
      return true;
    }
  };

  const submitCreateUserHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    if (validateForm(formData)) {
      if (userData) {
        dispatch(setLoaderView(true));
        updateUser(userData?.user_id, formData, token)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.message);
              setShowAddUpdateUser(false);
              fetchUsers(token)
                .then((res) => {
                  dispatch(setLoaderView(false));
                  if (res.status === 200) {
                    setShowAddUpdateUser(false);
                    setUserData(null);
                    setUsers(res.data);
                  } else {
                    dispatch(setLoaderView(false));
                    toast.error(res.message);
                  }
                })
                .catch((error) => {
                  dispatch(setLoaderView(false));
                  // console.log(error);
                });
            } else {
              dispatch(setLoaderView(false));
              toast.error(res.message);
            }
          })
          .catch((error) => {
            dispatch(setLoaderView(false));
            // console.log(error);
          });
      } else {
        dispatch(setLoaderView(true));
        createUser(formData, token)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.message);
              setShowAddUpdateUser(false);
              fetchUsers(token)
                .then((res) => {
                  dispatch(setLoaderView(false));
                  if (res.status === 200) {
                    setUsers(res.data);
                  } else {
                    dispatch(setLoaderView(false));
                    toast.error(res.message);
                  }
                })
                .catch((error) => {
                  dispatch(setLoaderView(false));
                  // console.log(error);
                });
            } else {
              dispatch(setLoaderView(false));
              toast.error(res.message);
            }
          })
          .catch((error) => {
            dispatch(setLoaderView(false));
            // console.log(error);
          });
      }
    }
  };

  const canDelete = (yesOrNo) => {
    if (yesOrNo) {
      dispatch(setLoaderView(true));
      if (roleData) {
        deleteRole(deleteId, token)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.message);
              setShowDeletePopup(false);
              fetchRoles(token)
                .then((res) => {
                  if (res.status === 200) {
                    dispatch(setLoaderView(false));
                    setRoles(res.data);
                    setRoleData(null);
                  } else {
                    dispatch(setLoaderView(false));
                    toast.error(res.message);
                  }
                })
                .catch((error) => {
                  dispatch(setLoaderView(false));
                  // console.log(error);
                });
            } else {
              dispatch(setLoaderView(false));
              toast.error(res.message);
            }
          })
          .catch((error) => {
            dispatch(setLoaderView(false));
            // console.log(error);
          });
      } else if (userData) {
        deleteUser(deleteId, token)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.message);
              setShowDeletePopup(false);
              fetchUsers(token)
                .then((res) => {
                  if (res.status === 200) {
                    dispatch(setLoaderView(false));
                    setUsers(res.data);
                    setUserData(null);
                  } else {
                    dispatch(setLoaderView(false));
                    toast.error(res.message);
                  }
                })
                .catch((error) => {
                  dispatch(setLoaderView(false));
                  // console.log(error);
                });
            } else {
              dispatch(setLoaderView(false));
              toast.error(res.message);
            }
          })
          .catch((error) => {
            dispatch(setLoaderView(false));
            // console.log(error);
          });
      } else {
        dispatch(setLoaderView(false));
      }
    } else {
      setShowDeletePopup(false);
    }
  };

  return (
    <>
      <Header title="Settings" />

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 ">
              <ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
                <li className="nav-item">
                  <a
                    href="#clinic-details-tab"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link rounded-0 active"
                  >
                    <i className="mdi mdi-home-variant d-md-none d-block"></i>
                    <span className="d-none d-md-block">Clinic Details</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#users-tab"
                    data-bs-toggle="tab"
                    aria-expanded="true"
                    className="nav-link rounded-0"
                  >
                    <i className="mdi mdi-account-circle d-md-none d-block"></i>
                    <span className="d-none d-md-block">Users</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#roles-tab"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link rounded-0"
                  >
                    <i className="mdi mdi-account d-md-none d-block"></i>
                    <span className="d-none d-md-block">Roles</span>
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-pane show active" id="clinic-details-tab">
                  {clinicDetail && (
                    <form onSubmit={submitClinicDetailsHandler}>
                      <div className="row">
                        <div className="col-md-6 mb-3 text-center align-self-center">
                          {logo ? (
                            <img
                              className="logo-image-upload-view"
                              src={
                                logo
                                  ? URL.createObjectURL(logo.target.files[0])
                                  : "/assets/images/logo.png"
                              }
                              alt="logo"
                            />
                          ) : (
                            <img
                              className="logo-image-upload-view"
                              src={
                                process.env.REACT_APP_BASE_URL +
                                clinicDetail?.logo
                              }
                              alt="logo"
                            />
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            htmlFor="example-fileinput"
                            className="form-label"
                          >
                            Logo <RequiredInput />
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            required={clinicDetail?.logo ? false : true}
                            name="logo"
                            onChange={setLogo}
                            id="example-fileinput"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <Input
                            label="Clinic Name"
                            maxLength={50}
                            minLength={2}
                            name="name"
                            defaultValue={clinicDetail?.name}
                            required={true}
                            type="text"
                            className="form-control"
                            placeholder="Enter clinic name"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <Input
                            label="Email ID"
                            name="email"
                            defaultValue={clinicDetail?.email}
                            required={true}
                            type="email"
                            className="form-control"
                            placeholder="Enter email id"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <Input
                            label="Primary Contact No"
                            defaultValue={clinicDetail?.primary_contact_no}
                            name="primary_contact_no"
                            required={true}
                            type="number"
                            className="form-control"
                            placeholder="Enter primary contact no"
                            types={'phone'}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <Input
                            label="Secondary Contact No"
                            defaultValue={clinicDetail?.secondary_contact_no}
                            name="secondary_contact_no"
                            required={true}
                            type="number"
                            className="form-control"
                            placeholder="Enter secondary contact no"
                            types={'phone'}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <Input
                            label="Address"
                            maxLength={50}
                            minLength={2}
                            defaultValue={clinicDetail?.address}
                            name="address"
                            required={true}
                            type="text"
                            className="form-control"
                            placeholder="Enter clinic address"
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <Input
                            label="Building Number"
                            maxLength={5}
                            minLength={1}
                            defaultValue={clinicDetail?.building_number}
                            name="building_number"
                            required={true}
                            type="number"
                            className="form-control"
                            placeholder="Enter clinic building number"
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <Input
                            label="Street"
                            maxLength={5}
                            minLength={1}
                            defaultValue={clinicDetail?.street}
                            name="street"
                            required={true}
                            type="number"
                            className="form-control"
                            placeholder="Enter clinic street"
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <Input
                            label="Zone"
                            maxLength={5}
                            minLength={1}
                            defaultValue={clinicDetail?.zone}
                            name="zone"
                            required={true}
                            type="number"
                            className="form-control"
                            placeholder="Enter clinic zone"
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <Input
                            label="Unit Number"
                            maxLength={5}
                            minLength={1}
                            defaultValue={clinicDetail?.unit_number}
                            name="unit_number"
                            required={true}
                            type="number"
                            className="form-control"
                            placeholder="Enter clinic unit number"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mb-3">
                          <Input
                            label="Opening Hours"
                            defaultValue={clinicDetail?.opening_hour}
                            name="opening_hour"
                            required={true}
                            type="time"
                            className="form-control"
                            placeholder="Enter clinic opening hours"
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <Input
                            label="Closing Hours"
                            defaultValue={clinicDetail?.closing_hour}
                            name="closing_hour"
                            required={true}
                            type="time"
                            className="form-control"
                            placeholder="Enter clinic closing hours"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Button
                            type="submit"
                            disabled={disable}
                            className="btn btn-primary"
                            label="Save Details"
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </div>

                <div className="tab-pane" id="users-tab">
                  <div className="row">
                    <div className="col-md-12">
                      <Button
                        label="Create New User"
                        className="btn btn-outline-primary  float-right mb-3"
                        onClick={() => {
                          setShowAddUpdateUser(true);
                          setUserData(null);
                        }}
                      />
                    </div>
                  </div>

                  <GridView
                    data={users}
                    columns={usersColumns}
                    totalSize={10}
                    sizePerPage={10}
                    keyField="id"
                  //fetchData={(page, size) => fetchProducts(page, size)}
                  />
                </div>

                <div className="tab-pane" id="roles-tab">
                  <div className="row">
                    <div className="col-md-12">
                      <Button
                        label="Create New Role"
                        className="btn btn-outline-primary  float-right mb-3"
                        onClick={() => {
                          setShowAddUpdateRole(true);
                          setRoleData(null);
                        }}
                      />
                    </div>
                  </div>

                  <GridView
                    data={roles}
                    columns={rolesColumns}
                    totalSize={10}
                    sizePerPage={10}
                    keyField="id"
                  //fetchData={(page, size) => fetchProducts(page, size)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAddUpdateRole ? (
        <AddUpdateRolePopup
          show={showAddUpdateRole}
          data={roleData}
          onHide={() => setShowAddUpdateRole(false)}
          submitForm={submitCreateRoleHandler}
        />
      ) : null}
      {showAddUpdateUser ? (
        <AddUpdateUserPopup
          show={showAddUpdateUser}
          data={userData}
          onHide={() => setShowAddUpdateUser(false)}
          submitForm={submitCreateUserHandler}
          roles={roles}
          checkGender={checkGenderUser}
          setCheckGender={setCheckGenderUser}
          checkRole={checkRoleUser}
          setCheckRole={setCheckRoleUser}
        />
      ) : null}
      {showDeletePopup ? (
        <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} />
      ) : null}
    </>
  );
};

export default Settings;
