import Header from "../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import GridView from "../Common/GridView";
import { deleteVisit, fetchClinicVisit, fetchPatient, updatePatientVisit } from "../../api/patientsApi";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { deletePrescription, fetchPrescriptions } from "../../api/prescriptionApi";
import DeleteConfirmationPopup from "../Common/DeleteConfirmationPopup";
import { toast } from "react-toastify";
import Button from "../Common/Button";
import CreateMedicalHistoryPopUp from "./CreateMedicalHistoryPopUp";

const ClinicVisit = () => {

    const { id } = useParams();
    const token = useSelector((state) => state?.token);
    const dispatch = useDispatch();
    const [patientDetail, setPatientDetail] = useState();
    const [prescriptions, setPrescriptions] = useState([]);
    const [clinicVisitsList, setClinicVisitsList] = useState([]);
    const [clinicVisit, setClinicVisit] = useState([]);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [showClinicPopUp, setShowClinicPopUp] = useState(false);
    const [deleteCategory, setDeleteCategory] = useState('');
    const isDoctor = useSelector((state) => state?.user_detail?.user_data?.is_doctor);


    const action = (prescriptionId) => {
        return (
            <div className="dropdown card-widgets">
                <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                </span>
                <div className="dropdown-menu dropdown-menu-end" >
                    {isDoctor && <Link className="dropdown-item cursor-pointer" to={`/update-prescription/${id}/${prescriptionId}`}><i className="uil-file-edit-alt me-1"></i>Update</Link>}
                    <span className="dropdown-item cursor-pointer" onClick={() => {
                        setDeleteCategory('prescription');
                        setShowDeletePopup(true);
                        setDeleteId(prescriptionId);
                    }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                </div>
            </div>
        )
    }

    const clinicAction = (clinicVisitId) => {
        return (
            <div className="dropdown card-widgets">
                <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                </span>
                <div className="dropdown-menu dropdown-menu-end" >
                    {isDoctor && <Link className="dropdown-item cursor-pointer" to={`/view-visit/${id}/${clinicVisitId}`}><i className="uil-file-edit-alt me-1"></i>View</Link>}
                    <span className="dropdown-item cursor-pointer" onClick={() => {
                        setShowDeletePopup(true);
                        setDeleteId(clinicVisitId);
                        setDeleteCategory('visit');
                    }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
                </div>
            </div>
        )
    }

    const openPrescriptionDetails = (cell, row) => {
        return <Link to={`/preview-prescription/${id}/${cell}`}>Prescription {cell}</Link>
    }

    const openClinicDetails = (cell, row) => {
        return <Link to={("/view-visit/" + id + '/' + cell)}>Visit {cell}</Link>
    }

    const prescriptionsColumns = [
        {
            dataField: 'id',
            text: 'Prescription Name',
            formatter: openPrescriptionDetails
        }, {
            dataField: 'created_on',
            text: 'Created On',
            formatter: (cell) => {
                let date = new Date(cell)
                return date.toDateString()
            }
        }, {
            dataField: 'created_on',
            text: 'Created At',
            formatter: (cell) => {
                let date = new Date(cell)
                return date.toLocaleTimeString()
            }
        }, {
            dataField: 'id',
            text: '',
            formatter: action
        }
    ];

    const clinicsColumns = [
        {
            dataField: 'id',
            text: 'Id',
            formatter: openClinicDetails
        },
        {
            dataField: 'doctor_name',
            text: 'Doctor Name',
            formatter: (cell) => {
                let date = new Date(cell)
                return date.toLocaleTimeString()
            }
        },
        {
            dataField: 'allergy',
            text: 'Allergy',
            formatter: (cell) => {
                let date = new Date(cell)
                return date.toLocaleTimeString()
            }
        },
        {
            dataField: 'created_on',
            text: 'Clinic Last Visit Date',
            formatter: (cell) => {
                let date = new Date(cell)
                return date.toLocaleTimeString()
            }
        },
        {
            dataField: 'insurance_files',
            text: 'Insurance Files',
            formatter: clinicAction
        }
    ];
    const fetchCurrentPatient = () => {
        dispatch(setLoaderView(true));
        fetchPatient(id, token).then(res => {
            if (res.status === 200) {
                setPatientDetail(res.data);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
        fetchPrescriptions(id, token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setPrescriptions(res.data?.reverse());
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
        fetchClinicVisit(id, token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setClinicVisitsList(res.data?.reverse());
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
    }

    const location = useLocation();
    useEffect(() => {
        // fetchCurrentPatient();
    }, [])

    useEffect(() => {
        // fetchCurrentPatient();
    }, [location.pathname]);

    const canDelete = (yesOrNo) => {
        if (yesOrNo) {
            dispatch(setLoaderView(true));
            if (deleteCategory === 'visit') {
                deleteVisit(deleteId, token).then(res => {
                    dispatch(setLoaderView(false));
                    if (res.status === 200) {
                        toast.success(res.message);
                        setShowDeletePopup(false);
                        fetchClinicVisit(id, token).then(res => {
                            if (res.status === 200) {
                                setClinicVisitsList(res.data?.reverse());
                            } else {
                                dispatch(setLoaderView(false));
                                toast.error(res.message);
                            }
                        }).catch(error => {
                            dispatch(setLoaderView(false));
                            // console.log(error);
                        });
                    } else {
                        dispatch(setLoaderView(false));
                        toast.error(res.message);
                    }
                }).catch(error => {
                    dispatch(setLoaderView(false));
                    // console.log(error);
                });
            } else if (deleteCategory === 'presciption') {
                deletePrescription(deleteId, token).then(res => {
                    dispatch(setLoaderView(false));
                    if (res.status === 200) {
                        toast.success(res.message);
                        setShowDeletePopup(false);
                        fetchPrescriptions(id, token).then(res => {
                            if (res.status === 200) {
                                setPrescriptions(res.data?.reverse());
                            } else {
                                dispatch(setLoaderView(false));
                                toast.error(res.message);
                            }
                        }).catch(error => {
                            dispatch(setLoaderView(false));
                            // console.log(error);
                        });
                    } else {
                        dispatch(setLoaderView(false));
                        toast.error(res.message);
                    }
                }).catch(error => {
                    dispatch(setLoaderView(false));
                    // console.log(error);
                });
            }
        } else {
            setShowDeletePopup(false);
        }
    }

    const handleSubmitClinicVisitForm = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.delete('patient_id');
        formData.delete('month');
        formData.delete('day');
        formData.delete('year');
        formData.delete('hour12');
        formData.delete('minute');
        formData.delete('amPm');
        updatePatientVisit(id, formData, token).then(res => {
            if (res.status === 200) {
                toast.success(res.message);
                setShowClinicPopUp(false);
                fetchCurrentPatient();
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            toast.error(error);
            // console.log(error);
        });
    }

    return (
        <>
            <Header title="Clinic Details" back={true}>
                {/* {isDoctor && <Link to={"/create-prescription/" + id} className="btn btn-outline-primary float-right mb-3">Create Prescription</Link>} */}
            </Header>

            <div className="row">
                <div className="col-12 m-auto">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mb-3 mt-2 text-center">Clinic Details</h4>
                            <div className="row">
                                <div className="col-7">
                                    <p className="mb-2"><strong>Clinic ID :</strong> <span className="ml-2">{patientDetail ? patientDetail?.first_name + ' ' + patientDetail?.last_name : null}</span></p>
                                </div>
                                <div className="col-5">
                                    <p className="mb-2"><strong>Clinic Visit Date Time :</strong><span className="ml-2">{patientDetail?.phone_number}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7">
                                    <p className="mb-2"><strong>Admitted At :</strong> <span className="ml-2 ">{patientDetail?.email}</span></p>
                                </div>
                                <div className="col-5">
                                    <p className="mb-2"><strong>Discharged On :</strong> <span className="ml-2">{patientDetail?.gender}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7">
                                    <p className="mb-2"><strong>Doctor Name :</strong> <span className="ml-2">{patientDetail?.dob}</span></p>
                                </div>
                                <div className="col-5">
                                    <p className="mb-2"><strong>Patient Status :</strong> <span className="ml-2">{patientDetail?.national_id_pass_number}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Button
                        type="button"
                        label="Create Medical History"
                        className="btn btn-primary float-right mb-3"
                        onClick={() => setShowClinicPopUp(true)}
                    />

                    {<CreateMedicalHistoryPopUp
                        show={showClinicPopUp}
                        onHide={() => setShowClinicPopUp(false)}
                        submitForm={(event, id) => handleSubmitClinicVisitForm(event, id)}
                        patient={patientDetail}
                    />}
                </div>

                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
                                <li className="nav-item">
                                    <a href="#clinics-tab" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0 active">
                                        <i className="mdi mdi-account d-md-none d-block"></i>
                                        <span className="d-none d-md-block">Medical History Records</span>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a href="#prescriptions-tab" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
                                        <i className="mdi mdi-account d-md-none d-block"></i>
                                        <span className="d-none d-md-block">Prescriptions</span>
                                    </a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a href="#first-tab" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
                                        <i className="mdi mdi-home-variant d-md-none d-block"></i>
                                        <span className="d-none d-md-block">Test</span>
                                    </a>
                                </li> */}
                            </ul>

                            <div className="tab-content">
                                <div className="tab-pane show active" id="clinics-tab">
                                    <GridView
                                        gridData={clinicVisitsList} // TO DO: change it to clinicVisitsList state once api is ready
                                        columns={clinicsColumns}
                                        totalSize={10}
                                        sizePerPage={10}
                                        keyField="id"
                                    />
                                </div>
                                {/* <div className="tab-pane show" id="prescriptions-tab">
                                    <GridView
                                        gridData={prescriptions}
                                        columns={prescriptionsColumns}
                                        totalSize={10}
                                        sizePerPage={10}
                                        keyField="id"
                                    />
                                </div> */}

                                {/* <div className="tab-pane" id="first-tab">
                                    <h1>Test</h1>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showDeletePopup ? <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} /> : null}

        </>
    )
}

export default ClinicVisit;