import RequiredInput from './RequiredInput';

const TextArea = ({ label, type, rows, placeholder, id, readOnly, disabled, name, className, labelClassName, required, value, defaultValue, onChange }) => {
    return (
        <>
            <label className={labelClassName}>{label} {required ? <RequiredInput /> : null}</label>
            <textarea className={className} rows={rows} id={id} readOnly={readOnly} disabled={disabled} name={name} value={value} onChange={onChange} defaultValue={defaultValue} type={type} required={required} placeholder={placeholder} />
        </>
    )
}

export default TextArea;