import { Table } from "react-bootstrap";

const ReactTable = ({ columns, data }) => {
    console.log(data);
    return (
        <div style={{ overflowX: 'auto', WebkitOverflowScrolling: 'touch' }}>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {
                            columns.map((field, i) => <th key={i}>{field.text}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, j) => (
                            <tr key={j}>
                                {
                                    columns.map((field, i) => <td key={i}>{item[field.dataField]} {item[i === 1 ? 'strength_unit' : i === 3 ? 'dosage_unit' : i === 7 ? 'duration_unit' : null]}</td>)
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}
export default ReactTable;