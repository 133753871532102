import { Modal } from "react-bootstrap";
import Input from "../Common/Input";
import Select from "react-select";
import SelectDropDown from "../Common/SelectDropDown";
import { useEffect, useState } from "react";
import { fetchSpecialization } from "../../api/settingsApi";
import { useSelector } from "react-redux";
import { formatValueLabel } from "../../helper/valueLabelFormatter";
import RequiredInput from "../Common/RequiredInput";
import PhoneInput from "react-phone-input-2";
import { dropDownKeys, fields, fileKeys } from "./settingsFields";

const AddUpdateUserPopup = ({
  show,
  data,
  onHide,
  submitForm,
  roles,
}) => {
  const token = useSelector((state) => state?.token);

  const [phone, setPhone] = useState(data?.user_data?.phone_number);
  const [countryCode, setCountryCode] = useState(data?.user_data?.country_code);
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    `${data?.user_data?.country_code}${data?.user_data?.phone_number}`
  );
  const [specialization, setSpecialization] = useState([]);
  const [isDoctor, setIsDoctor] = useState(data?.user_data?.is_doctor || false);
  const [signature, setSignature] = useState(null);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [doctorType, setDoctorType] = useState(data?.user_data?.doctor_type);
  const [errors, setFormErrors] = useState({});
  const [disableBtn, setDisable] = useState(false);
  const [checkGender, setCheckGender] = useState();
  const [checkRole, setCheckRole] = useState(data?.role_id);
  const [imageError, setImageError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState('');
  const doctorTypeOption = [
    {
      label: 'Resident',
      value: 'resident'
    },
    {
      label: 'Visitor',
      value: 'visitor'
    }
  ]

  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  useEffect(() => {
    let roleId = roles?.filter(({ name }) => name.toLowerCase() === 'doctor')[0];
    roleId = roleId?.id || '';
    const dropdown = document.querySelector('select[name="role_id"]');
    if (isDoctor) {
      dropdown.value = roleId;
    } else {
      dropdown.value = "0";
    }
  }, [isDoctor]);

  const onInput = (key, value, required) => {
    const allFields = fields.user;
    if (required && !fileKeys.includes(key) && ((!dropDownKeys.includes(key) && (!value || value.length === 0)) || (dropDownKeys.includes(key) && (value === '0')))) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: `${allFields[key]?.label} is required`
      }));
    } else if (required && !fileKeys.includes(key) && ((!dropDownKeys.includes(key) && (value || value.length > 0)) || (dropDownKeys.includes(key) && value !== '0')) && !allFields[key].pattern) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: ''
      }));
    } else if (!fileKeys.includes(key) && allFields[key].pattern && !allFields[key].pattern.test(value)) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: `${allFields[key]?.label} should only contain ${allFields[key].patternLabel} value`
      }));
    } else if (!fileKeys.includes(key) && allFields[key].pattern && allFields[key].pattern.test(value)) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: ''
      }));
    }
  }

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    setCheckGender(formData.get("gender"));
    setCheckRole(formData.get("role_id"));
    submitForm(event, isDoctor);
  };

  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  useEffect(() => {
    fetchSpecialization(token).then((res) => {
      if (res.status === 200) {
        setSpecialization(formatValueLabel("id", "name", res.data));
      }
    });
  }, []);

  useEffect(() => {
    if (specialization?.length && data?.user_data?.speciality_list?.length) {
      setSelectedSpecialities(
        formatValueLabel(
          "speciality_id",
          "speciality_name",
          data?.user_data?.speciality_list
        )
      );
    }
  }, [specialization]);

  useEffect(() => {
    if (errors && Object.values(errors).some(val => val !== '' && val.length > 0)) {
      setDisable(true);
    } else if (errors && Object.values(errors).every(val => val === '' && val.length === 0)) {
      setDisable(false);
    }
  }, [errors]);

  useEffect(() => {
    setSelectedSpecialities(specialization.filter(x => data?.user_data?.speciality_list.map(({ speciality_id }) => speciality_id).includes(x.id)));
  }, [data?.user_data]);

  return (
    <Modal
      show={show}
      backdrop="static"
      size="lg"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={handleSubmitForm} onInput={(e) => onInput(e.target.name, e.target.value, e.target.required)}>
        <Modal.Header>
          <Modal.Title className="m-0" id="contained-modal-title-vcenter">
            {data ? "Update" : "Add"} User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-5 mb-3">
              <Input
                label="Name"
                defaultValue={data?.user_data?.name}
                name="name"
                required={true}
                type="text"
                className="form-control"
                placeholder="Enter user full name"
              />
              <small className="text-danger">{errors && errors['name']}</small>
            </div>

            <div className="col-md-4 mb-3">
              {/* <Input label="Phone" defaultValue={data?.user_data?.phone_number} name="phone_number" required={true} type="number" className="form-control" placeholder="Enter phone no." /> */}
              <label>
                Phone <RequiredInput />
              </label>
              <PhoneInput
                disabled={false}
                onChange={(phoneNumber, countryCodeObj) => {
                  setPhone(
                    phoneNumber?.substring(countryCodeObj?.dialCode?.length)
                  );
                  setCountryCode(countryCodeObj?.dialCode);
                  setPhoneCountryCode(phoneNumber);
                }}
                country="qa"
                defaultMask={'.... ....'}
                onlyCountries={["qa"]}
                required={true}
                value={phoneCountryCode}
                className="phone-input"
              />
              <small className="text-danger">{errors && errors['phone_number']}</small>
              <small className="text-danger">{errors && errors['country_code']}</small>
              <input type="hidden" name="phone_number" required={true} value={phone} />
              <input type="hidden" name="country_code" required={true} value={countryCode} />
            </div>

            <div className="col-md-3 mb-3">
              <div className="form-check form-switch">
                <label>Are you a doctor?</label>
                <input
                  type="checkbox"
                  checked={isDoctor}
                  onChange={(event) => setIsDoctor(event.target.checked)}
                  id="is_doctor"
                  data-switch="none"
                  name="is_doctor"
                  className="form-control"
                />
                <label
                  htmlFor="is_doctor"
                  className="mt-1"
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
              </div>
            </div>
          </div>

          {isDoctor && (
            <div className="row">
              <div className="col-md-7 mb-3">
                <label>Specialization {isDoctor ? <RequiredInput /> : ''}</label>
                <Select
                  isMulti
                  name="speciality_id"
                  onChange={(value) => {
                    if (value?.length === 0 || !value) {
                      setFormErrors(prevState => ({
                        ...prevState,
                        'speciality_id': `Specialization is required`
                      }));
                    } else {
                      setFormErrors(prevState => ({
                        ...prevState,
                        'speciality_id': ''
                      }));
                    }
                    setSelectedSpecialities(value);
                  }}
                  value={selectedSpecialities}
                  options={specialization}
                  required={true}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <small className="text-danger">{errors && errors['speciality_id']}</small>
              </div>
              <div className="col-md-5 mb-3">
                <SelectDropDown
                  options={doctorTypeOption}
                  label="Doctor Type"
                  defaultValue={doctorType}
                  name="doctor_type"
                  required={true}
                  className="form-control"
                  onChange={(e) => setDoctorType(e.target.value)}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-5 mb-3">
              <Input
                label="Email"
                defaultValue={data?.user_data?.email}
                name="email"
                required={true}
                type="email"
                className="form-control"
                placeholder="Enter email id"
              />
              <small className="text-danger">{errors && errors['email']}</small>
            </div>
            <div className="col-md-4 mb-3">
              <Input
                label="Date of Birth"
                max={new Date().toISOString().slice(0, 10)}
                defaultValue={data?.user_data?.dob}
                name="dob"
                required={true}
                type="date"
                className="form-control"
                placeholder="Enter date of birth"
              />
              <small className="text-danger">{errors && errors['dob']}</small>
            </div>
            <div className="col-md-3 mb-3">
              <SelectDropDown
                options={gender}
                label="Gender"
                defaultValue={data?.user_data?.gender}
                name="gender"
                required={true}
                className="form-control"
                onChange={() => setCheckGender()}
              />
              {checkGender === "0" ? (
                <small className="text-danger">Gender is required</small>
              ) : null}
              <small className="text-danger">{errors && errors['gender']}</small>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mb-3">
              <SelectDropDown
                label="Role"
                required={true}
                defaultValue={checkRole}
                name="role_id"
                options={formatValueLabel("id", "name", roles)}
                className="form-control"
                onChange={() => setCheckRole()}
              />
              {checkRole === "0" ? (
                <small className="text-danger">Role is required</small>
              ) : null}
            </div>
            {isDoctor && <div className="col-md-4 mb-3">
              <label htmlFor="example-fileinput" className="form-label m-0">
                Signature <RequiredInput />
              </label>
              <input
                type="file"
                accept="image/*, application/pdf"
                required={data?.user_data?.signature ? false : true}
                name="signature"
                onChange={(e) => {
                  if (e.target.files[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].some(x => x === e.target.files[0]?.type)) {
                    setSignature(e);
                    setImageError(false);
                  } else {
                    e.target.value = null;
                    setImageError(true);
                  } if (e.target.files[0].size >= MAX_FILE_SIZE) {
                    setFileSizeError('File size must be less than 5 MB');
                    setImageError(true);
                    setDisable(true);
                  } else {
                    setFileSizeError('');
                    setDisable(false);
                    setImageError(false);
                  }
                }}
                id="example-fileinput"
                className="form-control"
              />
              <label className="mt-2" style={{ color: imageError ? 'red' : '' }}>{"Attach Files of Type JPEG, PNG and PDF format (Max File Size : 5mb)"}</label>
              <h5 className="text-danger">{fileSizeError}</h5>
            </div>}
            <div className="col-md-4 mb-3 text-center align-self-center">
              {isDoctor && (signature || data?.user_data?.signature) ? (
                <img
                  className="signature-image-upload-view"
                  src={
                    signature
                      ? URL.createObjectURL(signature.target.files[0])
                      : process.env.REACT_APP_BASE_URL +
                      data?.user_data?.signature
                  }
                  alt="signature"
                />
              ) : // <img className="signature-image-upload-view" src={data?.user_data?.signature ? process.env.REACT_APP_BASE_URL + data?.user_data?.signature : "/assets/images/logo.png"} alt="signature" />
                null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={disableBtn}>
            {data ? "Update" : "Add"} User
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddUpdateUserPopup;
