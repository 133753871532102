import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchAllSideMenuById } from '../../api/sidemenuapi';
import Header from '../Common/Header';
import Input from "../Common/Input";

const DetailedView = () => {
  const token = useSelector((state) => state?.token);
  const [data, setData] = useState([]);

  const location = useLocation();
  const category = location.pathname.split('/')[3];
  const id = location.pathname.split('/')[4];

  useEffect(() => {
    setData(JSON.parse(sessionStorage.getItem('DetailsData')));
    // fetchAllSideMenuById(location.pathname, token).then(res => {
    //   if (res.status === 1 || res.status === 200) {
    //     setData(res.data);
    //   } else {
    //     toast.error(res.message);
    //   }
    // }).catch(error => {
    //   // console.log(error);
    // });
  }, []);

  return (
    <>
      <Header title={category.slice(0, 1).toUpperCase() + category.slice(1) + ' Details'} back={true}>
        {/* {isDoctor && <Link to={"/create-prescription/" + id} className="btn btn-outline-primary float-right mb-3">Create Prescription</Link>} */}
      </Header>
      {/* <h3 className='mb-20'>Edit {category.slice(0, 1).toUpperCase() + category.slice(1)}</h3> */}
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input label={category + ' Name'} maxLength={50} name="name" value={data?.name} required={true} type="text" className="form-control" />
        </div>
        <div className="col-md-6 mb-3">
          <Input label={category + ' Description'} maxLength={50} name="description" value={data?.description} required={true} type="text" className="form-control" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input label={'Created By'} maxLength={50} name="created_by" value={data?.created_by} required={true} type="text" className="form-control" />
        </div>
        <div className="col-md-6 mb-3">
          <Input label={'Modifeid By'} maxLength={50} name="modified_by" value={data?.modified_by} required={true} type="text" className="form-control" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label>Created Date</label>
          <div className='mt-2'>{data?.created_on?.split('T')[0] + ' T' + data?.created_on?.split('T')[1].slice(0, 5)}</div>
        </div>
        <div className="col-md-6 mb-3">
          <label>Modified Date</label>
          <div className='mt-2'>{data?.modified_on?.split('T')[0] + ' T' + data?.modified_on?.split('T')[1].slice(0, 5)}</div>
        </div>
      </div>
      {/* <div className='row text-center'>
        <div className="col-md-4 mb-3"></div>
        <div className="col-md-4 mb-3">
          <button className='btn btn-primary'>Save</button>
        </div>
        <div className="col-md-4 mb-3"></div>
      </div> */}
    </>
  );
}

export default DetailedView;