import { combineReducers } from 'redux';
import { roleReducer, loaderReducer } from './settingReducer';
import { tokenReducer, userReducer } from './userReducer';

const reducers = combineReducers({
    token: tokenReducer,
    user_detail: userReducer,
    role_detail: roleReducer,
    loader: loaderReducer
});

export default reducers;