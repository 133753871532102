import { Modal } from "react-bootstrap";
import Input from "../Common/Input";
import InputWithDropDown from "../Common/InputWithDropDown";
import TextArea from "../Common/TextArea";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDoseUnits, fetchStrengthUnits, fetchDurationUnits, fetchRoute, fetchProducts, fetchDirection, fetchPreperation, fetchFrequency } from "../../api/prescriptionApi";
import { formatValueLabel } from "../../helper/valueLabelFormatter";
import { useParams } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { toast } from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import SelectDropDown from "../Common/SelectDropDown";
import { fetchClinicDetail, fetchUsers } from "../../api/settingsApi";
import axios from "axios";
import timeConverter from "../utils/timeConverter";
import { getTimeFromDate } from "../utils/getTime";

const CreateClinicVisitPopUp = ({ data, show, onHide, submitForm, patient, setSelectedClinicVisit }) => {

    const token = useSelector((state) => state?.token);

    const { patient_id } = useParams();
    const [doctors, setDoctors] = useState([]);
    const [disable, setDisable] = useState(true);
    const [selectedDoctor, setSelectedDoctor] = useState({});
    const dispatch = useDispatch();
    const [dateTime, setDateTime] = useState('');
    const [clinicDetail, setClinicDetail] = useState();

    useEffect(() => {
        setSelectedDoctor(data?.doctor_id);
        setDateTime(data?.visit_datetime && new Date(data?.visit_datetime));
    }, [data])

    useEffect(() => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BASE_URL + "/users/get_users_list?is_doctor=true",
            headers: {
                Authorization: 'Token ' + token
            },
        }).then((response) => {
            setDoctors(response.data.data.map(docRecord => ({ ...docRecord, label: docRecord.user_data.name, value: docRecord.user_id })))
        }).catch((error) => {
            // console.log(error)
        });


        // 
        const localClinicData = localStorage.getItem('ClinicDetail');
        if (localClinicData) {
            setClinicDetail(JSON.parse(localClinicData));
        }
        else {
            fetchClinicDetail(token).then(res => {
                dispatch(setLoaderView(false));
                if (res.status === 200) {
                    localStorage.setItem('ClinicDetail', JSON.stringify(res.data));
                    setClinicDetail(res.data);
                } else {
                    dispatch(setLoaderView(false));
                    toast.error(res.message);
                }
            }).catch(error => {
                dispatch(setLoaderView(false));
                // console.log(error);
            });
        }
    }, []);

    useEffect(() => {
        if (dateTime && selectedDoctor && selectedDoctor !== '0') {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [dateTime, selectedDoctor])

    return (
        <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <form onSubmit={(event) => { submitForm(event, patient_id, selectedDoctor, data?.id); onHide(); setDateTime(''); setSelectedDoctor({}); }}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Create Clinic Visit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input type="hidden" labelClassName="d-none" value={patient_id} name="patient_id" />
                    <div className="row">
                        <div className="col-4 mb-3">
                            <Input inputStyle={{ width: '100%' }} label={'Patient Name'} defaultValue={data?.id ? (data?.patient_first_name + ' ' + data?.patient_last_name) : (patient?.first_name + ' ' + patient?.last_name)} name="patient_name" disabled={true} />
                            {/* <CreatableSelect
                                placeholder="Search Drugs"
                                name="itemName"
                                options={products}
                                className="basic-multi-select mt-2"
                                classNamePrefix="select"
                            /> */}
                        </div>
                        <div className="col-8 mb-3">
                            <label>Clinic Visit Date Time</label>
                            <DateTimePicker
                                className={'create-visit-date-time-picker w-100'}
                                onChange={(value) => {
                                    setDateTime(value)
                                    if (!((clinicDetail?.opening_hour < getTimeFromDate(value)) && (getTimeFromDate(value) < clinicDetail?.closing_hour))) {
                                        console.log(getTimeFromDate(value))
                                        console.log(clinicDetail?.opening_hour)
                                        console.log(clinicDetail?.closing_hour)
                                        toast.warning(`Please Select a time in between ${timeConverter(clinicDetail?.opening_hour)} to ${timeConverter(clinicDetail?.closing_hour)}`);
                                    }
                                }}
                                value={dateTime || (data?.visit_datetime && new Date(data?.visit_datetime))}
                                minDate={new Date()}
                                disableClock={true}
                                name={'visit_datetime'}
                            />
                            <small>
                                Please Select a time in between {timeConverter(clinicDetail?.opening_hour)} to {timeConverter(clinicDetail?.closing_hour)}<br />
                                As the clinic is open in this time.
                            </small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <TextArea label={'Notes'} defaultValue={data?.notes} maxLength={50} name="notes" type="textarea" className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <SelectDropDown
                                options={doctors}
                                label="Select Doctor"
                                name="status"
                                defaultValue={data?.doctor_id}
                                required={true}
                                className="form-control basic-multi-select"
                                classNamePrefix="select"
                                onChange={(doc) => {
                                    setSelectedDoctor(doc.target.value);
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={() => { onHide(); setDateTime(''); setSelectedDoctor({}); setSelectedClinicVisit({}); }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" disabled={disable} className="btn btn-primary">Submit</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CreateClinicVisitPopUp;