const timeConverter = (time) => {
  const time12 = new Date("2000-01-01T" + time).toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return time12;
};

export default timeConverter;
