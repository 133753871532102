import { useNavigate } from 'react-router-dom';
import Button from "../Common/Button";
import { getAuth, signInWithPopup, GoogleAuthProvider, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useState } from "react";
import { toast } from 'react-toastify';
import { auth } from "./FirebaseConfig";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { getOTP, velidateOTP } from '../../api/authenticationApi';
import { setToken, setUser } from '../../redux/actions/userActions';
import { fetchUserDetails } from '../../api/settingsApi';
import { setLoaderView } from '../../redux/actions/settingActions';
import { GetDeviceId } from './GetDeviceId';
import axios from 'axios';
import { storeUserToken } from './UserToken';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
    const [otpActionBtnTxt, setOtpActionBtnTxt] = useState("Verify OTP");

    const [phone, setPhone] = useState();
    const [countryCode, setCountryCode] = useState();
    const [phoneCountryCode, setPhoneCountryCode] = useState();
    const [otp, setOtp] = useState();
    const [otpSent, setOtpSent] = useState(false);
    const [confirmationObj, setConfirmationObj] = useState(null);

    const sendOTP = () => {
        if (phone?.length !== 8) {
            toast.error("Please enter valid phone no!")
        } else {
            const recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {},
                auth
            );
            recaptchaVerifier.render();
            signInWithPhoneNumber(auth, '+' + phoneCountryCode, recaptchaVerifier)
                .then((confirmationResult) => {
                    setConfirmationObj(confirmationResult);
                    setOtpSent(true);
                })
                .catch((error) => {
                    if (error.message.toString().match('Hostname match not found')) {
                        toast.error("Phone is not registered");
                    }
                    else {
                        toast.error(error.message);
                    }
                });
            // getOTP(phone, countryCode).then(res => {
            //     if (res.status === 200) {
            //         toast.success(res.message);
            //         setOtpSent(true);
            //     } else {
            //         dispatch(setLoaderView(false));
            //         toast.error(res.message);
            //     }
            // });
        }
    }

    const verifyOTP = () => {
        setOtpActionBtnTxt("Verifying...");
        confirmationObj
            .confirm(otp)
            .then(async (response) => {
                submitOtpToServer();
            })
            .catch((err) => {
                setOtpActionBtnTxt("Verify OTP");
                toast.error("Invalid OTP");
            });
    }

    const submitOtpToServer = () => {
        velidateOTP(phone, otp).then(res => {
            if (res.status === 200) {
                toast.success(res.message);
                setOtpSent(true)
                dispatch(setToken(res.token))
                fetchUserDetails(res.token).then(res => {
                    if (res.status === 200) {
                        dispatch(setUser(res.data))
                        navigate("/");
                    } else {
                        dispatch(setLoaderView(false));
                        toast.error(res.message);
                    }
                });
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        });
    }

    const googleSignIn = () => {
        //Google Sign in
        const auth = getAuth();

        signInWithPopup(auth, provider)
            .then(async (result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                const unique_id = await GetDeviceId();
                const prescription_uuid = sessionStorage.getItem("prescription_uuid");
                let data = {};
                if (prescription_uuid) {
                    data = {
                        prescription_uuid,
                        email: user.email,
                        unique_id,
                    };
                } else {
                    data = {
                        email: user.email,
                        unique_id,
                    };
                }

                // axios request
                try {
                    const response = await axios.post(process.env.REACT_APP_BASE_URL + "/user/verify", data);

                    if (response.data.status === 200) {
                        setOtpActionBtnTxt("Verified");
                        // store user token
                        storeUserToken(response.data.token);
                        // check login
                        if (!prescription_uuid) {
                            if (response.data?.type === "login") {
                                if (localStorage.getItem("redirect_url")) {
                                    window.location = localStorage.getItem("redirect_url");
                                    localStorage.removeItem("redirect_url");
                                } else {
                                    window.location = "/";
                                }
                                // remove logindtls from local storage after login success
                                localStorage.removeItem("logindtls");
                            } else {
                                const logindtls = {
                                    email: user.email,
                                    name: user.displayName,
                                    type: "email"
                                }
                                sessionStorage.setItem("logindtls", JSON.stringify(logindtls))
                                navigate("/auth/sign-up");
                            }
                        } else {
                        }
                    } else {
                        setOtpActionBtnTxt("Verify");
                        toast.warning(response.data.message);
                    }
                } catch (error) {
                    setOtpActionBtnTxt("Verify");
                    try {
                        toast.error(error.response.data.message);
                    } catch (e) {
                        toast.error(error.toString());
                    }
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
                toast.error(errorMessage);
            });

        //Google sign in ends
    };

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-10 col-lg-10">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="text-center d-block mb-4 my-md-4 py-md-4">
                                            <img src="/assets/images/auth.svg" className="img-fluid" alt="Product-img" />
                                            <h5 className="font-18 mt-4 color-dark">Level up your clinic services</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-4 py-md-4 px-md-4">
                                        <div className="m-auto text-center">
                                            <h3 className="text-dark-50 pb-0 fw-bold mb-4">Login</h3>
                                            <p style={{
                                                color: '#808285',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                marginBottom: '10px',
                                                display: 'inline-block'
                                            }}>Continue with mobile no</p>
                                        </div>
                                        <div>
                                            <div className="mb-3">
                                                <PhoneInput onChange={(phoneNumber, countryCodeObj) => {
                                                    setPhone(phoneNumber.substring(countryCodeObj.dialCode.length));
                                                    setCountryCode(countryCodeObj.dialCode);
                                                    setPhoneCountryCode(phoneNumber);
                                                }} country="qa" onlyCountries={['qa']} value={phoneCountryCode} defaultMask={'.... ....'} />
                                            </div>
                                            {otpSent ? null : <div id="recaptcha-container" className="text-center mb-3 mb-0" />}
                                            {otpSent ?
                                                <>
                                                    <div className="mb-3 px-4 otp-container">
                                                        <OtpInput
                                                            value={otp}
                                                            shouldAutoFocus={true}
                                                            onChange={setOtp}
                                                            isInputNum={true}
                                                            inputStyle="form-control"
                                                            numInputs={6} />
                                                    </div>
                                                    <div className="mb-3 mb-0 text-center">
                                                        <Button className="btn btn-primary w-100" onClick={verifyOTP} label={otpActionBtnTxt} />
                                                    </div>
                                                </>
                                                :
                                                <div className="mb-3 mb-0 text-center">
                                                    <Button className="btn btn-primary w-100" onClick={sendOTP} label="Send OTP" />
                                                </div>
                                            }
                                        </div>
                                        {/* <div className="text-grey text-center">-- OR --</div>
                                        <div className="login-buttons mt-3  d-flex justify-content-center">
                                            <button className="btn border-dark p-1" onClick={googleSignIn}>
                                                <img
                                                    src="/assets/images/google.png"
                                                    alt="google icon"
                                                    width={40}
                                                    height={40}
                                                />
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;