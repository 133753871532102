import RequiredInput from "./RequiredInput";

const ToggleSwitch = ({ label, readOnly, disabled, name, defaultChecked, id, required, onChange }) => {
    return (
        <div className="form-check form-switch">
            <input type="checkbox" className="form-check-input cursor-pointer" defaultChecked={defaultChecked} onChange={onChange} disabled={disabled} readOnly={readOnly} name={name} id={id} />
            <label className="form-check-label cursor-pointer" htmlFor={id}>{label}{required ? <RequiredInput /> : null}</label>
        </div>
    )
}

export default ToggleSwitch;