import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <div className="mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="text-center">
                            <div className="row mt-5">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <h1 className="text-uppercase">404</h1>
                                        <h5 className="text-uppercase">Oops! - Page not found</h5>
                                        <p className="text-muted">We are sorry, but the page requested was not found!</p>
                                        <Link className="btn btn-outline-primary" to="/">Back to Home</Link>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;