import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchAllSideMenu } from '../../api/sidemenuapi';
import GridView from '../Common/GridView';
import { fetchAllPrescription } from '../../api/prescriptionApi';

const TotalPrescriptions = () => {

    const token = useSelector((state) => state?.token);
    const [data, setData] = useState([]);
    const user_detail = useSelector((state) => state?.user_detail);

    const location = useLocation();
    useEffect(() => {

        fetchAllPrescription(user_detail?.clinic_id, token).then(res => {
            if (res.status === 1 || res.status === 200) {
                // setData(res.data);
            } else {
                toast.error(res.message);
            }
        }).catch(error => {
            // console.log(error);
        });
    }, [location.pathname]);

    const navigate = useNavigate();
    let name = location.pathname.replace('/', '');
    name = name.slice(0, 1).toUpperCase() + name.slice(1);

    const openName = (cell, row) => {
        return <Link to={("/detailed-view/view/" + name + '/' + row.id)}>{row.name}</Link>
    }

    const openDate = (cell, row) => {
        return <>{cell.split('T')[0] + ' T' + cell.split('T')[1].slice(0, 5)}</>
    }

    const action = (id) => {
        return (
            <div className="dropdown card-widgets">
                <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical"></i>
                </span>
                <div className="dropdown-menu dropdown-menu-end" >
                    <span className="dropdown-item cursor-pointer" onClick={() => navigate("/detailed-view/edit/" + name + '/' + id)}><i className="uil-file-edit-alt me-1"></i>Edit</span>
                    {/* <span className="dropdown-item cursor-pointer" onClick={() => {
            // setShowDeletePopup(true);
            // setDeleteId(id);
          }}><i className="uil uil-trash-alt me-1"></i>Delete</span> */}
                </div>
            </div>
        )
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
        },
        {
            dataField: 'name',
            text: 'Name',
            formatter: openName
        },
        {
            dataField: 'description',
            text: 'Description',
        },
        {
            dataField: 'created_by',
            text: 'Created By',
        },
        {
            dataField: 'created_on',
            text: 'Created On',
            formatter: openDate
        },
        {
            dataField: 'modified_on',
            text: 'Modified On',
            formatter: openDate
        },
        // {
        //     dataField: 'id',
        //     text: '',
        //     formatter: action
        // }
    ];

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <GridView
                                gridData={data}
                                columns={columns}
                                totalSize={10}
                                sizePerPage={10}
                                keyField="id" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TotalPrescriptions;