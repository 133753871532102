/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SideMenuConfig } from "../../configuration/SideMenuConfig";

const SideMenu = () => {

    let permissions_list = useSelector((state) => state?.user_detail?.permissions_list);

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        setMenuItems(SideMenuConfig.filter((obj) => {
            return permissions_list[obj.id] == true
        }));
    }, [SideMenuConfig, permissions_list])


    permissions_list = {
        ...permissions_list,
        complaints: true,
        diagnosis: true,
        medication: true,
        tests: true,
    };

    return (
        <div className="leftside-menu">
            <Link to="/" className="logo text-center logo-light">
                <span className="logo-lg">
                    <img src="/assets/images/logo.png" alt="" height="28" />
                </span>
                <span className="logo-sm">
                    <img src="/assets/images/logo.png" className="top-left-logo" alt="" height="28" />
                </span>
            </Link>
            <Link to="/" className="logo text-center logo-dark">
                <span className="logo-lg">
                    <img src="/assets/images/logo.png" alt="" height="28" />
                </span>
                <span className="logo-sm">
                    <img src="/assets/images/logo.png" className="top-left-logo" alt="" height="28" />
                </span>
            </Link>

            <div className="h-100" id="leftside-menu-container" data-simplebar>
                <ul className="side-nav">
                    {menuItems.map((category, categoryIndex) => {
                        return <li className="side-nav-item" key={categoryIndex}>
                            {category.subCategory ?
                                <>
                                    <a data-bs-toggle="collapse" href={`#${category.path}`} aria-expanded="false" aria-controls="sidebarEcommerce" className="side-nav-link">
                                        <i className={category.icon}></i>
                                        <span> {category.label} </span>
                                        {category.subCategory && <span className="menu-arrow"></span>}
                                    </a>
                                    <div className="collapse" id={category.path}>
                                        <ul className="side-nav-second-level">
                                            {category.subCategory.map((subCategory, subCategoryIndex) => {
                                                return <li key={subCategoryIndex}>
                                                    <Link to={subCategory.path} onClick={() => window.innerWidth < 800 && window.location.reload()}>{subCategory.label}</Link>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                </>
                                :
                                <Link to={category.path} href={`#${category.path}`} className="side-nav-link" onClick={() => window.innerWidth < 800 && window.location.reload()}>
                                    <i className={category.icon}></i>
                                    <span> {category.label} </span>
                                </Link>
                            }
                        </li>
                    })}
                </ul>
                <div className="clearfix"></div>
            </div>
        </div >
    )
}

export default SideMenu;