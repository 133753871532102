import Input from "../Common/Input";
import Header from "../Common/Header";
import IconHeading from "../Common/IconHeading";
import Select from 'react-select';
import { useState, useEffect } from "react";
import TextArea from "../Common/TextArea";
import { useDispatch, useSelector } from "react-redux";
import SelectDropDown from "../Common/SelectDropDown";
import { fetchComplaints, fetchDiagnosis, fetchDrugsByPrescription, fetchPrescription, fetchTests } from "../../api/prescriptionApi";
import GridView from "../Common/GridView";
import { useParams } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { fetchPatient } from "../../api/patientsApi";
import { formatValueLabel } from "../../helper/valueLabelFormatter";
import { toast } from "react-toastify";

const ViewPrescription = () => {

    const { patient_id, prescription_id } = useParams();

    const token = useSelector((state) => state?.token);
    const dispatch = useDispatch();

    const [patientDetail, setPatientDetail] = useState();
    const [prescriptionDetail, setPrescriptionDetail] = useState();
    const [complaints, setComplaints] = useState([]);
    const [diagnosis, setDiagnosis] = useState([]);
    const [tests, setTests] = useState([]);
    const [drugs, setDrugs] = useState([]);

    const drugsColumns = [
        {
            dataField: 'itemName',
            text: 'Drug Name'
        }, {
            dataField: 'strength',
            text: 'Strength',
            formatter: (cell, row) => `${cell} / ${row.strength_unit}`
        }, {
            dataField: 'preparation_name',
            text: 'Preparation'
        }, {
            dataField: 'dosage',
            text: 'Dosage',
            formatter: (cell, row) => `${cell} / ${row.dosage_unit}`
        }, {
            dataField: 'route_name',
            text: 'Route'
        }, {
            dataField: 'direction_name',
            text: 'Direction'
        }, {
            dataField: 'frequency_name',
            text: 'Frequency'
        }, {
            dataField: 'duration',
            text: 'Duration',
            formatter: (cell, row) => `${cell} / ${row.duration_unit}`
        }
    ];

    useEffect(() => {
        dispatch(setLoaderView(true));
        fetchPrescription(prescription_id, token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setPrescriptionDetail(res.data);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
        fetchDrugsByPrescription(prescription_id, token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setDrugs(res.data);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
        fetchPatient(patient_id, token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setPatientDetail(res.data);
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
        fetchComplaints(token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setComplaints(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
        fetchDiagnosis(token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setDiagnosis(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
        fetchTests(token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setTests(formatValueLabel('id', 'name', res.data));
            } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
            }
        }).catch(error => {
            dispatch(setLoaderView(false));
            // console.log(error)
        })
    }, [])

    const gender = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
    ]

    const getSelectedItems = (data, selectedIds) => {
        return data?.filter(obj => {
            return selectedIds?.some(id => id === obj?.value)
        });
    }

    return (
        <>
            <Header title="View Prescription" back={true} />

            {patientDetail && <div className="card">
                <div className="card-body">
                    <IconHeading title="Patient Details" icon="mdi-account" />
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <Input label="Patient Name" defaultValue={patientDetail?.first_name + ' ' + patientDetail?.last_name} name="patient_name" readOnly={true} required={false} type="text" className="form-control" placeholder="Enter product name" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <SelectDropDown label="Gender" defaultValue={patientDetail?.gender} name="gender" disabled={true} required={false} className="form-select" options={gender} />
                        </div>
                        <div className="col-md-4">
                            <Input label="Age" defaultValue={patientDetail?.age} name="age" readOnly={true} required={false} type="number" className="form-control" placeholder="Enter product name" />
                        </div>
                    </div>
                </div>
            </div>}

            <div className="card">
                <div className="card-body">
                    <IconHeading title="Complaints" icon="mdi-account" />
                    <div className="row">
                        <div className="col-md-12">
                            <Select isMulti name="complaint" isDisabled={true} options={complaints} value={getSelectedItems(complaints, prescriptionDetail?.complaint)} className="basic-multi-select mt-2" classNamePrefix="select" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TextArea name="complaint_comment" value={prescriptionDetail?.complaint_comment} className="form-control mt-n1" rows={5} placeholder="Add comments" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <IconHeading title="Diagnosis" icon="mdi-account" />
                    <div className="row">
                        <div className="col-md-12">
                            <Select isMulti name="diagnosis" isDisabled={true} options={diagnosis} value={getSelectedItems(diagnosis, prescriptionDetail?.diagnosis)} className="basic-multi-select mt-2" classNamePrefix="select" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TextArea name="diagnosis_comment" value={prescriptionDetail?.diagnosis_comment} className="form-control mt-n1" rows={5} placeholder="Add comments" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <IconHeading title="Medication" icon="mdi-account" />
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-2">
                            <GridView
                                data={drugs}
                                columns={drugsColumns}
                                totalSize={10}
                                sizePerPage={10}
                                keyField="id" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <IconHeading title="Tests" icon="mdi-account" />
                    <div className="row">
                        <div className="col-md-12">
                            <Select isMulti name="tests_id" isDisabled={true} options={tests} value={getSelectedItems(tests, prescriptionDetail?.tests_id)} className="basic-multi-select mt-2" classNamePrefix="select" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <IconHeading title="Doctor's Comments" icon="mdi-account" />
                    <div className="row">
                        <div className="col-md-12">
                            <TextArea name="doctor_comment" value={prescriptionDetail?.doctor_comment} className="form-control mt-n1" rows={5} placeholder="Add comments" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewPrescription;