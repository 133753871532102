import { Modal } from "react-bootstrap";
import Input from "../Common/Input";
import InputWithDropDown from "../Common/InputWithDropDown";
import TextArea from "../Common/TextArea";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDoseUnits, fetchStrengthUnits, fetchDurationUnits, fetchRoute, fetchProducts, fetchDirection, fetchPreperation, fetchFrequency, fetchDrugAvailableUnit } from "../../api/prescriptionApi";
import { formatValueLabel } from "../../helper/valueLabelFormatter";
import { useParams } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { toast } from "react-toastify";
import { valueLabelProductFormatter } from "../../helper/valueLabelProductFormatter";
import { dropDownKeys, fields } from "./fields";

const AddDrugPopup = ({ show, onHide, submitForm, drug, setSelectedDrug }) => {

  const token = useSelector((state) => state?.token);
  const { patient_id, clinic_id } = useParams();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [stock, setStock] = useState('');
  const [strengthUnits, setStrengthUnits] = useState([]);
  const [preperation, setPreperation] = useState([]);
  const [direction, setDirection] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [doseUnits, setDoseUnits] = useState([]);
  const [durationUnits, setDurationUnits] = useState([]);
  const [route, setRoute] = useState([]);
  const [newDrug, setNewDrug] = useState(false);

  const [disableDoseDropdown, setDisableDoseDropdown] = useState(false);
  const [doseOptions, setDoseOptions] = useState([]);
  const [doseValue, setDoseValue] = useState(null);
  const [frequencyError, setFrequencyError] = useState({ m: '', a: '', n: '' });

  const errorFrequency = (label) => `Frequency for ${label} should have either one digit or 1/2`;


  const [errors, setFormErrors] = useState({});
  const [disableBtn, setDisable] = useState(false);

  useEffect(() => {
    if (drug?.isEditable) {
      setFrequency({ m: drug?.frequency?.split('-')[0], a: drug?.frequency?.split('-')[1], n: drug?.frequency?.split('-')[2] });
    } else {
      setFrequency({ m: '', a: '', n: '' });
    }
  }, [drug]);

  useEffect(() => {
    fetchProducts(token)
      .then((res) => {
        if (res.status === 200) {
          setProducts(
            valueLabelProductFormatter(
              "itemName",
              "itemName",
              "genericName",
              res.data
            )
          );
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });

    fetchStrengthUnits(token)
      .then((res) => {
        if (res.status === 200) {
          setStrengthUnits(formatValueLabel("id", "name", res.data));
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });

    fetchPreperation(token)
      .then((res) => {
        if (res.status === 200) {
          setPreperation(formatValueLabel("id", "name", res.data));
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });

    fetchDirection(token)
      .then((res) => {
        if (res.status === 200) {
          setDirection(formatValueLabel("id", "name", res.data));
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });

    // fetchFrequency(token)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setFrequency(formatValueLabel("id", "name", res.data));
    //     } else {
    //       dispatch(setLoaderView(false));
    //       toast.error(res.message);
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch(setLoaderView(false));
    //     // console.log(error);
    //   });

    fetchDurationUnits(token)
      .then((res) => {
        if (res.status === 200) {
          setDurationUnits(formatValueLabel("id", "name", res.data));
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });

    fetchDoseUnits(token)
      .then((res) => {
        if (res.status === 200) {
          setDoseUnits(formatValueLabel("id", "name", res.data));
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });

    fetchRoute(token)
      .then((res) => {
        if (res.status === 200) {
          setRoute(formatValueLabel("id", "name", res.data));
        } else {
          dispatch(setLoaderView(false));
          toast.error(res.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderView(false));
        // console.log(error);
      });
  }, []);

  const fetchStockUnits = (id) => {
    fetchDrugAvailableUnit(id, token).then(res => {
      if (res.status === 200) {
        setStock(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });
  }

  useEffect(() => {
    if (errors && Object.values(errors).some(val => val !== '' && val.length > 0)) {
      setDisable(true);
    } else if (errors && Object.values(errors).every(val => val === '' && val.length === 0)) {
      setDisable(false);
    }
  }, [errors]);

  // useEffect(() => {
  //   if (drug?.frequency) {
  //     const [m, a, n] = drug?.frequency?.split('-');
  //     setFrequency({ m, a, n });
  //   }
  // }, [drug]);

  const onInput = (key, value, required) => {
    const allFields = fields;
    if (required && ((!dropDownKeys.includes(key) && (!value || value.length === 0)) || (dropDownKeys.includes(key) && value === '0'))) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: `${allFields[key]?.label} is required`
      }));
    } else if (required && ((!dropDownKeys.includes(key) && (value || value.length > 0)) || (dropDownKeys.includes(key) && value !== '0')) && !allFields[key].pattern) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: ''
      }));
    } else if (allFields[key]?.pattern && !allFields[key]?.pattern.test(value)) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: `${allFields[key]?.label} should contain ${allFields[key].patternLabel}`
      }));
    } else if (allFields[key].pattern && allFields[key].pattern.test(value)) {
      setFormErrors(prevState => ({
        ...prevState,
        [key]: ''
      }));
    } if (doseValue === 0) {
      setFormErrors(prevState => ({
        ...prevState,
        'dosage': '',
        'dosage_unit': ''
      }));
    }
  }

  useEffect(() => {
    if (drug?.preparation_name?.toLowerCase().includes('ointment')) {
      setDoseValue(0);
      setDoseOptions(nilDose && nilDose[0].value);
      setDisableDoseDropdown(true);
    }
  }, [drug]);

  const handleFrequencyChange = (key, label, e) => {
    e.stopPropagation();
    const inputValue = e.target.value;
    if (
      inputValue === '' ||
      inputValue === '1/2' ||
      inputValue === '1/' ||
      inputValue === '/2' ||
      inputValue === '/' ||
      (inputValue.length === 1 && /^[0-9]$/.test(inputValue[0]))
    ) {
      setFrequency({ ...frequency, [key]: inputValue });
      if (inputValue === '1/' ||
        inputValue === '/2' ||
        inputValue === '/') {
        setFrequencyError(prevState => ({ ...prevState, [key]: errorFrequency(label) }));
      } else {
        setFrequencyError(prevState => ({ ...prevState, [key]: '' }));
      }
    } else {
      if (inputValue.length === 2 && /^[0-9]$/.test(inputValue[0]) && inputValue[1] === '/') {
        setFrequencyError(prevState => ({ ...prevState, [key]: '' }));
      } else {
        setFrequencyError(prevState => ({ ...prevState, [key]: errorFrequency(label) }));
      }
    }
  }

  const nilDose = doseUnits.filter(x => x.label.toLowerCase() === 'nil');

  useEffect(() => {
    if (Object.values(frequencyError).some(value => value !== '')) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [frequencyError]);

  return (
    <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
      <form onSubmit={(e) => submitForm(e, frequency, setFrequency, drug, newDrug, doseValue)} onInput={(e) => {
        onInput(e.target.name, e.target.value, e.target.required);
      }}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{drug?.isEditable ? 'Edit Drug' : 'Add Drug'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input type="hidden" labelClassName="d-none" value={patient_id} name="patient_id" />
          <input type="hidden" name="drug_id" value={drug?.id} />
          <input type="hidden" name="clinic_id" value={clinic_id} />
          <div className="row">
            <div className="col-md-12 mb-3">
            <label>Medicine<span style={{ color: 'red' }}>{' ' + '*'}</span></label>
              <CreatableSelect
                placeholder="Search Drugs"
                name="itemName"
                options={products}
                className="basic-multi-select mt-2"
                classNamePrefix="select"
                defaultInputValue={drug?.itemName}
                onChange={(product) => {
                  if (product.__isNew__) {
                    setStock('New Product');
                    setNewDrug(true);
                    setSelectedDrug({ ...product });
                  } else {
                    setSelectedDrug({ ...product });
                    setStock('');
                    // fetchStockUnits(product.id);
                  }
                }}
              />
              <small className="text-danger">{errors && errors['itemName']}</small>
            </div>
          </div>
          {/* {stock && <div className="row mb-2">
            <div className="col-12">
              <label>{stock === 'New Product' ? stock : `Stock: ${stock} more items available`}</label>
            </div>
          </div>} */}
          <div className="row">
            <div className="col-md-12 mb-3">
              <InputWithDropDown step="0.1" dropDownDefaultValue={[drug?.strength_unit_id]} inputDefaultValue={drug?.strength} label="Strength" inputName="strength" required={true} dropDownName="strength_unit" inputType="number" placeholder="Enter here" inputClassName="form-control" dropDownClassName="btn border" options={strengthUnits} />
              <small className="text-danger">{errors && errors['strength']}</small><br />
              <small className="text-danger">{errors && errors['strength_unit']}</small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label>Preperation<span style={{ color: 'red' }}>{' ' + '*'}</span></label>
              <Select name="prepration" defaultInputValue={drug?.preparation_name} options={preperation} className="basic-multi-select" classNamePrefix="select"
                onChange={(value) => {
                  if (value && value.label.toLowerCase().includes('ointment')) {
                    setDoseValue(0);
                    setDoseOptions(nilDose && nilDose[0].value);
                    setDisableDoseDropdown(true);
                  } else {
                    setDoseValue(null);
                    setDoseOptions([]);
                    setDisableDoseDropdown(false);
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label>Route<span style={{ color: 'red' }}>{' ' + '*'}</span></label>
              <Select name="route" defaultInputValue={drug?.route_name} options={route} className="basic-multi-select" classNamePrefix="select" />
            </div>
          </div>
          {doseValue !== 0 && < div className="row">
            <div className="col-md-12 mb-3">
              <InputWithDropDown label="Dose" dropDownDefaultValue={[drug?.dosage_unit_id]} inputDefaultValue={(drug?.isEditable && drug?.dosage) || doseValue} inputName="dosage" required={true} dropDownName="dosage_unit" inputType="number" placeholder="Enter here" inputClassName="form-control" dropDownClassName="btn border" options={doseUnits} />
              <small className="text-danger">{errors && errors['dosage']}</small><br />
              <small className="text-danger">{errors && errors['dosage_unit']}</small>
            </div>
          </div>}
          <div className="row">
            <div className="col-md-12 mb-3">
              <label>Direction<span style={{ color: 'red' }}>{' ' + '*'}</span></label>
              <Select defaultInputValue={drug?.direction_name} name="direction" options={direction} className="basic-multi-select" classNamePrefix="select" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label>Frequency</label>
              {/* <Select name="frequency" defaultInputValue={drug?.frequency_name} options={frequency} className="basic-multi-select" classNamePrefix="select" /> */}
              <div className="d-flex mt-2">
                <div className="d-flex flex-column" style={{ width: '32%' }}>
                  <input
                    className="form-control"
                    name="morning"
                    value={frequency.m}
                    defaultValue={drug?.frequency?.split('-')[0]}
                    onChange={(e) => handleFrequencyChange('m', 'morning', e)}
                  />

                  <label className="text-center">Morning</label>
                  <label className="text-left text-danger">{frequencyError.m}</label>
                </div>
                <div className="mt-1 mx-1">-</div>
                <div className="d-flex flex-column" style={{ width: '32%' }}>
                  <input className="form-control"
                    value={frequency.a}
                    name="afternoon"
                    defaultValue={(drug?.frequency?.split('-')[1])}
                    onChange={(e) => handleFrequencyChange('a', 'afternoon', e)}
                  />
                  <label className="text-center">Afternoon</label>
                  <label className="text-left text-danger">{frequencyError.a}</label>
                </div>
                <div className="mt-1 mx-1">-</div>
                <div className="d-flex flex-column" style={{ width: '32%' }}>
                  <input
                    className="form-control"
                    name="night"
                    defaultValue={(drug?.frequency?.split('-')[2])}
                    value={frequency.n}
                    onChange={(e) => handleFrequencyChange('n', 'night', e)}
                  />
                  <label className="text-center">Night</label>
                  <label className="text-left text-danger">{frequencyError.n}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <InputWithDropDown label="Duration" dropDownDefaultValue={[String(drug?.duration_unit_id)]} inputDefaultValue={drug?.duration} required={true} inputName="duration" dropDownName="duration_unit" inputType="number" placeholder="Enter here" inputClassName="form-control" dropDownClassName="btn border" options={durationUnits} />
              <small className="text-danger">{errors && errors['duration']}</small><br />
              <small className="text-danger">{errors && errors['duration_unit']}</small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextArea label="Other Instructions" defaultValue={drug?.instructions} name="instructions" className="form-control" rows={5} placeholder="Add instructions here" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => { onHide(); setSelectedDrug({}) }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="submit" className="btn btn-primary" disabled={disableBtn} >{drug?.isEditable ? 'Edit Drug' : 'Add Drug'}</button>
        </Modal.Footer>
      </form>
    </Modal >
  )
}

export default AddDrugPopup;
