import { ActionTypes } from "../constants/actionTypes"

export const setToken = (user) => {
    return {
        type: ActionTypes.SET_TOKEN,
        payload: user
    }
}

export const setUser = (user) => {
    return {
        type: ActionTypes.SET_USER,
        payload: user
    }
}

export const removeUser = (user) => {
    return {
        type: ActionTypes.LOGOUT_USER,
        payload: user
    }
}