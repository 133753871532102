// export const ageCalculator = (dob) => {
//     var dob_age = new Date(dob);
//     var diff_ms = Date.now() - dob_age.getTime();

//     if (diff_ms > 0) {
//         var age_dt = new Date(diff_ms);
//         var result = Math.abs(age_dt.getUTCFullYear() - 1970);

//         if (result > 0) {
//             return result + (result === 1 ? ' year' : ' years');
//         } else {

//             let dob_month = (dob_age.getMonth() + 1);
//             let current_month = (new Date().getMonth() + 1);

//             if (dob_month === current_month) {
//                 return (((new Date().getDate()) - dob_age.getDate()) + ' days');
//             }

//             if (current_month > dob_month) {
//                 return (((new Date().getDate()) - dob_age.getDate()) + ' days');
//             }

//             //return (12 - (dob_age.getMonth() + 1)) === 0 ? (((new Date().getDate()) - dob_age.getDate()) + ' days') : (12 - (dob_age.getMonth() + 1)) + ' months';
//         }
//     } else {
//         return ''
//     }
// }

export function ageCalculator(dateString) {
  var now = new Date();

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(dateString);

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = "";
  var yearString = "";
  var monthString = "";
  var dayString = "";

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = { years: yearAge, months: monthAge, days: dateAge };

  if (age.years > 1) yearString = " years";
  else yearString = " year";
  if (age.months > 1) monthString = " months";
  else monthString = " month";
  if (age.days > 1) dayString = " days";
  else dayString = " day";

  if (age.years > 0 && age.months > 0 && age.days > 0)
    ageString = age.years + yearString;
  else if (age.years == 0 && age.months == 0 && age.days > 0)
    ageString = age.days + dayString;
  else if (age.years > 0 && age.months == 0 && age.days == 0)
    ageString = age.years + yearString;
  else if (age.years > 0 && age.months > 0 && age.days == 0)
    ageString = age.years + yearString;
  else if (age.years == 0 && age.months > 0 && age.days > 0)
    ageString = age.months + monthString;
  else if (age.years > 0 && age.months == 0 && age.days > 0)
    ageString = age.years + yearString;
  else if (age.years == 0 && age.months > 0 && age.days == 0)
    ageString = age.months + monthString;
  else ageString = 0 + dayString;

  return ageString;
}
