import { Link } from "react-router-dom";

const DashCards = ({ name, value }) => {

    return (
        <div className="col-sm-4">
            <div className="card widget-flat" title={name}>
                <div className="card-body">
                    {/* <Link to='/total-prescriptions'> */}
                    <h5 className="fw-normal mt-0">
                        <i className="uil-store"></i> {name}</h5>
                    <h3 className="mt-3">{value}</h3>
                    {/* </Link> */}
                </div>
            </div>
        </div>
    )
}
export default DashCards;