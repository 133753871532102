import { useState } from "react";
import { Modal } from "react-bootstrap";
import AddPatientStep1 from "./AddPatientStep1";
import AddPatientStep2 from "./AddPatientStep2";
import AddPatientStep3 from "./AddPatientStep3";
import './AddPatientDetails.css';
import { step1, step2, step3 } from "./consts";


const AddUpdatePatientPopup = ({
  checkSalutation,
  createdId,
  setCheckSalutation,
  setCheckGender,
  checkGender,
  show,
  data,
  onHide,
  submitForm,
  stepFormData,
  onInput,
  setStepFormData,
  formErrors,
  setFormErrors,
}) => {
  const allSteps = [
    step1(data),
    step2(data),
    step3(data),
  ];
  const [phone_number, setPhone] = useState(data?.phone_number);
  const [country_code, setCountryCode] = useState(data?.country_code);
  const [activeStep, setActiveStep] = useState(allSteps[0]);
  // eslint-disable-next-line
  const [stepLabels, setStepLabels] = useState(allSteps);
  const [insuranceFile, setInsurancefile] = useState(null);
  const [insuranceId, setInsuranceId] = useState(null);
  const [insuranceName, setInsuranceName] = useState(null);
  const [subInsuranceCode, setSubInsuranceCode] = useState(null);
  const [subInsuranceId, setSubInsuranceId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [disable, setDisable] = useState(true);

  const otherDataMap = {
    2: { insuranceFile, insuranceId, insuranceName, subInsuranceCode, subInsuranceId },
    3: { files: uploadedFiles }
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      size="lg"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="remove-padding add-new-patient"
    >
      <form
        id={'add-patient-' + activeStep.step}
        onInput={(e) => {
          setStepFormData(prevState => ({
            ...prevState,
            [activeStep.step]: {
              ...prevState[activeStep.step],
              [e.target.name]: e.target.value
            }
          }));
          onInput(e.target.name, e.target.value, activeStep.step, otherDataMap[2].insuranceFile, setBtnDisable);
        }}
        onSubmit={(e) => { submitForm(e, activeStep.step, allSteps.filter(({ step }) => step === activeStep.next)[0], setActiveStep, otherDataMap[activeStep.step], data, setDisable) }}
      >
        <Modal.Header>
          <Modal.Title className="m-0" id="contained-modal-title-vcenter  justify-content-between" style={{ width: '100%', display: 'flex' }}>
            <span className="flex-grow-1">{data ? "Update" : "Add"} Patient</span>
            <button
              type="button"
              onClick={(e) => { onHide(e); setStepFormData({}) }}
              className="btn btn-close"
              data-bs-dismiss="modal"
            >
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2">
            {stepLabels.map((step) => <div onClick={() => setActiveStep(step)} className={`col mx-1 step ${activeStep.label === step.label ? 'active-step' : ''}`} style={{ pointerEvents: step.step !== 1 && disable && !data?.id ? 'none' : 'initial' }}>{step.label}</div>)}
          </div>
          <div className="row">
            {
              activeStep.step === 1
                ? <>
                  <input type="hidden" name="phone_number" value={phone_number} />
                  <input type="hidden" name="country_code" value={country_code} />
                  <input type="hidden" name="mr_id" value={data?.mr_id} />
                  <AddPatientStep1
                    data={data?.id ? { ...data, ...stepFormData[activeStep.step] } : stepFormData[activeStep.step]}
                    setCheckSalutation={setCheckSalutation}
                    checkSalutation={checkSalutation}
                    setCheckGender={setCheckGender}
                    checkGender={checkGender}
                    setActiveStep={setActiveStep}
                    setPhone={setPhone}
                    setCountryCode={setCountryCode}
                    setBtnDisable={setBtnDisable}
                    errors={formErrors[1]}
                    setFormErrors={setFormErrors}
                  />
                </>
                : activeStep.step === 2
                  ? <AddPatientStep2
                    data={data?.id ? { ...data, ...data?.insurance_list[data?.insurance_list?.length - 1], ...stepFormData[activeStep.step] } : stepFormData[activeStep.step]}
                    setActiveStep={setActiveStep}
                    setInsurancefile={setInsurancefile}
                    setInsuranceId={setInsuranceId}
                    setInsuranceName={setInsuranceName}
                    setSubInsuranceCode={setSubInsuranceCode}
                    setSubInsuranceId={setSubInsuranceId}
                    setBtnDisable={setBtnDisable}
                    setStepFormData={setStepFormData}
                    errors={formErrors[2]}
                    setFormErrors={setFormErrors}
                    insuranceFile={insuranceFile}
                  />
                  : <AddPatientStep3
                    data={data?.id ? { ...data, ...stepFormData[activeStep.step] } : stepFormData[activeStep.step]}
                    setActiveStep={setActiveStep}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    setBtnDisable={setBtnDisable}
                  />
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary" disabled={
            (!data?.id && btnDisable) || ((activeStep.step === 3 && !uploadedFiles) || (activeStep.step === 3 && uploadedFiles?.every(({ file }) => typeof file === 'string')))}
          >
            {activeStep.buttonLabel}
          </button>
          <div className="nav-buttons">
            <button
              className="btn btn-primary mr-2"
              disabled={activeStep.step === 1}
              onClick={(e) => { e.preventDefault(); activeStep.step !== 1 && setActiveStep(allSteps.filter(({ step }) => step === activeStep.previous)[0]) }}
            >
              Prev
            </button>
            <button
              className="btn btn-primary"
              disabled={(!data?.id && disable) || activeStep.step === 3}
              onClick={(e) => { e.preventDefault(); activeStep.step !== 3 && setActiveStep(allSteps.filter(({ step }) => step === activeStep.next)[0]) }}
            >
              Next
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddUpdatePatientPopup;
