export const fields = {
  1: {
    salutation: {
      name: "salutation",
      label: "Salutation",
      required: true,
    },
    gender: {
      name: "gender",
      label: "Gender",
      required: true,
    },
    first_name: {
      label: "First Name",
      name: "first_name",
      required: true,
      pattern: /^[a-zA-Z]+$/,
      patternLabel: "alphabet",
    },
    last_name: {
      name: "last_name",
      label: "Last Name",
      required: true,
      pattern: /^[a-zA-Z]+$/,
      patternLabel: "alphabet",
    },
    phone_number: {
      required: true,
      name: "phone_number",
    },
    email: {
      name: "email",
      required: true,
      label: "Email",
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      patternLabel: "format i.e., example@gmail.com",
    },
    dob: {
      name: "dob",
      label: "Date of Birth",
      required: true,
    },
    national_id_pass_number: {
      label: "National Id / Passport Number",
      name: "national_id_pass_number",
      pattern: /^[a-zA-Z0-9 ]+$/,
      patternLabel: "alpha-numeric",
      required: true,
    },
  },
  2: {
    insurance_file: {
      name: "insurance_file",
      label: "Insurance File",
      required: true,
      maxFileSize: 5 * 1024 * 1024,
      fileSizeLabel: "5mb",
    },
    insuranceType: {
      name: "insuranceType",
      label: "Insurance Type",
      required: true,
    },
    subInsuranceName: {
      name: "subInsuranceName",
      label: "Sub Insurance",
      required: true,
    },
    premium_no: {
      name: "premium_no",
      label: "Insurance Premium No.",
      min: 2,
      required: true,
    },
    expiry_date: {
      name: "expiry_date",
      label: "Insurance Expiry Date",
      required: true,
    },
  },
};

export const dropDownKeys = [
  "insuranceType",
  "subInsuranceName",
  "gender",
  "salutation",
];

export const fileKeys = ["insurance_file"];
