import { useEffect } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import RequiredInput from "../Common/RequiredInput";

const Input = ({
  label,
  type,
  placeholder,
  multiple,
  min,
  onKeyUp,
  max,
  limit,
  disableSubmitBtn,
  maxLength,
  pattern,
  minLength,
  id,
  readOnly,
  disabled,
  name,
  className,
  labelClassName,
  required,
  value,
  defaultValue,
  onChange,
  types,
  regex, regexLabel,
}) => {
  const [error, setError] = useState("");
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState("qa");
  const [phoneCountryCode, setPhoneCountryCode] = useState("+974");

  const handleOnChange = (e) => {
    if (regex && !regex.test(e.target.value)) {
      e.preventDefault();
      setError(label + ' should contain only ' + regexLabel);
    } else if (e.target.value.length > limit) {
      e.preventDefault();
      setError(label + " should not be more than " + limit);
    } else if (e.target.value.length < minLength) {
      e.preventDefault();
      setError(label + ' should be of minimum length: ' + minLength);
    } else {
      setError("");
    }
    onChange(e);
  };
  return (
    <>
      <label className={labelClassName}>
        {label} {required ? <RequiredInput /> : null}
      </label>
      {types === "phone" ? (
        <PhoneInput
          onChange={(phoneNumber) => {
            setPhone(phoneNumber);
          }}
          country={countryCode}
          defaultMask={".... ...."}
          onlyCountries={["qa"]}
          defaultValue={phoneCountryCode + phone}
          value={phoneCountryCode + defaultValue}
          className="phone-input"
        />
      ) : (
        <>
          <input
            className={className}
            max={max}
            pattern={pattern}
            maxLength={maxLength}
            minLength={minLength}
            id={id}
            readOnly={readOnly}
            disabled={disabled}
            name={name}
            value={value}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              if (e.target.value.length > 0) {
                if (regex && !regex.test(String(e.target.value))) {
                  e.preventDefault();
                  setError(label + ' should contain only ' + regexLabel);
                  disableSubmitBtn && disableSubmitBtn(true);
                } else if (e.target.value.length > limit) {
                  e.preventDefault();
                  setError(label + " should not be more than " + limit);
                  disableSubmitBtn && disableSubmitBtn(true);
                } else if (e.target.value.length < minLength) {
                  e.preventDefault();
                  setError(label + ' should be of minimum length: ' + minLength);
                  disableSubmitBtn && disableSubmitBtn(true);
                } else {
                  setError("");
                  disableSubmitBtn && disableSubmitBtn(false);
                }
              }
            }}
            defaultValue={defaultValue}
            type={type}
            onKeyUp={onKeyUp}
            required={required}
            placeholder={placeholder}
            multiple={multiple}
            min={min}
          />
          {error && <small className="text-danger">{error}<br /></small>}
        </>
      )}
    </>
  );
};

export default Input;