import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchDashboardDetails } from "../../api/settingsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import { removeUser } from "../../redux/actions/userActions";
import Header from "../Common/Header";
import DashCards from "./DashCards";

const Dashboard = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = useSelector((state) => state?.token);

    const [dashboardDetails, setDashboardDetails] = useState([]);

    useEffect(() => {
        dispatch(setLoaderView(true));
        fetchDashboardDetails(token).then(res => {
            dispatch(setLoaderView(false));
            if (res.status === 200) {
                setDashboardDetails(res.data);
            }
        }).catch(error => {
            dispatch(removeUser());
            navigate("/auth/login");
        });
    }, []);

    return (
        <>
            <Header title="Dashboard" />

            <div className="row">
                <DashCards name={'Total Prescriptions'} value={dashboardDetails?.total_prescriptions} />
                <DashCards name={'New Patients'} value={dashboardDetails?.new_patients} />
                <DashCards name={'Total Patients'} value={dashboardDetails?.total_patients} />
                <DashCards name={`My Today's Visits`} value={dashboardDetails?.my_visits_today} />
                <DashCards name={'My Upcoming Visits'} value={dashboardDetails?.my_visits_upcoming} />
                <DashCards name={'Clinic/Today Visit'} value={dashboardDetails?.todays_visits} />
                <DashCards name={'Clinic/Upcoming Visit'} value={dashboardDetails?.upcoming_visits} />
            </div>
        </>
    )
}

export default Dashboard;