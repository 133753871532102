const InputWithDropDown = ({
    label,
    options,
    placeholder,
    step,
    required,
    min,

    inputType,
    inputClassName,
    inputName,
    inputId,
    inputValue,
    inputDefaultValue,
    inputOnChange,

    disableBoth,

    dropDownClassName,
    dropDownName,
    dropDownId,
    dropDownValue,
    dropDownDefaultValue,
    dropdownOnChange
}) => {
    return (
        <>
            <label>{label}{required ? <span style={{ color: 'red' }}>{' ' + '*'}</span> : ''}</label>
            <div className="input-group">
                <input type={inputType} disabled={disableBoth} step={step} onChange={inputOnChange} min={min} className={inputClassName} value={inputValue} defaultValue={inputDefaultValue} id={inputId} name={inputName} required={required} placeholder={placeholder} />
                <select className={dropDownClassName} disabled={disableBoth} id={dropDownId} name={dropDownName} value={dropDownValue} onChange={dropdownOnChange} defaultValue={dropDownDefaultValue} required={required}>
                    <option value={0}>--Select--</option>
                    {options.map((obj, index) => {
                        return <option key={index} value={obj?.value}>{obj?.label}</option>
                    })}
                </select>
            </div>
        </>
    )
}

export default InputWithDropDown;