export const fields = {
    strength: {
        name: "strength",
        label: "Strength",
        required: true,
    },
    strength_unit: {
        name: "strength_unit",
        label: "Strength Unit",
        required: true,
    },
    dosage: {
        name: "dosage",
        label: "Dosage",
        required: true
    },
    dosage_unit: {
        name: "dosage_unit",
        label: "Dosage Unit",
        required: true
    },
    duration: {
        label: "Duration",
        name: "duration",
        required: true,
        pattern: /^[a-zA-Z0-9 ]+$/,
        patternLabel: 'alphanumeric',
    },
    duration_unit: {
        label: "Duration Unit",
        name: "duration_unit",
        required: true,
    },
    itemName: {
        name: "itemName",
        label: "Drug",
        required: true,
    },
}

export const dropDownKeys = ['itemName', 'strength_unit', 'dosage_unit', 'duration_unit'];