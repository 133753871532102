import axios from "axios";
import { toast } from "react-toastify";

export async function fetchAllPatients(
  token,
  pageNo,
  totalItemToBeFetched,
  applyFilter,
  search_string,
  filters
) {
  const response = await axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      "/patients/get_all_patients?page_no=" +
      (applyFilter || search_string ? "1" : pageNo) +
      "&page_count=" +
      totalItemToBeFetched +
      "&" +
      (!search_string && filters) +
      (search_string ? "&search_string=" + search_string : ""),
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    toast.error(error?.response?.data?.message);
  });
  return response.data;
}

export async function fetchAllPatientsofRecentVisits(
  token,
  doctor_id,
  pageNo,
  totalItemToBeFetched,
  applyFilter,
  search_string,
  filters
) {
  const response = await axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      "/patients/recent_visits?doctor_id=" +
      doctor_id +
      "&page_no=" +
      (applyFilter || search_string ? "1" : pageNo) +
      "&page_count=" +
      totalItemToBeFetched +
      (!search_string && filters ? "&" + filters : "") +
      (search_string ? "&search_string=" + search_string : ""),
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    toast.error(error?.response?.data?.message);
  });
  return response.data;
}

export async function fetchAllPatientsGeneral(token, search_string) {
  const response = await axios({
    method: "get",
    // eslint-disable-next-line
    url:
      process.env.REACT_APP_BASE_URL +
      "/patients/get_all_patients" +
      ("?search_string=" + (search_string || "")),
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    toast.error(error?.response?.data?.message);
  });
  return response.data;
}

export async function getAllDocTypes(token) {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_BASE_URL + "/master/get_document_type",
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    toast.error(error?.response?.data?.message);
  });
  return response.data;
}

export async function searchPatients(
  token,
  mrId,
  name,
  phone,
  email,
  gender,
  age,
  dobFromDate,
  dobToDate
) {
  const response = await axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      "/patients/get_all_patients?phone_number=" +
      phone +
      "&email=" +
      email +
      "&mr_id=" +
      mrId +
      "&name=" +
      name +
      "&gender=" +
      gender +
      "&age=" +
      age +
      "&dob_from=" +
      dobFromDate +
      "&dob_to=" +
      dobToDate,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // toast.error(error?.response?.data?.message);
  });
  return response.data;
}

export async function fetchPatient(id, token) {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_BASE_URL + "/patients/get_single_patient/" + id,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // toast.error(error?.response?.data?.message);
  });
  return response.data;
}

export async function createPatient(formData, token) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_BASE_URL + "/patients/save_patient",
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    const { data, status, message } = error.response.data;
    if (status === 400) {
      const keys = Object.keys(data);
      const values = Object.values(data);
      for (let i = 0; i < keys.length; i++) {
        const field = keys[i];
        toast.error(
          field.toUpperCase().replace("_", " ") + " : " + values[i][0]
        );
      }
    } else {
      toast.error(message);
    }
  });
  return response.data;
}

export async function uploadInsurance(formData, token, patientId) {
  formData.append("patient_id", patientId);
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_BASE_URL + "/patients/add_patient_insurance",
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    toast.error(error.response?.data?.data?.file[0]);
  });
  return response.data;
}

export async function uploadDocuments(formData, token, patientId) {
  formData.append("patient_id", patientId);
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_BASE_URL + "/patients/add_document",
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
  });
  return response.data;
}

export async function updatePatient(id, formData, token) {
  const response = await axios({
    method: "put",
    url: process.env.REACT_APP_BASE_URL + "/patients/update_patient/" + id,
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {

  });
  return response.data;
}

export async function fetchClinicVisit(
  id,
  token,
  pageNo = 1,
  totalItemToBeFetched = 10,
  applyFilter,
  search_string,
  filters
) {
  const response = await axios({
    method: "get",
    // url: process.env.REACT_APP_BASE_URL + "/patients/patients-visit/?patient_id=" + id, //patient id
    url:
      process.env.REACT_APP_BASE_URL +
      "/patients/get_all_visits?patient_id=" +
      id +
      "&page_no=" +
      (applyFilter || search_string ? "1" : pageNo) +
      "&page_count=" +
      totalItemToBeFetched +
      "&" +
      (!search_string && filters) +
      (search_string ? "&search_string=" + search_string : ""), //patient id
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {

  });
  return response.data;
}

export async function deleteVisit(id, token) {
  const response = await axios({
    method: "delete",
    url: process.env.REACT_APP_BASE_URL + "/patient/patients-visit/" + id + "/",
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // // console.log(error);
  });
  return response.data;
}

export async function getInsuranceTypeList() {
  const response = await axios({
    method: "get",
    url:
      process.env.REACT_APP_CONSUMER_URL +
      "/master/get_insurance_list??active_list=1",
    // headers: {
    //     Authorization: 'Token ' + token
    // }
  }).catch((error) => {
    // // console.log(error);
  });
  return response.data;
}

export async function getInsuranceSubTypeList() {
  const response = await axios({
    method: "get",
    url:
      process.env.REACT_APP_CONSUMER_URL +
      "/master/get_insurance_list??active_list=1",
    // headers: {
    //     Authorization: 'Token ' + token
    // }
  }).catch((error) => {
    // // console.log(error);
  });
  return response.data;
}

export async function updatePatientVisit(
  patientId,
  formData,
  token,
  clinic_visit_id
) {
  formData.append("patient_id", patientId);
  const response = await axios({
    method: clinic_visit_id ? "put" : "post",
    url: clinic_visit_id
      ? process.env.REACT_APP_BASE_URL +
        "/patients/update_visit/" +
        clinic_visit_id
      : process.env.REACT_APP_BASE_URL + "/patients/add_visit",
    data: formData,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // // console.log(error);
  });
  return response.data;
}

export async function deleteClinicVisit(token, clinic_visit_id, close) {
  const response = await axios({
    method: "delete",
    url:
      process.env.REACT_APP_BASE_URL +
      "/patients/delete_visits/" +
      clinic_visit_id,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    toast.error(error?.response?.data?.message);
    close(false);
  });
  return response.data;
}

export async function deletePatient(id, token) {
  const response = await axios({
    method: "delete",
    url: process.env.REACT_APP_BASE_URL + "/patients/delete_patient/" + id,
    headers: {
      Authorization: "Token " + token,
    },
  }).catch((error) => {
    // // console.log(error);
  });
  return response.data;
}
