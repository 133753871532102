import Header from "../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import GridView from "../Common/GridView";
import { deleteClinicVisit, fetchClinicVisit, fetchPatient, updatePatientVisit } from "../../api/patientsApi";
import { useState } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { setLoaderView } from "../../redux/actions/settingActions";
import { deletePrescription, fetchPrescriptions } from "../../api/prescriptionApi";
import DeleteConfirmationPopup from "../Common/DeleteConfirmationPopup";
import { toast } from "react-toastify";
import Button from "../Common/Button";
import CreateClinicVisitPopUp from "./CreateClinicVisitPopUp";
import { formatDate } from "../utils/dateUtils";
import { toQueryParams } from "../Common/helpers/filtersHelpers";
import { useContext } from "react";
import { FiltersContext } from "../Common/FiltersProvider";

const PatientProfile = () => {

  const { id } = useParams();
  const token = useSelector((state) => state?.token);
  const dispatch = useDispatch();
  const [patientDetail, setPatientDetail] = useState();
  const [prescriptions, setPrescriptions] = useState([]);
  const [clinicVisitsList, setClinicVisitsList] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  // eslint-disable-next-line
  const [clinicVisit, setClinicVisit] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showClinicPopUp, setShowClinicPopUp] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState('');
  const isDoctor = useSelector((state) => state?.user_detail?.user_data?.is_doctor);
  const [selectedClinicVisit, setSelectedClinicVisit] = useState({});

  // eslint-disable-next-line
  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  // eslint-disable-next-line
  const [totalItemsPrescriptions, setTotalItemsPrescriptions] = useState(0);
  const [pageNoPrescriptions, setPageNoPrescriptions] = useState(1);
  const [totalItemToBeFetchedPrescriptions, setTotalItemToBeFetchedPrescriptions] = useState(10);

  useEffect(() => {
    setSearchedData(clinicVisitsList);
  }, [clinicVisitsList])

  const action = (prescriptionId) => {
    if (!isDoctor) {
      return null;
    }
    return (
      <div className="dropdown card-widgets">
        <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="mdi mdi-dots-vertical"></i>
        </span>
        <div className="dropdown-menu dropdown-menu-end" >
          {isDoctor && <Link className="dropdown-item cursor-pointer" to={`/update-prescription/${id}/${prescriptionId}`}><i className="uil-file-edit-alt me-1"></i>Update</Link>}
          {isDoctor && <span className="dropdown-item cursor-pointer" onClick={() => {
            setDeleteCategory('prescription');
            setShowDeletePopup(true);
            setDeleteId(prescriptionId);
          }}><i className="uil uil-trash-alt me-1"></i>Delete</span>}
        </div>
      </div>
    )
  }

  const clinicAction = (clinicVisitId, row) => {
    return (
      <div className="dropdown card-widgets">
        <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="mdi mdi-dots-vertical"></i>
        </span>
        <div className="dropdown-menu dropdown-menu-end" >
          {isDoctor && <span className="dropdown-item cursor-pointer" onClick={() => { setShowClinicPopUp(true); setSelectedClinicVisit(row); }}><i className="uil-file-edit-alt me-1"></i>Edit</span>}
          <span className="dropdown-item cursor-pointer" onClick={() => {
            setShowDeletePopup(true);
            setDeleteId(clinicVisitId);
            setDeleteCategory('visit');
          }}><i className="uil uil-trash-alt me-1"></i>Delete</span>
        </div>
      </div>
    )
  }

  const openPrescriptionDetails = (cell, row) => {
    return isDoctor ? <NavLink to={{
      pathname: `/preview-prescription-details/${id}/${row.prescription_uuid}`,
      state: row
    }}>Prescription {cell}</NavLink> : <span>{`Prescription ${cell}`}</span>
  }

  const openClinicDetails = (cell, row) => {
    const visitDate = row?.visit_datetime;
    const today = new Date();
    return <Link to={{ pathname: ("/view-visit/" + id + '/' + cell), state: { isFurtureVisit: visitDate > today } }}>Visit {cell}</Link>
  }

  const prescriptionsColumns = [
    {
      dataField: 'id',
      text: 'Prescription Name',
      formatter: openPrescriptionDetails
    }, {
      dataField: 'created_on',
      text: 'Created On',
      sort: true,
      isFilterable: true,
      type: "datePicker",
      formatter: (cell) => {
        let date = new Date(cell)
        return date.toDateString()
      }
    }, {
      dataField: 'created_by_name',
      text: 'Created By',
      sort: true,
      isFilterable: true,
      type: "input",
      // formatter: (cell) => {
      //   let date = new Date(cell)
      //   return date.toLocaleTimeString()
      // }
    }, {
      dataField: 'id',
      text: '',
      formatter: action
    }
  ];

  const clinicsColumns = [
    {
      dataField: 'id',
      text: 'Visit Id',
      sort: true,
      isFilterable: false,
      type: "input",
      formatter: openClinicDetails
    },
    {
      dataField: 'doctor_name',
      text: 'Doctor Name',
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: 'notes',
      text: 'Clinic Notes',
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: 'visit_datetime',
      text: 'Visit Date',
      sort: true,
      isFilterable: true,
      type: "datePicker",
      formatter: (cell) => cell ? formatDate(cell) : ''
    },
    {
      dataField: 'id',
      text: '',
      formatter: clinicAction
    }
  ];

  const { state: { filters } } = useContext(FiltersContext);

  const fetchCurrentPatient = () => {
    dispatch(setLoaderView(true));
    fetchPatient(id, token).then(res => {
      if (res.status === 200) {
        setPatientDetail(res.data);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    });
    fetchPrescriptionsLists();
    // fetchPrescriptions(id, token).then(res => {
    //   dispatch(setLoaderView(false));
    //   if (res.status === 200) {
    //     setPrescriptions(res.data?.reverse());
    //   } else {
    //     dispatch(setLoaderView(false));
    //     toast.error(res.message);
    //   }
    // }).catch(error => {
    //   dispatch(setLoaderView(false));
    //   // console.log(error)
    // });
    fetchVisitLists();
    // fetchClinicVisit(id, token).then(res => {
    //   dispatch(setLoaderView(false));
    //   if (res.status === 200) {
    //     setClinicVisitsList(res.data?.reverse());
    //   } else {
    //     dispatch(setLoaderView(false));
    //     toast.error(res.message);
    //   }
    // }).catch(error => {
    //   dispatch(setLoaderView(false));
    //   // console.log(error)
    // });
  }

  const fetchPrescriptionsLists = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    fetchPrescriptions(id, token, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setPrescriptions(res.data?.reverse());
        setTotalItemsPrescriptions(res.total_count);
        setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    });
  }

  useEffect(() => {
    fetchPrescriptionsLists(toQueryParams(filters));
    // eslint-disable-next-line
  }, [pageNoPrescriptions, totalItemToBeFetchedPrescriptions]);

  const fetchVisitLists = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    fetchClinicVisit(id, token, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 200) {
        setClinicVisitsList(res.data?.reverse());
        setTotalItems(res.total_count);
        setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error)
    });
  }

  useEffect(() => {
    fetchVisitLists(toQueryParams(filters));
    // eslint-disable-next-line
  }, [pageNo, totalItemToBeFetched]);

  const location = useLocation();
  useEffect(() => {
    fetchCurrentPatient();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchCurrentPatient();
    // eslint-disable-next-line
  }, [location.pathname]);

  const canDelete = (yesOrNo) => {
    if (yesOrNo) {
      if (deleteCategory === 'visit') {
        deleteClinicVisit(token, deleteId, setShowDeletePopup).then(res => {
          dispatch(setLoaderView(false));
          if (res.status === 200) {
            toast.success(res.message);
            setShowDeletePopup(false);
            fetchClinicVisit(id, token).then(res => {
              if (res.status === 200) {
                setClinicVisitsList(res.data?.reverse());
              } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
              }
            }).catch(error => {
              dispatch(setLoaderView(false));
              // console.log(error);
            });
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }
        }).catch(error => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
      } else {
        deletePrescription(deleteId, token).then(res => {
          dispatch(setLoaderView(false));
          if (res.status === 200) {
            toast.success(res.message);
            setShowDeletePopup(false);
            fetchPrescriptions(id, token).then(res => {
              if (res.status === 200) {
                setPrescriptions(res.data?.reverse());
              } else {
                dispatch(setLoaderView(false));
                toast.error(res.message);
              }
            }).catch(error => {
              dispatch(setLoaderView(false));
              // console.log(error);
            });
          } else {
            dispatch(setLoaderView(false));
            toast.error(res.message);
          }
        }).catch(error => {
          dispatch(setLoaderView(false));
          // console.log(error);
        });
      }
    } else {
      setShowDeletePopup(false);
    }
  }

  const handleSubmitClinicVisitForm = (event, patientId, doctor_id, clinic_visit_id) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('doctor_id', doctor_id);
    formData.delete('patient_id');
    formData.delete('month');
    formData.delete('day');
    formData.delete('year');
    formData.delete('hour12');
    formData.delete('minute');
    formData.delete('amPm');
    formData.delete('status');
    updatePatientVisit(id, formData, token, clinic_visit_id).then(res => {
      if (res.status === 200) {
        toast.success(res.message);
        setShowClinicPopUp(false);
        setSelectedClinicVisit({});
        fetchCurrentPatient();
      } else {
        dispatch(setLoaderView(false));
        toast.error(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      toast.error(error);
      // console.log(error);
    });
  }

  const searchData = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    if (searchTerm) {
      setSearchedData(clinicVisitsList.filter(e => e.doctor_name.toLowerCase().match(searchTerm.toLowerCase())));
    }
    else {
      setSearchedData(clinicVisitsList);
    }
  }

  let dob = patientDetail?.dob ? patientDetail?.dob.split('-') : '';
  dob = dob.length > 1 ? `${dob[2]}-${dob[1]}-${dob[0]}` : '';
  return (
    <>
      <Header title="Patient Profile" back={true}>
        {/* {isDoctor && <Link to={"/create-prescription/" + id} className="btn btn-outline-primary float-right mb-3">Create Prescription</Link>} */}
      </Header>

      <div className="row">
        <div className="col-12 m-auto">
          <div className="card">
            <div className="card-body">
              <h4 className="mb-3 mt-2 text-center">Patient Details</h4>
              <div className="row">
                <div className="col-7">
                  <p className="mb-2"><strong>Full Name :</strong> <span className="ml-2">{patientDetail ? patientDetail?.first_name + ' ' + patientDetail?.last_name : null}</span></p>
                </div>
                <div className="col-5">
                  <p className="mb-2"><strong>Mobile :</strong><span className="ml-2">{patientDetail?.country_code ? `+${patientDetail?.country_code}` : ''}{patientDetail?.phone_number}</span></p>
                </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <p className="mb-2"><strong>Email :</strong> <span className="ml-2 ">{patientDetail?.email}</span></p>
                </div>
                <div className="col-5">
                  <p className="mb-2"><strong>Gender :</strong> <span className="ml-2">{patientDetail?.gender}</span></p>
                </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <p className="mb-2"><strong>DOB :</strong> <span className="ml-2">{dob}</span></p>
                </div>
                <div className="col-5">
                  <p className="mb-2"><strong>National Id :</strong> <span className="ml-2">{patientDetail?.national_id_pass_number}</span></p>
                </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <p className="mb-2"><strong>Mrn No :</strong> <span className="ml-2">{patientDetail?.mr_id}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Button
            type="button"
            label="Create Clinic Visit"
            className="btn btn-primary float-right mb-3"
            onClick={() => setShowClinicPopUp(true)}
          />
          {<CreateClinicVisitPopUp
            data={selectedClinicVisit}
            show={showClinicPopUp}
            onHide={() => setShowClinicPopUp(false)}
            submitForm={(event, id, doctor_id, clinic_visit_id) => handleSubmitClinicVisitForm(event, id, doctor_id, clinic_visit_id)}
            patient={patientDetail}
            setSelectedClinicVisit={setSelectedClinicVisit}
          />}
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
                <li className="nav-item">
                  <a href="#clinics-tab" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0 active">
                    <i className="mdi mdi-account d-md-none d-block"></i>
                    <span className="d-none d-md-block">Clinic Visit</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#prescriptions-tab" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
                    <i className="mdi mdi-account d-md-none d-block"></i>
                    <span className="d-none d-md-block">Prescriptions</span>
                  </a>
                </li>
                {/* <li className="nav-item">
                                    <a href="#first-tab" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
                                        <i className="mdi mdi-home-variant d-md-none d-block"></i>
                                        <span className="d-none d-md-block">Test</span>
                                    </a>
                                </li> */}
              </ul>

              <div className="tab-content">
                <div className="tab-pane show active" id="clinics-tab">
                  <GridView
                    data={searchedData}
                    searchPlaceHolder="Enter doctor name to search"
                    columns={clinicsColumns}
                    maxRow={10}
                    hideFilters={true}
                    searchKey="name"
                    totalItems={totalItems}
                    totalItemToBeFetched={totalItemToBeFetched}
                    setTotalItemToBeFetched={setTotalItemToBeFetched}
                    pageNo={pageNo}
                    // onFilter={fetchVisitLists}
                    onFilter={searchData}
                    url={process.env.REACT_APP_BASE_URL + "/patients/get_all_visits?patient_id=" + id + '&page_no=' +
                      pageNo +
                      "&page_count=" +
                      totalItemToBeFetched}
                    setPageNo={setPageNo}
                  />
                  {/* <GridView
                    gridData={clinicVisitsList} // TO DO: change it to clinicVisitsList state once api is ready
                    columns={clinicsColumns}
                    totalSize={10}
                    sizePerPage={10}
                    keyField="id"
                  /> */}
                </div>
                <div className="tab-pane show" id="prescriptions-tab">
                  <GridView
                    data={prescriptions}
                    columns={prescriptionsColumns}
                    hideFilters={true}
                    maxRow={10}
                    searchKey="name"
                    totalItems={totalItemsPrescriptions}
                    totalItemToBeFetched={totalItemToBeFetchedPrescriptions}
                    setTotalItemToBeFetched={setTotalItemToBeFetchedPrescriptions}
                    pageNo={pageNoPrescriptions}
                    onFilter={fetchPrescriptionsLists}
                    url={process.env.REACT_APP_BASE_URL + "/prescriptions/patients-prescription/?patient_id=" + id + '&page_no=' +
                      pageNo +
                      "&page_count=" +
                      totalItemToBeFetched}
                    setPageNo={setPageNoPrescriptions}
                  />
                  {/* <GridView
                    gridData={prescriptions}
                    columns={prescriptionsColumns}
                    totalSize={10}
                    sizePerPage={10}
                    keyField="id"
                  /> */}
                </div>

                {/* <div className="tab-pane" id="first-tab">
                                    <h1>Test</h1>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeletePopup ? <DeleteConfirmationPopup show={showDeletePopup} canDelete={canDelete} /> : null}

    </>
  )
}

export default PatientProfile;