import * as React from 'react';
import SelectDropDown from '../Common/SelectDropDown';
import Input from '../Common/Input';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getInsuranceTypeList } from '../../api/patientsApi';
import { useState } from 'react';

const AddPatientStep2 = ({
  data,
  setInsurancefile,
  setInsuranceId,
  setInsuranceName,
  setSubInsuranceCode,
  setSubInsuranceId,
  setBtnDisable,
  errors,
  setStepFormData,
  insuranceFile
}) => {
  const [insuranceList, setInsuranceList] = React.useState([]);
  const [insuranceType, setInsuranceType] = React.useState('');
  // const [insuranceSubType, setInsuranceSubType] = React.useState('');
  const [subInsuranceList, setSubInsuranceList] = React.useState([]);
  const [imageError, setImageError] = React.useState(false);
  const today = new Date().toISOString().substr(0, 10);
  const [fileSizeError, setFileSizeError] = useState('');
  const [date, setDate] = useState();
  const [file, setFile] = useState(null);

  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  const mapOptions = (list, compare, prop) => list.map(type => type[compare] === Number(data[prop]))

  // React.useEffect(() => {
  //   if (data?.insuranceType) {
  //     setInsuranceType(mapOptions(insuranceList, 'id', 'insuranceType'));
  //   }
  //   // if (data?.subInsuranceName) {
  //   //   setInsuranceSubType(mapOptions(insuranceList, 'subInsuranceId', 'subInsuranceName'));
  //   // }
  // }, [insuranceList]);
  React.useEffect(() => {
    getInsuranceTypeList().then((res) => {
      if (res.status === 200) {
        setInsuranceList(res.data?.map(insurance => ({ ...insurance, label: insurance.insuranceName, value: insurance.id })));
      } else {
        toast.error(res.message);
      }
    }).catch(error => {
      // console.log(error)
    });
  }, []);

  React.useEffect(() => {
    setBtnDisable(imageError);
  }, [imageError]);

  React.useEffect(() => {
    if (errors && Object.values(errors).some(val => val !== '' && val.length > 0)) {
      setBtnDisable(true);
    } else if (errors && Object.values(errors).every(val => val === '' && val.length === 0)) {
      setBtnDisable(false);
    }
  }, [errors]);

  const handleKeyUp = (event) => {
    const inputValue = event.target.value;
    const inputDate = new Date(inputValue).toISOString().substr(0, 10);
    if (inputDate < today) {
      toast.error(`Value must be ${today} or greater!`);
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  };

  // React.useEffect(() => {
  //   if (data?.insurance_list[0]) {
  //     setStepFormData(prevState => ({
  //       ...prevState,
  //       insuranceType: data?.insurance_list[0]?.insuranceType,
  //       premium_no: data?.insurance_list[0]?.premium_no,
  //       expiry_date: data?.insurance_list[0]?.expiry_date
  //     }));
  //   }
  // }, [data?.insurance_list]);

  // React.useEffect(() => {
  //   console.log('>>', data);
  // }, [data]);

  React.useEffect(() => {
    handleInsuranceType({}, data?.insuranceType || (data?.insurance_list?.length > 0 && data?.insurance_list[0]?.insuranceType));
  }, [data?.insuranceType || (data?.insurance_list?.length > 0 && data?.insurance_list[0]?.insuranceType)]);

  const handleInsuranceType = (e, selectedId) => {
    let option;
    if (e.target && !selectedId) {
      const index = e.target.selectedIndex;
      const optionElement = e.target.childNodes[index]
      option = optionElement.getAttribute('data-id');
    } else if (selectedId) {
      option = selectedId;
    }
    option = insuranceList.filter(({ insuranceId }) => insuranceId === option)[0];
    const {
      insuranceName,
      insuranceId,
    } = option || {};
    setInsuranceId(insuranceId);
    setInsuranceName(insuranceName);
    if (selectedId || e?.target?.value || (data?.insurance_list?.length > 0 && data?.insurance_list[0]?.insuranceType)) {
      axios
        .get(
          process.env.REACT_APP_CONSUMER_URL +
          "/master/get_sub_insurance_list?insurance_id=" +
          (selectedId || e?.target?.value || (data?.insurance_list?.length > 0 && data?.insurance_list[0]?.insuranceType))
        )
        .then((res) => {
          setSubInsuranceList(res.data.data.map(subInsurance => ({ ...subInsurance, label: subInsurance.subInsuranceName, value: subInsurance.subInsuranceId })));
        })
        .catch((err) => {
          toast.error(err.toString());
        });
    }
  }

  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <label className="mb-2" style={{ color: imageError ? 'red' : '' }}>{"Attach Insurance File of Type JPEG, PNG or PDF format (Max File Size : 5mb)"}<span className="text-danger">*</span></label>
          <span className="form-control upload-input-placeholader-txt">
            <Input
              type="file"
              accept="image/*, .pdf"
              name="insurance_file"
              onChange={(e) => {
                if (e.target.files[0] && ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].some(x => x === e.target.files[0]?.type)) {
                  setInsurancefile(e.target.files[0]);
                  setImageError(false);
                  setFile(e);
                } else {
                  e.target.value = null;
                  setImageError(true);
                } if (e.target.files[0].size >= MAX_FILE_SIZE) {
                  setFileSizeError('File size must be less than 5 MB');
                  setBtnDisable(true);
                } else {
                  setFileSizeError('');
                  setBtnDisable(false);
                }
              }}
            />
            <h5 className="text-danger">{fileSizeError}</h5>
          </span>
        </div>
        <div className="row mt-3 justify-content-center">
          <div className="col-12 m-auto" style={{ textAlign: 'center ' }}>
            <img src={insuranceFile ? URL.createObjectURL(insuranceFile) : process.env.REACT_APP_BASE_URL + data?.file} alt="" style={{ width: '70%' }} />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <SelectDropDown
            options={insuranceList}
            label="Insurance Type"
            name="insuranceType"
            required={true}
            value={data?.insuranceType}
            className="form-control basic-multi-select"
            classNamePrefix="select"
            onChange={handleInsuranceType}
          />
          <small className="text-danger">{errors && errors['insuranceType']}</small>
        </div>
        <div className="col-6">
          <SelectDropDown
            options={subInsuranceList}
            label="Sub Insurance"
            // value={insuranceSubType}
            name="subInsuranceName"
            required={true}
            value={data?.subInsuranceName}
            className="form-control basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => {
              const index = e.target.selectedIndex;
              const optionElement = e.target.childNodes[index]
              let option = optionElement.getAttribute('value');
              option = subInsuranceList.filter(({ subInsuranceId }) => subInsuranceId === option)[0];
              const {
                subInsuranceCode,
                subInsuranceId,
              } = option;
              setSubInsuranceCode(subInsuranceCode);
              setSubInsuranceId(subInsuranceId);
            }}
          />
          <small className="text-danger">{errors && errors['subInsuranceName']}</small>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6">
          <Input className='form-control' min={2} required={true} label={'Insurance Premium No.'} name="premium_no" value={data?.premium_no} />
          <small className="text-danger">{errors && errors['premium_no']}</small>
        </div>
        <div className="col-6">
          <Input type='date' className='form-control' required={true} label={'Insurance Expiry Date'} onKeyUp={handleKeyUp} value={data?.expiry_date} min={today} name="expiry_date" />
          <small className="text-danger">{errors && errors['expiry_date']}</small>
        </div>
      </div>
      <div className="row mt-2 mb-3">
        <div className="w-100 mt-3 py-2 px-3 cart-NB" style={{ backgroundColor: 'rgb(254, 242, 232)' }}>
          <div className="text-primary">
            Medicines with insurance will be delivered after verification.
            Payment will be done after approval.
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPatientStep2;