import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchAllSideMenu } from '../../api/sidemenuapi';
import { setLoaderView } from '../../redux/actions/settingActions';
import { FiltersContext } from '../Common/FiltersProvider';
import GridView from '../Common/GridView';
import { toQueryParams } from '../Common/helpers/filtersHelpers';
import DetailedViewEdit from './DetailedViewEdit';

const Complaints = () => {
  const { id } = useParams();
  const token = useSelector((state) => state?.token);
  const [selectedData, setSelectedData] = useState({});
  const { state: { filters } } = useContext(FiltersContext);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  let name = location.pathname.replace('/', '');
  name = name.slice(0, 1).toUpperCase() + name.slice(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  const clinic_id = useSelector((state) => state?.user_detail?.clinic_id);

  useEffect(() => {
    setPageNo(1);
  }, [location.pathname]);


  useEffect(() => {
    if (!id) {
      fetchAllSideMenu(location.pathname, token).then(res => {
        if (res.status === 1 || res.status === 200) {
          setData(res.data);
        } else {
          toast.error(res.message);
        }
      }).catch(error => {
        // console.log(error);
      });
    }
  }, [location.pathname]);

  const openName = (cell, row) => {
    return <Link to={("/detailed-view/view/" + name + '/' + row.id)} onClick={() => sessionStorage.setItem('DetailsData', JSON.stringify(row))}>{row.name}</Link>
  }

  const openDate = (cell, row) => {
    return <>{cell.split('T')[0] + ' T' + cell.split('T')[1].slice(0, 5)}</>
  }

  const action = (id, row) => {
    if (row?.clinic_id === clinic_id) {
      return (
        <div className="dropdown card-widgets">
          <span className="dropdown-toggle arrow-none cursor-pointer card-drop" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="mdi mdi-dots-vertical"></i>
          </span>
          <div className="dropdown-menu dropdown-menu-end" >
            <span className="dropdown-item cursor-pointer" onClick={() => { setSelectedData(row); navigate("/detailed-view/edit/" + name + '/' + id, { state: { ...row } }); }}><i className="uil-file-edit-alt me-1"></i>Edit</span>
            {/* <span className="dropdown-item cursor-pointer" onClick={() => {
              // setShowDeletePopup(true);
              // setDeleteId(id);
            }}><i className="uil uil-trash-alt me-1"></i>Delete</span> */}
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  const columns = [
    {
      dataField: 'id',
      text: (id ? name.split('/')[2] : name) + ' ' + 'Id',
    },
    {
      dataField: 'name',
      text: (id ? name.split('/')[2] : name) + ' ' + 'Name',
      formatter: openName,
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: 'description',
      text: (id ? name.split('/')[2] : name) + ' ' + 'Description',
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: 'created_by_name',
      text: 'Created By',
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: 'created_on',
      text: 'Created On',
      formatter: openDate,
      sort: true,
      isFilterable: true,
      type: "datePicker",
    },
    {
      dataField: 'modified_on',
      text: 'Modified On',
      formatter: openDate
    },
    {
      dataField: 'id',
      text: '',
      formatter: action
    }
  ];

  const loadData = (formData, params, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    dispatch(setLoaderView(true));
    fetchAllSideMenu(location.pathname, token, pageNo, totalItemToBeFetched, applyFilter, searchTerm, toQueryParams(filters)).then(res => {
      dispatch(setLoaderView(false));
      if (res.status === 1 || res.status === 200) {
        setData(res.data);
        setTotalItems(res.total_count);
        setSelectedData({});
        setApplyFiltersFalseFn && setApplyFiltersFalseFn(false);
      } else {
        toast.success(res.message);
      }
    }).catch(error => {
      dispatch(setLoaderView(false));
      // console.log(error);
    });

  }

  useEffect(() => {
    loadData(toQueryParams(filters));
    // eslint-disable-next-line
  }, [pageNo, totalItemToBeFetched]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 ">
              <GridView
                data={data}
                columns={columns}
                hideFilters={true}
                maxRow={10}
                searchKey="name"
                totalItems={totalItems}
                totalItemToBeFetched={totalItemToBeFetched}
                setTotalItemToBeFetched={setTotalItemToBeFetched}
                pageNo={pageNo}
                onFilter={loadData}
                setPageNo={setPageNo}
                searchPlaceHolder={'Enter ' + name + ' Name'}
              />
            </div>
          </div>
        </div>

        {selectedData !== {} && <DetailedViewEdit
          // path={"/detailed-view/edit/" + name + '/' + id}
          name={name} id={id}
          data={selectedData}
          onSuccess={() => { navigate(-1) }}
        />}
      </div>
    </>
  );
}

export default Complaints;