import RequiredInput from './RequiredInput';

const SelectDropDown = ({ label, options, id, name, readOnly, disabled, className, labelClassName, required, value, defaultValue, onChange }) => {
    return (
        <>
            <label className={labelClassName}>{label} {required ? <RequiredInput /> : null}</label>
            <select className={className} readOnly={readOnly} disabled={disabled} id={id} name={name} value={value} onChange={onChange} defaultValue={defaultValue} required={required}>
                <option value={0}>--Select--</option>
                {options.map((obj, index) => {
                    return <option key={index} value={obj?.value} data-id={obj?.id}>{obj?.label}</option>
                })}
            </select>
        </>
    )
}

export default SelectDropDown;