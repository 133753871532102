import { useState } from "react";
import { Modal } from "react-bootstrap";

const TermsConditionPopup = ({ show, onHide, submitForm }) => {

    const [policyChecked, setPolicyChecked] = useState(false);
    const [shareDataChecked, setShareDataChecked] = useState(false);

    return (
        <Modal show={show} backdrop="static" size="lg" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title className="m-0" id="contained-modal-title-vcenter">Agree & Continue</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input cursor-pointer checkbox-highlight" checked={policyChecked} onChange={(event => setPolicyChecked(event.target.checked))} />
                            <label class="form-check-label ml-2">By login into Medpack you agree to our <a href={process.env.REACT_APP_FE_URL + "/terms-conditions"} target="_blank"> terms of use</a> &amp; <a href={process.env.REACT_APP_FE_URL + "/privacy-policy"} target="_blank">policy</a></label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input cursor-pointer checkbox-highlight" checked={shareDataChecked} onChange={(event => setShareDataChecked(event.target.checked))} />
                            <label class="form-check-label ml-2">By clicking this button you agree to share your data with Medpack</label>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={onHide} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                {policyChecked && shareDataChecked && <button type="button" onClick={submitForm} className="btn btn-primary"> Submit</button>}
            </Modal.Footer>
        </Modal>
    )
}

export default TermsConditionPopup;