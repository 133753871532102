import { BrowserRouter, Routes, Route } from "react-router-dom";
import BaseLayout from "./layouts/BaseLayout";
import AuthLayout from "./layouts/AuthLayout";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Authentication/Login";
import { ToastContainer } from "react-toastify";
import Settings from "./components/Settings/Settings";
import CreatePrescription from "./components/Prescription/CreatePrescription";
import PreviewPrescription from "./components/Prescription/PreviewPrescription";
import SearchPatient from "./components/Prescription/SearchPatient";
import Patients from "./components/Patients/Patients";
import PatientProfile from "./components/Patients/PatientProfile";
import { setLoaderView } from "./redux/actions/settingActions";
import { useDispatch } from "react-redux";
import { adjust } from "./helper/colorFormatter";
import PageNotFound from "./components/Common/PageNotFound";
import ViewPrescription from "./components/Prescription/ViewPrescription";
import UpdatePrescription from "./components/Prescription/UpdatePrescription";
import FullScreenLoader from "./components/Common/FullScreenLoader";
import Complaints from "./components/Complaints/Complaints";
import DetailedView from "./components/Complaints/DetailedView";
import ViewClinic from "./components/Patients/ViewClinic";
import ClinicDetails from "./components/Settings/ClinicDetails";
import Users from "./components/Settings/Users";
import Roles from "./components/Settings/Roles";
import DetailedViewEdit from "./components/Complaints/DetailedViewEdit";
import ClinicVisit from "./components/Patients/ClinicVisit";
import TotalPrescriptions from "./components/Complaints/TotalPrescriptions";
import PrescriptionDetail from "./components/Prescription/PrescriptionDetails";
import { FiltersProvider } from "./components/Common/FiltersProvider";

function App() {
  const dispatch = useDispatch();
  dispatch(setLoaderView(false));

  document.documentElement.style.setProperty(
    "--custom-primary",
    process.env.REACT_APP_PRIMARY_COLOR
  );
  document.documentElement.style.setProperty(
    "--custom-primary-dark",
    adjust(process.env.REACT_APP_PRIMARY_COLOR, -20)
  );
  document.documentElement.style.setProperty(
    "--custom-primary-border",
    adjust(process.env.REACT_APP_PRIMARY_COLOR, -40)
  );
  document.documentElement.style.setProperty(
    "--custom-primary-focus",
    process.env.REACT_APP_PRIMARY_COLOR + "26"
  );

  return (
    <>
      <FiltersProvider>
        <FullScreenLoader />
        <BrowserRouter>
          <ToastContainer />
          <Routes>
            <Route element={<BaseLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/create-prescription/:patient_id"
                element={<CreatePrescription />}
              />
              <Route
                path="/create-prescription/:patient_id/:clinic_id"
                element={<CreatePrescription />}
              />

              <Route
                path="/view-prescription/:patient_id/:prescription_id"
                element={<ViewPrescription />}
              />
              <Route
                path="/update-prescription/:patient_id/:prescription_id"
                element={<UpdatePrescription />}
              />
              <Route
                path="/view-visit/:patient_id/:clinic_id"
                element={<ViewClinic />}
              />
              <Route path="/patients" element={<Patients />} />
              <Route path="/patients/Total-Patients" element={<Patients />} />
              <Route path="/patients/New-Patients" element={<Patients />} />
              <Route path="/clinicDetails" element={<ClinicDetails />} />
              <Route
                path="/total-prescriptions"
                element={<TotalPrescriptions />}
              />
              <Route path="/users" element={<Users />} />
              <Route path="/roles" element={<Roles />} />
              <Route path="/complaints" element={<Complaints />} />
              <Route path="/diagnosis" element={<Complaints />} />
              <Route path="/tests" element={<Complaints />} />
              <Route
                path="/detailed-view/view/:name/:id"
                element={<DetailedView />}
              />
              {/* <Route path="/complaints/:id" element={<Complaints />} /> */}
              <Route path="/detailed-view/edit/:name/:id" element={<Complaints />} />
              <Route
                path="/view-visit/patientID/:patientId/clinicID/:clinicId"
                element={<ClinicVisit />}
              />
              <Route path="/search-patient" element={<SearchPatient />} />
              <Route
                path="/preview-prescription-details/:patient_id/:prescription_id"
                element={<PrescriptionDetail />}
              />
              {/* <Route path="/preview-prescription/:patient_id/:prescription_id" element={<PreviewPrescription />} /> */}
              <Route path="/patient-profile/:id" element={<PatientProfile />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
              <Route path="" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route
              path="/preview-prescription/:prescription_id"
              element={<PreviewPrescription />}
            />{" "}
          </Routes>
        </BrowserRouter>
      </FiltersProvider>
    </>
  );
}

export default App;
