export const dateFormatter = (rawDate) => {
    let date = new Date(rawDate);
    return date.toDateString();
}

export const newDateFormatter = (rawDate) => {
    const date = new Date(rawDate);
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleString(undefined, options);
    return formattedDate.replace(',', ' ');
}